// import React, { useState, useEffect } from 'react';
import React from 'react';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import Button from '../Buttons/Button';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

import {
  deleteDepartment,
  // editDepartment,
  // clearDepartmentError,
  // getUserForDepartment,
} from '../../store/actions/departmentActions';

import './styles.css';

const Department = ({
  department,
  auth,
  deleteDepartment,
  project,
  // editDepartment,
  // clearDepartmentError,
  // getUserForDepartment,
  setDepartment,
  onOpenForm,
}) => {
  // const [isEdit, setIsEdit] = useState(false);
  // const [addUserError, setAddUserError] = useState(null);

  const handleDelete = (e, id) => {
    e.preventDefault();
    // if (!isEdit) {
    deleteDepartment(id);
    // }
  };

  const checkPermissions = () => {    
    if (
      auth.isAuthenticated &&
      (department.account?.admins?.some((admin) => admin.toString() === auth.me.id.toString()) ||
        auth.me.role === 'SUPERADMIN' ||
        project.podMasters?.some((pm) => pm.id === auth.me.id))
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={department.isLoading ? 'department loader' : 'department'}>
      {/* <p>{departmentProfile ? departmentProfile.name : null}</p> */}

      <div className="department-list-item">
        <div className="department-row">
          <div className="department-row-left">
            <div className="department-color-box" style={{ backgroundColor: department.color }}></div>
            <div className="department-name Material-KitCaption">{department.name}</div>
          </div>          
          
          <div className="department-tags">
            <div className="user-count Material-KitSB2">
              <span>{department.users.length}</span>
              <div className="user-count__icon">
                <img src="/icons/groups_2.svg" alt="users" />
              </div>
              {department.viewOnly ? (
                <div className="user-count__icon">
                  <img src="/icons/visibility.svg" alt="read only" />
                </div>
              ) : null}
              {department.external ? (
                <div className="user-count__icon">
                  <img src="/icons/shield_person.svg" alt="external" />
                </div>
              ) : null}
          </div>
        </div>
      </div>
    </div>
      {checkPermissions() && (
        <div className="department-actions flex align-center ">
          {/* <Link to={`/project/department/edit/${department.id}`} className="btn department-btn">
            <FontAwesomeIcon icon={faPen} /> Edit
          </Link>
          <button
            onClick={(event) => handleDelete(event, department.id)}
            type="button"
            className="btn department-btn"
          >
            <FontAwesomeIcon icon={faTrash} /> Delete
          </button> */}
          <Button
            size="x-mini"
            className=" primary-blue"
            onClick={() => {
              setDepartment(department.id);
              onOpenForm('department/edit');
            }}
          >
            <div className="department-actions__image">
              <img src="/icons/edit.svg" alt="edit" />
            </div>
          </Button>
          <Button
            size="x-mini"
            className="primary-red"
            onClick={() => {
              if (window.confirm('Are you sure you want to delete this department?')) {
                deleteDepartment(department.id);
              }
            }}
          >
            <div className="department-actions__image">
              <img src="/icons/delete.svg" alt="delete" />
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  // departments: state.department,
});

export default connect(mapStateToProps, {
  deleteDepartment,
  // editDepartment,
  // clearDepartmentError,
  // getUserForDepartment,
})(Department);
