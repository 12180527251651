import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Button from '../../Buttons/Button';
import Loader from '../../../components/Loader/Loader';
import DepartmentForm from '../../../components/DepartmentForm/DepartmentForm';
import requireAuth from '../../../hoc/requireAuth';
import { getProject, deleteDepartmentForProject, addDepartmentForProject, moveDepartment } from '../../../store/actions/projectActions';
// import { getAccount } from '../../../store/actions/accountActions';
import { getProjectDepartments } from '../../../store/actions/departmentActions';
import './styles.css';

const ProjectDepartmentsEdit = ({
  getProject,
  deleteDepartmentForProject,
  addDepartmentForProject,
  thisProject: { project, isLoading, error },
  // thisAccount: { account, isLoading: accountIsLoading, error: accountError },
  department: { departments, isLoading: departmentsIsLoading, error: departmentsError },
  history,
  match,
  projectID,
  accountID,
  onUnsetForm,
  onReturnBack,
  onOpenForm,
  setDepartment,
  // getAccount,
  getProjectDepartments,
  moveDepartment, 
}) => {
  const matchProjectId = projectID || match.params.projectId;
  const matchAccountId = accountID || match.params.accountId;
  const [ filteredDepartments, setFilteredDepartments ] = useState([]);

  
  
  useEffect(() => {
    setFilteredDepartments(departments.filter(d => !project.departments.some(pd => pd.id === d.id)));
  }, [departments, project.departments])

  useEffect(() => {
    getProject(matchProjectId, true, history);
    getProjectDepartments(project.id, true, history);
    // if (matchAccountId) {
    //   getAccount(matchAccountId, true, history);
    //   getProjectDepartments(matchAccountId);
    // } else {
      
    // }
  }, [getProject, history, matchProjectId, matchAccountId]);

  
  return (
    <div className="dashboard-form project-list">
      <div className="project-form__heading flex align-center justify-space-between">
        <h2 className="Material-KitH4">Project Departments</h2>
        <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
          <div className="btn__image">
            <img src="/icons/arrow_back.svg" alt="previous" />
          </div>
        </Button>
      </div>

      {error && <div className="error-center Material-KitCaption">{error}</div>}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="project-departments-list-container list flex flex-column justify-space-between">
          <div className="flex flex-row">
            <div className="department-list">
              <h4 className="Material-KitH6">Remove</h4>
              { project.departments.length > 0 
                ?  project.departments.map((department, index) => {
                  return (
                    <div key={index}>
                      <div className="department">

                        <div className="department-list-item">
                          <div className="department-row">
                            <div className="department-row-left">
                              <div className="department-color-box" style={{ backgroundColor: department.color }}></div>
                              {department.privateProject ? 
                                <div 
                                  className="department-name Material-KitCaption private-department-name"
                                  onClick={() => {
                                    onOpenForm('department/edit');                                      
                                    setDepartment(department.id);
                                  }}  
                                >{department.name}</div> : 
                                <div className="department-name Material-KitCaption">{department.name}</div>
                              }                             
                            </div>          
                            
                            <div className="department-tags">
                              <div className="user-count Material-KitSB2">
                                <span>{department.users.length}</span>
                                <div className="user-count__icon">
                                  <img src="/icons/groups_2.svg" alt="users" />
                                </div>
                                {department.viewOnly && (
                                  <div className="user-count__icon">
                                    <img src="/icons/visibility.svg" alt="read only" />
                                  </div>
                                )}
                                {department.external && (
                                  <div className="user-count__icon">
                                    <img src="/icons/shield_person.svg" alt="external" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        
                        <div className={`department-actions ${index > 0 && index < project.departments.length - 1 ? 'flex-center' : index > 0 ? 'flex-left' : 'flex-right'}`} style={{ width: '110px', marginRight: '20px' }}> 
                          {index > 0 && <Button
                            size="x-mini"
                            className="primary-blue button-move-up"
                            onClick={() => {                        
                              moveDepartment(project.id, department.id, 'up');                        
                            }}                          
                          >
                            <div className="department-actions__image">
                              <img src="/icons/arrow_drop_up.svg" alt="Up" />
                            </div>
                          </Button>}
                          {index < project.departments.length - 1 && <Button
                            size="x-mini"
                            className="primary-blue button-move-down"
                            onClick={() => {                        
                              moveDepartment(project.id, department.id, 'down');                        
                            }}                          
                          >
                            <div className="department-actions__image">
                              <img src="/icons/arrow_drop_down.svg" alt="Down" />
                            </div>
                          </Button>}
                        </div>
                        <div className="department-actions flex align-center ">                        
                          <Button
                            size="x-mini"
                            className="primary-red"
                            onClick={() => {                        
                              deleteDepartmentForProject(project.id, department.id);                        
                            }}
                          >
                            <div className="department-actions__image">
                              <img src="/icons/delete.svg" alt="delete" />
                            </div>
                          </Button>
                        </div>
                        
                      </div>
                      
                    </div>
                  )
                }) 
              : <p className="department-name Material-KitCaption">No departments assigned to this project</p>}
            </div>
            <div className="department-list">
              <h4 className="Material-KitH6">Add</h4>
              { filteredDepartments.length > 0 
                ?  filteredDepartments.map((department, index) => {
                  return (
                    <div key={index}>
                      <div className="department">
                        
                        <div className="department-list-item">
                          <div className="department-row">
                            <div className="department-row-left">
                              <div className="department-color-box" style={{ backgroundColor: department.color }}></div>
                              {department.privateProject ? 
                                <div 
                                  className="department-name Material-KitCaption private-department-name"
                                  onClick={() => {
                                    onOpenForm('department/edit');                                      
                                    setDepartment(department.id);
                                  }}  
                                >{department.name}</div> : 
                                <div className="department-name Material-KitCaption">{department.name}</div>
                              }
                            </div>          
                            
                            <div className="department-tags">
                              <div className="user-count Material-KitSB2">
                                <span>{department.users.length}</span>
                                <div className="user-count__icon">
                                  <img src="/icons/groups_2.svg" alt="users" />
                                </div>
                                {department.viewOnly && (
                                  <div className="user-count__icon">
                                    <img src="/icons/visibility.svg" alt="read only" />
                                  </div>
                                )}
                                {department.external && (
                                  <div className="user-count__icon">
                                    <img src="/icons/shield_person.svg" alt="external" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div className="department-actions flex align-center ">
                          <Button
                            size="x-mini"
                            className="primary-blue"
                            onClick={() => {                        
                              addDepartmentForProject(project.id, department.id);                        
                            }}
                          >
                            <div className="department-actions__image">
                              <img src="/icons/add.svg" alt="Add" />
                            </div>
                          </Button>
                        </div>
                      </div>
                      
                    </div>
                  )
                }) 
              : <p className="department-name Material-KitCaption">No departments remaining to assign</p>}
            </div>
          </div>
          <DepartmentForm 
            projectId={matchProjectId} 
            accountId={matchAccountId} 
          />            
        </div>
      )}
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  thisProject: state.project,
  // thisAccount: state.account,
  department: state.department,
});

// export default compose(requireAuth, withRouter, connect(mapStateToProps))(ProjectDepartmentsEdit);
export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getProjectDepartments, getProject, deleteDepartmentForProject, addDepartmentForProject, moveDepartment }),
)(ProjectDepartmentsEdit);
