import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { createHuddleBoard } from '../../../store/actions/huddleBoardAction';
import { connect } from 'react-redux';

const HuddleBoardForm = ({
  projectId,
  title,
  createHuddleBoard,
  simple,
  tags,
  newTag,
  selectedTag,
  departmentView,
}) => {
  const [splitByTag, setSplitByTag] = useState(false);
  // Validation Schema
  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    data: Yup.string(),
  });

  // in the case of department view, the huddle board is created for the
  // department - projectId is the departmentId passed down from Department view
  const initialValues = {
    project: departmentView ? null : projectId,
    department: departmentView ? projectId : null,
    title: title,
    description: simple ? title : '',
    order: 1,
    data: [],
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (!splitByTag) {
        if (newTag) {
          await createHuddleBoard({ ...values, tag: selectedTag });
        } else {
          await createHuddleBoard(values);
        }
      } else {
        for (const tag of tags) {
          await createHuddleBoard({ ...values, tag: tag.id });
        }
      }
    } catch (error) {
      console.error('Error creating huddle board:', error);
    }
  };

  return (
    <div className="huddle-board-init">
      {simple ? (
        <h2>Ready to track {title}?</h2>
      ) : (
        <h2>To get started, enter the description for the {title} KPI:</h2>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="huddle-board-init-form">
            {/* <div>
              <label htmlFor="title">Title:</label>
              <Field type="text" name="title" />
              <ErrorMessage name="title" component="div" className="error" />
            </div> */}

            {!simple && (
              <div>
                {/* <label htmlFor="description">Description:</label> */}
                <Field type="text" name="description" placeholder="Enter Description" />
                <ErrorMessage name="description" component="div" className="error" />
              </div>
            )}
            {/* <div>
              <label htmlFor="order">Order:</label>
              <Field type="text" name="order" />
              <ErrorMessage name="order" component="div" className="error" />
            </div>
            <div>
              <label htmlFor="description">JSON Format Data:</label>
              <Field type="text" name="data" />
              <ErrorMessage name="data" component="div" className="error" />
            </div> */}
            {tags?.length > 0 && !newTag && (
              <div className="split-by-tag-toggle">
                <div
                  className={
                    splitByTag ? 'split-by-tag-toggle-button' : 'split-by-tag-toggle-button-active'
                  }
                  onClick={() => setSplitByTag(false)}
                >
                  Do Not Split
                </div>
                <div
                  className={
                    splitByTag ? 'split-by-tag-toggle-button-active' : 'split-by-tag-toggle-button'
                  }
                  onClick={() => setSplitByTag(true)}
                >
                  Split By Tag
                </div>
              </div>
            )}

            <button type="submit" disabled={isSubmitting}>
              {simple ? 'Start Tracking' : 'Initialize KPI'}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default connect(null, { createHuddleBoard })(HuddleBoardForm);
