import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import Loader from '../../../components/Loader/Loader';
import Layout from '../../../layout/Layout';
import requireAuth from '../../../hoc/requireAuth';
import DailyTaskCell from '../../../components/DailyTaskCell/DailyTaskCell';
import DepartmentFilter from '../Filters/DepartmentFilter';
import TagFilter from '../Filters/TagFilter';
import '../../../components/DepartmentList/styles.css';
import {
  getProject,  
  clearProjectError,
} from '../../../store/actions/projectActions';
import { getProjectCompletedTasks } from '../../../store/actions/taskActions';
import { getTags } from '../../../store/actions/tagActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import { filterDepartmentsArray } from '../../../constants/functions';
import '../styles.css';


const deconstructTask = (task) => {
  return {
    title: task.title,
    description: task.description,
    complete: task.complete,
    assignedTo: task.assignedTo.hasOwnProperty('id') ? task.assignedTo.id : null,
    department: task.department.id,
    tag: task.tag.hasOwnProperty('id') ? task.tag.id : null,
    project: task.project.id,
    approved: task.approved,
    delay: task.delay,
    dueDate: task.dueDate,
  };
};

const deconstructValues = (values) => {
  return {
    title: values.title,
    description: values.description,
    complete: values.complete,
    assignedTo: values.assignedTo === '' ? null : values.assignedTo,
    department: values.department,
    project: values.project,
    tag: values.tag === '' ? null : values.tag,
    approved: values.approved,
    delay: values.delay,
    dueDate: values.dueDate,
  };
};

const desconstructAudit = (audit) => {
  let cleanAudit = [];
  audit.forEach((a) => {
    cleanAudit.push({
      user: a.user.id,
      action: a.action,
      date: a.date,
      old: a.old,
      new: a.new,
    });
  });
  return cleanAudit;
};

const CompletedTasksView = ({
  getProject,
  getProjectCompletedTasks,  
  getTags,
  auth,  
  task: { tasks, isLoading: tasksIsLoading, error: tasksError },
  thisProject: { project, isLoading, error },  
  tag: { tags, isLoading: tagsIsLoading, error: tagsError },
  history,
  match,
}) => {  

  const matchProjectId = match.params.projectId;
  const [departmentFilter, setDepartmentFilter] = useState({});
  const [tagFilter, setTagFilter] = useState({});
  const [secondsToRefresh, setSecondsToRefresh] = useState(10);  
  const [uniqueDates, setUniqueDates] = useState([]);
  const [expandTasks, setExpandTasks] = useState({});
  
  useEffect(() => {
    if (secondsToRefresh > -1) {
      const timerId = setInterval(() => {
        setSecondsToRefresh((prevSeconds) => prevSeconds - 1); // decrement seconds
      }, 1000);

      // Clean up the interval on component unmount or when seconds reach 0
      return () => clearInterval(timerId);
    } else {
      getProjectCompletedTasks(matchProjectId);      
      setSecondsToRefresh(10);
    }
  }, [secondsToRefresh]);

  

  const toggleDepartmentFilter = (departmentId) => {
    setDepartmentFilter({
      ...departmentFilter,
      [departmentId]: !departmentFilter[departmentId],
    });
  };

  const toggleTagFilter = (tagId) => {
    setTagFilter({
      ...tagFilter,
      [tagId]: !tagFilter[tagId],
    });
  };  

  useEffect(() => {
    getProjectCompletedTasks(matchProjectId);    
    getProject(matchProjectId, true, history);   
    getTags(matchProjectId);
    document.documentElement.style.removeProperty('overflow');
    setSecondsToRefresh(10);
  }, []);

  useEffect(() => {
    
    // const orderedTasks = tasks.sort((a, b) => {
    //   return moment(b.dueDate).diff(moment(a.dueDate));
    // });
    
    // const listOfDates = orderedTasks.map((task) => { return moment(task.dueDate).format('YYYY-MM-DD') });
    const listOfDates = tasks?.length > 0 ? tasks?.map((task) => { return moment(task.dueDate).format('YYYY-MM-DD') }) : [];
    const uniqueDates = [...new Set(listOfDates)];
    // setSortedTasks(orderedTasks);
    setUniqueDates(uniqueDates);
    
  }, [tasks]);

  if (auth.isAuthenticated) {
    return (
      <Layout>
        <div className={`project-view-page completed-tasks-view-page ${Object.keys(expandTasks).length > 0 ? 'expand-tasks-active' : ''}`}>
          {error && <div className="error-center">{error}</div>}
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <div className="project-title-bar">
                <div className="project-title-bar-left-container">
                  <div className="project-title-bar-left">
                    <h1 className="project-view-page-title">{project.title}</h1>
                    <h3 className="completed-tasks-view-page-sub-title">Completed Tasks</h3>
                    <p className="project-view-page-refresh-in">
                      {secondsToRefresh > 0
                        ? `Next refresh in ${secondsToRefresh} seconds`
                        : 'Fetching tasks...'}
                    </p>
                  </div>
                  <div className="project-title-bar-right-filters-container">
                    <div className="project-title-bar-right-filter-container completed-tasks-filter-responsive">
                      <Link to={`/project/view/${project.id}`}>
                        <div className={`project-title-bar-right-filter-button`}>                                                
                          <FontAwesomeIcon icon={faTable} />                                             
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="project-title-bar-right">
                  <div className="scrollable-filters">
                    <DepartmentFilter                    
                      departmentFilter={departmentFilter}
                      toggleDepartmentFilter={toggleDepartmentFilter}
                      project={project}
                      filterDepartmentsArray={filterDepartmentsArray}
                      authMeId={auth.me?.id}
                    />
                    <TagFilter
                      tags={tags}
                      tagFilter={tagFilter}
                      toggleTagFilter={toggleTagFilter}
                      project={project}
                    />
                  </div>                                    
                </div>
                <div id="project-nav-right-container" className="project-nav-right-container">
                  <Link to={`/project/view/${project.id}`}>
                    <div className={'project-nav-right-button'}>                          
                        <FontAwesomeIcon icon={faTable} />
                        <span className="btn-project-nav-right-button-tooltip">View Pod</span>
                    </div>
                  </Link>
                </div>
              </div>              
              <div className="project-view-page-header">
                
                <div className="project-view-page-body-row-header-department-1">
                  <div>Departments</div>
                </div>
                
                {uniqueDates.map((date) => (
                  <div key={date} className="project-view-page-body-row-header-2">
                    {moment(date).format('MMM DD, YYYY')}                  
                  </div>
                ))}
                
              </div>
              <div className="project-view-page-body">
                {filterDepartmentsArray(project.departments, auth.me?.id).map((department) => (
                  <div
                    key={department.id}
                    className={`project-view-page-body-row ${
                      departmentFilter[department.id] ? 'hide-department' : ''
                    }`}
                  >
                    <div className={`project-view-page-body-row-header-department-1 ${department.privateProject ? 'private-department-cell' : ''}`}>
                      
                      <div className="project-view-page-body-row-department-name">
                        <div>{department.name}</div>
                        
                      </div>
                    </div>

                    
                    {uniqueDates.map((date, index) => (
                      <DailyTaskCell
                        // dayNumber={index}
                        department={department}
                        tasks={tasks}
                        tags={tags}
                        project={project}
                        hideApproveDecline={false}
                        auth={auth}
                        deconstructTask={deconstructTask}
                        deconstructValues={deconstructValues}
                        desconstructAudit={desconstructAudit}
                        key={index}
                        cellType="completed"
                        date={date}
                        expandTasks={expandTasks}
                        setExpandTasks={setExpandTasks}                         
                        tagFilter={tagFilter}
                      />
                    ))}
                    {/* Empty space for the column view styling */}
                    <div className="project-view-page-body-row-task-cell no-tasks empty-space-task-cell"></div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </Layout>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  thisProject: state.project,
  auth: state.auth,  
  tag: state.tag,
  task: state.task,
  
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, {
    getProject,
    getProjectCompletedTasks,    
    clearProjectError,    
    getTags,
  }),
)(CompletedTasksView);
