import {
  GET_TASKS_LOADING,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
  GET_TASK_LOADING,
  GET_TASK_SUCCESS,
  GET_TASK_FAIL,
  GET_PROJECT_TASKS_LOADING,
  GET_PROJECT_TASKS_SUCCESS,
  GET_PROJECT_TASKS_FAIL,
  GET_PROJECT_COMPLETED_TASKS_LOADING,
  GET_PROJECT_COMPLETED_TASKS_SUCCESS,
  GET_PROJECT_COMPLETED_TASKS_FAIL,
  GET_USER_TASKS_LOADING,
  GET_USER_TASKS_SUCCESS,
  GET_USER_TASKS_FAIL,
  GET_ASSIGNEDTO_TASKS_LOADING,
  GET_ASSIGNEDTO_TASKS_SUCCESS,
  GET_ASSIGNEDTO_TASKS_FAIL,
  GET_DEPARTMENT_TASKS_LOADING,
  GET_DEPARTMENT_TASKS_SUCCESS,
  GET_DEPARTMENT_TASKS_FAIL,
  ADD_TASK_LOADING,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAIL,
  DELETE_TASK_LOADING,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,
  EDIT_TASK_LOADING,
  EDIT_TASK_SUCCESS,
  EDIT_TASK_FAIL,
  CLEAR_TASK_ERROR,
} from '../types';

const initialState = {
  tasks: [],
  task: {},
  isLoading: false,
  error: null,
};

// You could have an array [{ id: 1, isLoading: false, error: null, text: "Hey" }, { id: 2, isLoading: true, error: null, text: null }]

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROJECT_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_PROJECT_COMPLETED_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_USER_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ASSIGNEDTO_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_DEPARTMENT_TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_TASK_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_TASK_LOADING:
      return {
        ...state,
        tasks: [
          {
            id: 0,
            title: 'Loading...',
            description: '',
            complete: false,
            assignedTo: '',
            department: { ...payload.department },
            project: { ...payload.project },
            approved: false,
            delay: 0,
            dueDate: new Date().toISOString(),
            isLoading: true,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            user: { ...payload.me },
            audit: [],
          },
          ...state.tasks,
        ],
      };
    case DELETE_TASK_LOADING:
    case EDIT_TASK_LOADING:
      return {
        ...state,
        tasks: state.tasks.map((t) => {
          if (t.id === payload.id) return { ...t, isLoading: true };
          return t;
        }),
      };
    case GET_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tasks: payload.tasks,
      };
    case GET_PROJECT_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tasks: payload.tasks,
      };
    case GET_PROJECT_COMPLETED_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tasks: payload.tasks,
      };
    case GET_USER_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tasks: payload.tasks,
      };
    case GET_ASSIGNEDTO_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tasks: payload.tasks,
      };
    case GET_DEPARTMENT_TASKS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tasks: payload.tasks,
      };
    case GET_TASK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        task: payload.task,
      };
    case ADD_TASK_SUCCESS:
      return {
        ...state,
        // tasks: payload.tasks,
        tasks: state.tasks.map((t) => {
          if (t.id === 0) return payload.task;
          return t;
        }),
      };
    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        tasks: state.tasks.filter((t) => t.id !== payload.task.id),
      };
    case EDIT_TASK_SUCCESS:      
      return {
        ...state,
        // tasks: newTasks,
        tasks: state.tasks
          .filter((t) => {
            if (t.id === payload.task.id) {
              if (payload.task.complete !== t.complete) {
                return false;
              }
            }
            return true;
          })
          .map((t) => {
            if (t.id === payload.task.id) {              
              return payload.task;                       
            }
            return t;            
          }),
        // tasks: payload.tasks,
      };
    case DELETE_TASK_FAIL:
    case EDIT_TASK_FAIL:
      return {
        ...state,
        error: null,
        tasks: state.tasks.map((t) => {
          if (t.id === payload.id) return { ...t, isLoading: false, error: payload.error };
          return t;
        }),
      };
    case GET_TASKS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_PROJECT_TASKS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_PROJECT_COMPLETED_TASKS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_USER_TASKS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_ASSIGNEDTO_TASKS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_DEPARTMENT_TASKS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case GET_TASK_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    case ADD_TASK_FAIL:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
        tasks: state.tasks.filter((t) => t.id !== 0),
      };
    case CLEAR_TASK_ERROR:
      return {
        ...state,
        tasks: state.tasks.map((t) => {
          if (t.id === payload.id) return { ...t, isLoading: false, error: null };
          return t;
        }),
      };
    default:
      return state;
  }
}
