import React, { useEffect, useState, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import Loader from '../Loader/Loader';
import Layout from '../../layout/Layout';
import moment from 'moment-timezone';
// import { Calendar, CalendarCell, Button, Heading, CalendarGrid } from 'react-aria-components';
import { month, normalizeKPIData } from '../../constants/index';
import { Line, Bar } from 'react-chartjs-2';
import requireAuth from '../../hoc/requireAuth';
import LetterS from '../HuddleBoards/LettersLayout/LetterS';
import LetterQ from '../HuddleBoards/LettersLayout/LetterQ';
import LetterC from '../HuddleBoards/LettersLayout/LetterC';
import LetterP from '../HuddleBoards/LettersLayout/LetterP';
import LetterD from '../HuddleBoards/LettersLayout/LetterD';
import Table from './Table/Table';
import {
  fetchHuddleBoard,
  createHuddleBoard,
  updateCustomHuddleBoard,
  fetchHuddleBoards,
} from '../../store/actions/huddleBoardAction';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { useSelector } from 'react-redux';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './KPIView.css';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const KPIView = ({
  fetchHuddleBoard,
  fetchHuddleBoards,
  huddleBoard: { huddleBoard, huddleBoards, isLoading: isLoadingHuddleBoard },
  createHuddleBoard,
  updateCustomHuddleBoard,
  history,
  match,
  departments,
  isDepartmentView = false,
  auth,
}) => {
  const matchProjectId = isDepartmentView ? departments.id : match.params.projectId;
  // placeholder data for pacing rows
  const [activeRows, setActiveRows] = useState(
    isDepartmentView
      ? []
      : departments?.filter((department) => !department.viewOnly)?.map((d, index) => ({
          departmentID: d.id,
          status: true,
          actionsGoal: true,
          updatedDate: true,
        })) || [{}],
  );

  // Separate dates for each SQCPD letter
  const [selectedDates, setSelectedDates] = useState({
    S: new Date(),
    Q: new Date(),
    C: new Date(),
    D: new Date(),
    P: new Date(),
  });

  const [isDeleteConfirmed, setIsDeleteConfirmed] = useState({ state: false, index: null });

  // const selectedDate = new Date(moment().add(1, 'day').toISOString()); // current date modifier for testing edge cases (i.e. mid-week is end of month)
  const selectedDate = new Date();

  const year = selectedDate.getFullYear().toString();

  const monthName = month[selectedDate.getMonth()];

  const currentDate = selectedDate.getDate() - 1;

  const [reasonIndex, setReasonIndex] = useState(null);

  const [secondsToRefresh, setSecondsToRefresh] = useState(10);
  
  const reasonsFilteredLetter = (letterIndex) => {
    const letter = ['S', 'Q', 'C', 'D', 'P'];
    const selectedYear = selectedDates[letter[letterIndex]].getFullYear().toString();
    const selectedMonthName = month[selectedDates[letter[letterIndex]].getMonth()];

    return (
      huddleBoard?.data[1]?.years?.[selectedYear]?.[selectedMonthName]?.reduce((acc, day) => {
        day[letterIndex].forEach((group) => {
          if (group.Reason) {
            const existingReason = acc.find((item) => item[0] === group.Reason);
            if (existingReason) {
              existingReason[1]++; // Increment count
            } else {
              acc.push([group.Reason, 1]); // Add new reason with count 1
            }
          }
        });
        return acc;
      }, []) || []
    );
  };

  const reasonsFilteredSQCDP = {
    S: reasonsFilteredLetter(0),
    Q: reasonsFilteredLetter(1),
    C: reasonsFilteredLetter(2),
    D: reasonsFilteredLetter(3),
    P: reasonsFilteredLetter(4),
  };

  const [reason, setReason] = useState('');

  const [targetDate, setTargetDate] = useState(null);
  const [targetYear, setTargetYear] = useState(null);
  const [targetMonthName, setTargetMonthName] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const daysInMonth = new Date(year, selectedDate.getMonth() + 1, 0).getDate();

  const [error, setError] = useState(() => {
    return Array(5).fill(Array(2).fill(''));
  });

  const isKPIExists = useMemo(
    () =>
      // isDepartmentView
      //   ? huddleBoards?.find((item) => item.department === matchProjectId && item.title === 'KPI')
      //   : huddleBoards?.find((item) => item.project === matchProjectId && item.title === 'KPI'),
      huddleBoard ? 
        true : false,
    // [huddleBoards, matchProjectId, isDepartmentView],
    [huddleBoard],
  );

  const initialValues = useMemo(
    () => ({
      actions: isDepartmentView
        ? []
        : Array.isArray(departments)
        ? departments.filter((department) => !department.viewOnly).map((department) => ({
            departmentID: department.id || '',
            status:
              huddleBoard?.data[0]?.actions?.find((action) => action.departmentID === department.id)
                ?.status || '',
            actionsGoal:
              huddleBoard?.data[0]?.actions?.find((action) => action.departmentID === department.id)
                ?.actionsGoal || [],
            updatedDate:
              huddleBoard?.data[0]?.actions?.find((action) => action.departmentID === department.id)
                ?.updatedDate || new Date().toISOString(),
          }))
        : [],
    }),
    [departments, huddleBoard?.id, isDepartmentView],
  );

  const getAvailableMonths = () => {
    const months = huddleBoard?.data[1]?.years?.[year] || {};
    return Object.keys(months);
  };

  const getMinMaxDates = () => {
    const availableMonths = getAvailableMonths();
    if (availableMonths.length === 0) return { min: null, max: null };

    const minMonth = Math.min(...availableMonths.map((m) => month.indexOf(m)));
    const maxMonth = Math.max(...availableMonths.map((m) => month.indexOf(m)));

    return {
      min: new Date(year, minMonth, 1),
      max: new Date(year, maxMonth + 1, 0), // Last day of max month
    };
  };

  const [graphDateRange, setGraphDateRange] = useState(() => {
    // const { min, max } = getMinMaxDates();
    const sqcdp = ['S', 'Q', 'C', 'D', 'P'];
    return Array(5)
      .fill()
      .map((_, index) => ({
        // startDate: min || new Date(year, selectedDates[sqcdp[index]].getMonth(), 1),
        // endDate: max || new Date(year, selectedDates[sqcdp[index]].getMonth() + 1, 0),
        startDate: new Date(year, selectedDates[sqcdp[index]].getMonth(), 1),
        endDate: new Date(year, selectedDates[sqcdp[index]].getMonth() + 1, 0),
      }));
  });

  // Add handler for date range changes
  const handleGraphDateRangeChange = (index, type, date) => {
    setGraphDateRange((prev) => ({
      ...prev,
      [index]: {
        ...prev[index],
        [type]: date,
      },
    }));
  };

  const [tableIndex, setTableIndex] = useState(null);

  const outsideData = {
    LetterS: {
      data:
        huddleBoard?.data[1]?.years?.[selectedDates.S.getFullYear()]?.[
          month[selectedDates.S.getMonth()]
        ]?.map((day) => {
          if (!day?.[0] || !Array.isArray(day[0])) return null;
          const [item1, item2] = day[0];
          if (!item1 || !item2) return null;

          const actual0 = Number(item1?.Actual) || 0;
          // const actual1 = Number(item2?.Actual) || 0;
          const planned0 = Number(item1?.Planned) || 0;
          // const planned1 = Number(item2?.Planned) || 0;

          // if (actual0 === 0 && planned0 === 0 && actual1 === 0 && planned1 === 0) return null;
          // return actual0 + actual1 <= planned0 + planned1;
          // if (actual0 === 0 && planned0 === 0) return null;
          if (!actual0 || !planned0) return null;
          return actual0 <= planned0;
        }) || [],
      startDate: new Date(selectedDates.S.getFullYear(), selectedDates.S.getMonth(), 1),
      endDate: new Date(selectedDates.S.getFullYear(), selectedDates.S.getMonth() + 1, 0),
    },
    LetterQ: {
      data:
        huddleBoard?.data[1]?.years?.[selectedDates.Q.getFullYear()]?.[
          month[selectedDates.Q.getMonth()]
        ]?.map((day) => {
          if (!day?.[1] || !Array.isArray(day[1])) return null;
          const [item1, item2] = day[1];
          if (!item1 || !item2) return null;

          const actual0 = Number(item1?.Actual) || 0;
          // const actual1 = Number(item2?.Actual) || 0;
          const planned0 = Number(item1?.Planned) || 0;
          // const planned1 = Number(item2?.Planned) || 0;

          // if (actual0 === 0 && planned0 === 0 && actual1 === 0 && planned1 === 0) return null;
          // return actual0 + actual1 <= planned0 + planned1;
          // if (actual0 === 0 && planned0 === 0) return null;
          if (!actual0 || !planned0) return null;
          return actual0 <= planned0;
        }) || [],
      startDate: new Date(selectedDates.Q.getFullYear(), selectedDates.Q.getMonth(), 1),
      endDate: new Date(selectedDates.Q.getFullYear(), selectedDates.Q.getMonth() + 1, 0),
    },
    LetterC: {
      data:
        huddleBoard?.data[1]?.years?.[selectedDates.C.getFullYear()]?.[
          month[selectedDates.C.getMonth()]
        ]?.map((day) => {
          if (!day?.[2] || !Array.isArray(day[2])) return null;
          const [item1, item2] = day[2];
          if (!item1 || !item2) return null;

          const actual0 = Number(item1?.Actual) || 0;
          // const actual1 = Number(item2?.Actual) || 0;
          const planned0 = Number(item1?.Planned) || 0;
          // const planned1 = Number(item2?.Planned) || 0;

          // if (actual0 === 0 && planned0 === 0 && actual1 === 0 && planned1 === 0) return null;
          // return actual0 + actual1 <= planned0 + planned1;
          // if (actual0 === 0 && planned0 === 0) return null;
          if (!actual0 || !planned0) return null;
          return actual0 <= planned0;
        }) || [],
      startDate: new Date(selectedDates.C.getFullYear(), selectedDates.C.getMonth(), 1),
      endDate: new Date(selectedDates.C.getFullYear(), selectedDates.C.getMonth() + 1, 0),
    },
    LetterP: {
      data:
        huddleBoard?.data[1]?.years?.[selectedDates.P.getFullYear()]?.[
          month[selectedDates.P.getMonth()]
        ]?.map((day) => {
          if (!day?.[3] || !Array.isArray(day[3])) return null;
          const [item1, item2] = day[3];
          if (!item1 || !item2) return null;

          const actual0 = Number(item1?.Actual) || 0;
          // const actual1 = Number(item2?.Actual) || 0;
          const planned0 = Number(item1?.Planned) || 0;
          // const planned1 = Number(item2?.Planned) || 0;

          // if (actual0 === 0 && planned0 === 0 && actual1 === 0 && planned1 === 0) return null;
          // return actual0 + actual1 <= planned0 + planned1;
          // if (actual0 === 0 && planned0 === 0) return null;
          if (!actual0 || !planned0) return null;
          return actual0 <= planned0;
        }) || [],
      startDate: new Date(selectedDates.P.getFullYear(), selectedDates.P.getMonth(), 1),
      endDate: new Date(selectedDates.P.getFullYear(), selectedDates.P.getMonth() + 1, 0),
    },
    LetterD: {
      data:
        huddleBoard?.data[1]?.years?.[selectedDates.D.getFullYear()]?.[
          month[selectedDates.D.getMonth()]
        ]?.map((day) => {
          if (!day?.[4] || !Array.isArray(day[4])) return null;
          const [item1, item2] = day[4];
          if (!item1 || !item2) return null;

          const actual0 = Number(item1?.Actual) || 0;
          // const actual1 = Number(item2?.Actual) || 0;
          const planned0 = Number(item1?.Planned) || 0;
          // const planned1 = Number(item2?.Planned) || 0;

          // if (actual0 === 0 && planned0 === 0 && actual1 === 0 && planned1 === 0) return null;
          // return actual0 + actual1 <= planned0 + planned1;
          // if (actual0 === 0 && planned0 === 0) return null;
          if (!actual0 || !planned0) return null;
          return actual0 <= planned0;
        }) || [],
      startDate: new Date(selectedDates.D.getFullYear(), selectedDates.D.getMonth(), 1),
      endDate: new Date(selectedDates.D.getFullYear(), selectedDates.D.getMonth() + 1, 0),
    },
  };

  useEffect(() => {
    if (isDepartmentView) {
      fetchHuddleBoard(matchProjectId, 'KPI', true, isDepartmentView);
    } else {
      fetchHuddleBoard(matchProjectId, 'KPI');
      fetchHuddleBoards(matchProjectId, false, true);
    }
    setSecondsToRefresh(10);
    
  }, [fetchHuddleBoard, fetchHuddleBoards, matchProjectId, isDepartmentView]);

  useEffect(() => {    
    if (secondsToRefresh > -1) {
      const timerId = setInterval(() => {
        setSecondsToRefresh((prevSeconds) => prevSeconds - 1); // decrement seconds
      }, 1000);

      // Clean up the interval on component unmount or when seconds reach 0
      return () => clearInterval(timerId);
    } else {
      if (isDepartmentView) {
        fetchHuddleBoard(matchProjectId, 'KPI', true, isDepartmentView);
      } else {
        fetchHuddleBoard(matchProjectId, 'KPI');
        fetchHuddleBoards(matchProjectId, false, true);
      }
      setSecondsToRefresh(10);
    }
    
  }, [secondsToRefresh]);

  const [newTables, setNewTables] = useState(
    /* Array(5)
      .fill()
      .map((_, index) => {
        const tableData =
          huddleBoard?.data[1]?.years?.[year]?.[monthName]?.[currentDate]?.[index] || [];
        const uniqueLabels = [...new Set(tableData.map((item) => item.Label))].filter(Boolean);
        return uniqueLabels.length ? uniqueLabels : ['', ''];
      }), */
    /* huddleBoard?.data[1]?.years?.[year]?.[monthName]?.[currentDate]?.map((item) =>
      item.map((obj) => obj.Label),
    ) || Array(5).fill(Array(2).fill('')), */
    () => {
      // const existingTables = huddleBoard?.data[1]?.years?.[year]?.[monthName]?.[currentDate];
      const existingTables = huddleBoard?.data[3]?.master?.metrics;
      if (existingTables) {
        return Array(5)
          .fill()
          .map((_, index) => {
            const tableData = existingTables[index] || [];
            // Get unique labels from the table data
            const uniqueLabels = [...new Set(tableData.map((item) => item.Label))].filter(Boolean);
            return uniqueLabels.length ? uniqueLabels : ['', ''];
          });
      }
      return Array(5)
        .fill()
        .map(() => ['', '']);
    },
  );

  const [tables, setTables] = useState(
    /* huddleBoard?.data[1]?.years?.[year]?.[monthName]?.[currentDate]?.map((item) =>
      item.map((obj) => (obj.id.split('_')[0] === '' ? false : true)),
    ) || Array(5).fill(Array(2).fill(false)), */
    () => {
      // const existingTables = huddleBoard?.data[1]?.years?.[year]?.[monthName]?.[currentDate];
      const existingTables = huddleBoard?.data[3]?.master?.metrics;
      if (existingTables) {
        return existingTables.map((tableGroup) => {
          // If the tableGroup has any entries with Labels, mark those positions as true
          return [tableGroup?.[0]?.Label ? true : false, tableGroup?.[1]?.Label ? true : false];
        });
      }
      return Array(5).fill([false, false]);
    },
  );

  useEffect(() => {
    if (huddleBoard?.data[1]?.years?.[year]?.[monthName]?.[currentDate]) {
      // const existingTables = huddleBoard.data[1].years[year][monthName][currentDate];
      const existingTables = huddleBoard?.data[3]?.master?.metrics;

      setNewTables(
        Array(5)
          .fill()
          .map((_, index) => {
            const tableData = existingTables?.[index] || [];
            const uniqueLabels = [...new Set(tableData.map((item) => item.Label))].filter(Boolean);
            return uniqueLabels.length ? uniqueLabels : ['', ''];
          }),
      );

      setTables(
        existingTables?.map((tableGroup) => [
          tableGroup?.[0]?.Label ? true : false,
          tableGroup?.[1]?.Label ? true : false,
        ]) || Array(5).fill([false, false]),
      );
    }
  }, [huddleBoard?.id, year, monthName, currentDate]);

  const handleTableInput = (e, index, i) => {
    const value = e.target.value;
    setNewTables((prevTables) => {
      const updatedTables = [...prevTables];
      updatedTables[index][i] = value;
      return updatedTables;
    });
  };

  // Add loading states for different operations
  const [isInitializing, setIsInitializing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isActionUpdating, setIsActionUpdating] = useState({});
  const [isStatusUpdating, setIsStatusUpdating] = useState(false);

  // Update handleChangeStatus to use optimistic updates
  const handleChangeStatus = async (status) => {
    try {
      setIsStatusUpdating(true);
      const prevData = [...huddleBoard.data.slice(0, 2)];
      const afterData = [...huddleBoard.data.slice(3)];
      const update = [
        ...prevData,
        {
          overallStatus: status,
          updatedDate: new Date().toISOString(),
        },
        ...afterData,
      ];

      // Optimistically update the UI
      const updatedHuddleBoard = {
        ...huddleBoard,
        data: update,
      };

      await updateCustomHuddleBoard(huddleBoard?.id, update, updatedHuddleBoard);
      if (isDepartmentView) {
        await fetchHuddleBoard(matchProjectId, 'KPI', true, isDepartmentView);
      } else {
        await fetchHuddleBoard(matchProjectId, 'KPI');
      }
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
      setIsStatusUpdating(false);
    }
  };

  // ACTIONS
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const update = [
        {
          actions: values.actions.map((action) => ({
            ...action,
            // updatedDate: new Date().toISOString(),
          })),
        },
        huddleBoard?.data[1] || {},
        huddleBoard?.data[2] || {},
        huddleBoard?.data[3] || {},
      ];

      await updateCustomHuddleBoard(huddleBoard?.id, update, huddleBoard);
      // Removed fetchHuddleBoard call to prevent infinite loop
    },
  });

  const handleActionAppend = async (index) => {
    const updatedActions = [...formik.values.actions];

    // Ensure the action at this index exists and has an actionsGoal array
    updatedActions[index] = {
      ...updatedActions[index],
      actionsGoal: [...(updatedActions[index]?.actionsGoal || []), ''],
      updatedDate: new Date().toISOString(),
    };

    // If this is the first action for this department, initialize other required fields
    if (!updatedActions[index].status) {
      updatedActions[index].status = '';
    }

    const update = [
      {
        actions: updatedActions,
      },
      huddleBoard?.data[1] || {},
      huddleBoard?.data[2] || {},
    ];

    try {
      await updateCustomHuddleBoard(huddleBoard?.id, update, huddleBoard);
      // Removed fetchHuddleBoard call to prevent infinite loop
    } catch (error) {
      console.error('Error appending action:', error);
    }
  };

  const handleDeleteConfirmation = (index, actionIndex, e) => {
    e.stopPropagation();
    setIsDeleteConfirmed({ state: true, index: index, action: actionIndex });
  };

  const handleDeleteAction = async (index, actionIndex) => {
    const updatedActions = [...formik.values.actions];
    updatedActions[index] = {
      ...updatedActions[index],
      actionsGoal: updatedActions[index].actionsGoal.filter((_, i) => i !== actionIndex),
      updatedDate: new Date().toISOString(),
    };
    const update = [
      {
        actions: updatedActions,
      },
      huddleBoard?.data[1] || {},
      huddleBoard?.data[2] || {},
    ];

    await updateCustomHuddleBoard(huddleBoard?.id, update, huddleBoard);
    // Removed fetchHuddleBoard call to prevent infinite loop
    setIsDeleteConfirmed({ state: false, index: null, action: null });
  };
  // END ACTIONS

  // REASONS
  const handleAddReason = async () => {
    if (
      !reason ||
      !huddleBoard?.data ||
      targetDate === null ||
      targetMonthName === null ||
      targetYear === null ||
      reasonIndex === null
    )
      return;

    try {
      const monthData = huddleBoard.data[1]?.years?.[targetYear]?.[targetMonthName];
      /* 
        console.log(reasonIndex); */
      if (!monthData || !monthData[targetDate] || !monthData[targetDate][reasonIndex]) {
        console.error('Target table data not found');
        return;
      }
      // Preserve all existing data while updating reasons
      monthData[targetDate] = monthData[targetDate].map((tableGroup, index) => {
        if (index === reasonIndex) {
          return tableGroup.map((item, idx) => {
            if (idx === tableIndex) {
              return { ...item, Reason: reason };
            }
            return item;
          });
        }
        return tableGroup;
      });

      // Update the huddle board with new data, preserving all month data
      const updatedData = huddleBoard.data;
      updatedData[1].years[targetYear][targetMonthName] = monthData;
      const update = updatedData;

      await updateCustomHuddleBoard(huddleBoard.id, update, huddleBoard);
      // Removed fetchHuddleBoard call to prevent infinite loop

      // Reset modal state
      setReason('');
      setIsModalOpen(false);
      setReasonIndex(null);
      setTargetDate(null);
    } catch (error) {
      console.error('Error updating reason:', error);
    }
  };

  const handleSetReason = (index, tableIndex, targetYear, targetMonthName, targetDate) => {
    setReasonIndex(index);
    setTargetDate(targetDate);
    setTargetYear(targetYear);
    setTargetMonthName(targetMonthName);
    setIsModalOpen(true);
    setTableIndex(tableIndex);
  };

  const getReasonsByFrequency = (letterIndex) => {
    const reasonsData = huddleBoard?.data[1]?.years?.[year]?.[monthName] || [];
    const allReasons = [];

    // Safely collect all reasons
    reasonsData.forEach((day) => {
      day?.forEach((group) => {
        group?.forEach((item) => {
          if (item?.Reason) {
            allReasons.push(item.Reason);
          }
        });
      });
      // day[letterIndex]?.group?.forEach((item) => {
      //     if (item?.Reason) {
      //       allReasons.push(item.Reason);
      //     }
      //   });
      
    });

    // Count frequencies
    const frequencyMap = allReasons.reduce((acc, reason) => {
      acc[reason] = (acc[reason] || 0) + 1;
      return acc;
    }, {});

    // Convert to array and sort
    return Object.entries(frequencyMap)
      .filter(([reason]) => reason) // Remove empty reasons
      .sort(([, a], [, b]) => b - a);
  };
  // END REASONS

  // Add loading state for initialization
  const handleCreateKPI = async () => {
    try {
      setIsInitializing(true);
      const daysInMonth = new Date(year, selectedDate.getMonth() + 1, 0).getDate();
      await createHuddleBoard({
        project: isDepartmentView ? departments.project : matchProjectId,
        department: isDepartmentView ? matchProjectId : null,
        title: 'KPI',
        description: 'KPI View Board',
        order: 1,
        data: [
          initialValues,
          {
            years: {
              [year]: {
                [monthName]: Array(daysInMonth)
                  .fill()
                  .map(() => {
                    return Array(5)
                      .fill()
                      .map((_, i) => [
                        {
                          id: `_${i}_0`,
                          Label: '',
                          Actual: '',
                          Planned: '',
                          Reason: '',
                        },
                        {
                          id: `_${i}_1`,
                          Label: '',
                          Actual: '',
                          Planned: '',
                          Reason: '',
                        },
                      ]);
                  }),
              },
            },
          },
          {
            overallStatus: '',
            updatedDate: new Date().toISOString(),
          },
          {
            master: {
              metrics: Array(5)
                .fill()
                .map((_, i) => [
                  {
                    id: `_${i}_0`,
                    Label: '',
                  },
                  {
                    id: `_${i}_1`,
                    Label: '',
                  },
                ]),
            },
          },
        ],
        tag: null,
      });
      // Pass the departmentView parameter as a string
      if (isDepartmentView) {
        await fetchHuddleBoard(matchProjectId, 'KPI', true, isDepartmentView);
      } else {
        await fetchHuddleBoard(matchProjectId, 'KPI');
      }
    } catch (error) {
      console.error('Error creating KPI board:', error);
    } finally {
      setIsInitializing(false);
    }
  };

  // Update LoadingSkeleton to only show during initial load
  const LoadingSkeleton = () => {
    if (!isLoadingHuddleBoard && !isInitializing) return null;

    return (
      <div className="kpi-view-container adaptive-container">
        <div className="kpi-view__content kpi-view-content">
          <div className="skeleton-loading">
            <div className="skeleton-header"></div>
            <div className="skeleton-pacing">
              <div className="skeleton-status"></div>
              <div className="skeleton-departments"></div>
            </div>
            <div className="skeleton-sqcpd">
              {[1, 2, 3, 4, 5].map((i) => (
                <div key={i} className="skeleton-letter"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Update the render logic to handle loading states
  if (!isKPIExists) {
    return (      
      <Layout>
        <div className="kpi-view-container adaptive-container">
          { (isLoadingHuddleBoard || isInitializing) ? <Loader /> : <div className="kpi-view__form-create">
            <h2 className="Material-KitSB1">Ready to initialize your Tier View?</h2>
            <button
              type="button"
              onClick={handleCreateKPI}
              disabled={isLoadingHuddleBoard || isInitializing}
              className="Material-KitBS2"
            >
              {isLoadingHuddleBoard || isInitializing ? 'Initializing...' : 'Start'}
            </button>
          </div> }
        </div>
      </Layout>      
    );
  }

  // Show loading skeleton only during initial load
  if (isLoadingHuddleBoard && !huddleBoard) {
    return <LoadingSkeleton />;
  }

  const handlePrevMonth = (letter) => {
    const sqcdp = ['S', 'Q', 'C', 'D', 'P'];
    setSelectedDates((prev) => ({
      ...prev,
      [letter]: new Date(prev[letter].getFullYear(), prev[letter].getMonth() - 1, 1),
    }));
    setGraphDateRange((prev) => {
      const newRange = [...prev];
      newRange[sqcdp.indexOf(letter)].startDate = new Date(
        prev[sqcdp.indexOf(letter)].startDate?.getFullYear(),
        prev[sqcdp.indexOf(letter)].startDate?.getMonth() - 1,
        1,
      );
      newRange[sqcdp.indexOf(letter)].endDate = new Date(
        prev[sqcdp.indexOf(letter)].endDate?.getFullYear(),
        prev[sqcdp.indexOf(letter)].endDate?.getMonth(),
        0,
      );
      return newRange;
    });
  };

  const handleNextMonth = (letter) => {
    const sqcdp = ['S', 'Q', 'C', 'D', 'P'];
    setSelectedDates((prev) => ({
      ...prev,
      [letter]: new Date(prev[letter].getFullYear(), prev[letter].getMonth() + 1, 1),
    }));
    setGraphDateRange((prev) => {
      const newRange = [...prev];
      newRange[sqcdp.indexOf(letter)].startDate = new Date(
        prev[sqcdp.indexOf(letter)].startDate?.getFullYear(),
        prev[sqcdp.indexOf(letter)].startDate?.getMonth() + 1,
        1,
      );
      newRange[sqcdp.indexOf(letter)].endDate = new Date(
        prev[sqcdp.indexOf(letter)].endDate?.getFullYear(),
        prev[sqcdp.indexOf(letter)].endDate?.getMonth() + 2,
        0,
      );
      return newRange;
    });
  };

  const handleInputToggle = async (index, field, payload, actionType) => {
    let formikActionIndex = null;
    try {
      setIsActionUpdating((prev) => ({ ...prev, [index]: true }));

      switch (field) {
        case 'status':
          formikActionIndex = formik.values.actions?.findIndex(
            (action) => action.departmentID === index,
          );

          // Optimistically update the UI
          const newStatus =
            actionType === 'cancel'
              ? huddleBoard?.data[0]?.actions?.find((action) => action.departmentID === index)
                  ?.status || ''
              : payload;

          formik.setFieldValue(`actions[${formikActionIndex}].status`, newStatus);
          formik.setFieldValue(
            `actions[${formikActionIndex}].updatedDate`,
            new Date().toISOString(),
          );

          if (actionType !== 'cancel') {
            await formik.handleSubmit();
          }
          break;

        case 'actionsGoal':
          // Optimistically update the UI
          setActiveRows((prevRows) => {
            const newRows = prevRows.map((row) => {
              if (row.departmentID === index) {
                return {
                  ...row,
                  actionsGoal: !row.actionsGoal,
                };
              }
              return row;
            });
            return newRows;
          });

          if (actionType !== 'cancel') {
            await formik.handleSubmit();
          }
          break;

        default:
          console.error('Invalid type entry in the button');
          break;
      }
    } catch (error) {
      console.error('Error updating action:', error);
      // Revert optimistic update on error
      if (field === 'status' && formikActionIndex !== null) {
        formik.setFieldValue(
          `actions[${formikActionIndex}].status`,
          huddleBoard?.data[0]?.actions?.find((action) => action.departmentID === index)?.status ||
            '',
        );
      }
    } finally {
      setIsActionUpdating((prev) => ({ ...prev, [index]: false }));
    }
  };

  const handleAddTable = (index, i) => {
    if (!newTables[index][i] || newTables[index][i].length < 3) {
      setError((prevError) => {
        const newError = [...prevError];
        newError[index][i] = 'No name for table has been entered';
        return newError;
      });
      setTimeout(() => {
        setError((prevError) => {
          const newError = [...prevError];
          newError[index][i] = '';
          return newError;
        });
      }, 2000);
      return;
    }
    let updatedTables = huddleBoard.data[1].years[year][monthName];
    updatedTables.forEach((day) => {
      day[index][i].Label = newTables[index][i];
      day[index][i].id = `${newTables[index][i]}_${i}`;
      return day;
    });
    let updatedMasterMetrics = huddleBoard.data[3]?.master?.metrics;
    updatedMasterMetrics[index][i].Label = newTables[index][i];
    updatedMasterMetrics[index][i].id = `${newTables[index][i]}_${i}`;
    let updatedMaster = huddleBoard.data[3]?.master;
    updatedMaster.metrics = updatedMasterMetrics;
    updateCustomHuddleBoard(
      huddleBoard.id,
      [
        huddleBoard.data[0],
        { years: { [year]: { [monthName]: updatedTables } } },
        huddleBoard.data[2],
        { master: updatedMaster },
      ],
      huddleBoard,
    );
    /* setNewTables((prevTables) => {
      const newTempTables = [...prevTables];
      newTempTables[index][i] = '';
      return newTempTables;
    }); */
    setTables((prevTables) => {
      const newTempTables = [...prevTables];
      newTempTables[index][i] = true;
      return newTempTables;
    });
  };

  // Add this new function before the return statement
  const getGroupedReasonsByFrequency = () => {
    const reasonsData = huddleBoard?.data[1]?.years?.[year]?.[monthName] || [];
    const groupedReasons = Array(5)
      .fill()
      .map(() => []);

    // Collect reasons for each group
    reasonsData.forEach((day) => {
      if (!Array.isArray(day)) return;

      day.forEach((group, groupIndex) => {
        if (!Array.isArray(group)) return;

        group.forEach((item) => {
          if (item?.Reason && item.Reason.trim()) {
            groupedReasons[groupIndex].push(item.Reason.trim());
          }
        });
      });
    });

    // Process each group's frequencies and sort
    return groupedReasons.map((reasons) => {
      const frequencyMap = reasons.reduce((acc, reason) => {
        acc[reason] = (acc[reason] || 0) + 1;
        return acc;
      }, {});

      return Object.entries(frequencyMap).sort((a, b) => {
        // Sort by frequency (descending)
        if (b[1] !== a[1]) {
          return b[1] - a[1];
        }
        // If frequencies are equal, sort alphabetically
        return a[0].localeCompare(b[0]);
      });
    });
  };

  const renderLetter = (letter) => {
    let outsideDataKeyName = '';
    let letterIndex = -1;
    let letterName = '';
    switch (letter) {
      case 'S':
        outsideDataKeyName = 'LetterS';
        letterIndex = 0;
        letterName = 'Safety';
        break;
      case 'Q':
        outsideDataKeyName = 'LetterQ';
        letterIndex = 1;
        letterName = 'Quality';
        break;
      case 'C':
        outsideDataKeyName = 'LetterC';
        letterIndex = 2;
        letterName = 'Cost';
        break;
      case 'D':
        outsideDataKeyName = 'LetterD';
        letterIndex = 3;
        letterName = 'Delivery';
        break;
      case 'P':
        outsideDataKeyName = 'LetterP';
        letterIndex = 4;
        letterName = 'People';
        break;
      default:
        console.error('Invalid letter');
        break;
    }

    const getSubComponent = (letter) => {
      let customProps = {
        isLayout: false,
        huddleBoard: huddleBoard,
        selectedDate: selectedDates[letter],
        setSelectedDate: (date) => setSelectedDates((prev) => ({ ...prev, [letter]: date })),
        outsideData: outsideData[outsideDataKeyName],
      };

      switch (letter) {
        case 'S':
          return <LetterS {...customProps} />;
        case 'Q':
          return <LetterQ {...customProps} />;
        case 'C':
          return <LetterC {...customProps} />;
        case 'D':
          return <LetterD {...customProps} />;
        case 'P':
          return <LetterP {...customProps} />;
      }
    };

    return (
      <>
        <div className="letters-kpi-view__letter-header flex flex-row justify-space-between align-center">
          {/* week selector */}
          <div className="select-period status-kpi-view__date flex flex-gap-16">
            <div className="select-period-button__prev" onClick={() => handlePrevMonth(letter)}>
              {'<'}
            </div>
            <span>{moment(selectedDates[letter]).format('MMMM')}</span>
            <div className="select-period-button__prev" onClick={() => handleNextMonth(letter)}>
              {'>'}
            </div>
          </div>
          <div className="Material-KitHB6">{letterName}</div>
        </div>

        <div className="flex flex-row justify-space-between">
          <div className="flex flex-column">{getSubComponent(letter)}</div>

          <div className="flex flex-column flex-gap-24 justify-center align-center">
            <div className="Material-KitCaption">
              {getTodaysPrimaryMetricData()?.[letterIndex][0].Label}
              <br />
              {moment().format('MMM DD, YYYY')}
            </div>
            <div
              className="status-kpi-view__sqcdp-status"
              style={{
                backgroundColor: `${
                  Number(getTodaysPrimaryMetricData()?.[letterIndex][0].Actual) >
                  Number(getTodaysPrimaryMetricData()?.[letterIndex][0].Planned)
                    ? 'var(--system-red-1)'
                    : '#5bf98a'
                }`,
                color: `${
                  Number(getTodaysPrimaryMetricData()?.[letterIndex][0].Actual) >
                  Number(getTodaysPrimaryMetricData()?.[letterIndex][0].Planned)
                    ? 'white'
                    : 'black'
                }`,
              }}
            >
              {getTodaysPrimaryMetricData()?.[letterIndex][0].Actual}/
              {getTodaysPrimaryMetricData()?.[letterIndex][0].Planned}
            </div>
            <div
              className="status-kpi-view__sqcdp-status-trend"
              style={{
                color:
                  Number(getTodaysPrimaryMetricData()?.[letterIndex][0].Actual) >
                  Number(getTodaysPrimaryMetricData(-1)?.[letterIndex][0].Actual)
                    ? 'var(--system-red-1)'
                    : Number(getTodaysPrimaryMetricData()?.[letterIndex][0].Actual) <
                      Number(getTodaysPrimaryMetricData(-1)?.[letterIndex][0].Actual)
                    ? '#5BF98A'
                    : Number(getTodaysPrimaryMetricData()?.[letterIndex][0].Actual) ===
                      Number(getTodaysPrimaryMetricData(-1)?.[letterIndex][0].Actual)
                    ? 'var(--primary-primary-80)'
                    : '',
              }}
            >
              {Number(getTodaysPrimaryMetricData()?.[letterIndex][0].Actual) >
              Number(getTodaysPrimaryMetricData(-1)?.[letterIndex][0].Actual) ? (
                <>
                  <span style={{ color: 'var(--system-red-1)' }}>↑</span>&nbsp;&nbsp;+
                </>
              ) : Number(getTodaysPrimaryMetricData()?.[letterIndex][0].Actual) <
                Number(getTodaysPrimaryMetricData(-1)?.[letterIndex][0].Actual) ? (
                <>
                  <span style={{ color: '#5BF98A' }}>↓</span>&nbsp;&nbsp;
                </>
              ) : Number(getTodaysPrimaryMetricData()?.[letterIndex][0].Actual) ===
                Number(getTodaysPrimaryMetricData(-1)?.[letterIndex][0].Actual) ? (
                <>
                  <span style={{ color: 'var(--primary-primary-80)' }}>→</span>&nbsp;&nbsp;
                </>
              ) : (
                ''
              )}
              {(
                (Number(getTodaysPrimaryMetricData()?.[letterIndex][0].Actual) /
                  Number(getTodaysPrimaryMetricData(-1)?.[letterIndex][0].Actual) -
                  1) *
                100
              ).toFixed(0)}
              %
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderTable = (table, index) => {
    return (
      <div className="item-kpi-view__tables">
        <div className="item-kpi-view__title">
          <div className="Material-KitSB1_S" style={{ color: 'var(--general-general-70)' }}>
            Planned vs Actual
          </div>
        </div>
        {table?.map((isTableCreated, i) => (
          <React.Fragment key={`table-${index}-${i}`}>
            {isTableCreated ? (
              <Table
                projectId={matchProjectId}
                huddleBoard={huddleBoard}
                label={newTables[index][i]}
                labelColor={i === 0 ? 'green' : 'blue'}
                arrayRelatedToTable={[index, i]}
                setIsModalOpen={setIsModalOpen}
                onSetReason={handleSetReason}
              />
            ) : (
              (table[0] === true || i === 0) && (
                <div className="item-kpi-view__add-table-input-container">
                  <input
                    type="text"
                    onChange={(e) => handleTableInput(e, index, i)}
                    placeholder="Enter metric name"
                    className="item-kpi-view__add-table-input Material-KitLabel-1"
                  />
                  <div className={`${error[index][i] !== '' && 'visible'} kpi-view__error-message`}>
                    {error[index][i] || ''}
                  </div>
                  <button
                    onClick={() => handleAddTable(index, i)}
                    // className="item-kpi-view__add-table-submit Material-KitSB2"
                    className="task-form-buttons-create Material-KitLabel-1"
                  >
                    + Add Metric
                  </button>
                </div>
              )
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  const renderGraph = (i, letter) => {
    const selectedYear = selectedDates[letter].getFullYear().toString();
    const selectedMonthName = month[selectedDates[letter].getMonth()];

    return (
      <div className="graph-kpi-view__item flex flex-column flex-gap-16" key={i}>
        <div className="item-kpi-view__title flex flex-row justify-space-between align-center">
          <div className="Material-KitSB2_TS" style={{ color: 'var(--general-general-80)' }}>
            Chart
          </div>
          {/* <div className="graph-kpi-view__date-range">
            <div className="select-period status-kpi-view__date flex flex-gap-16">
              <label>From:</label>
              <input
                type="date"
                value={graphDateRange[i].startDate.toISOString().split('T')[0]}
                onChange={(e) =>
                  handleGraphDateRangeChange(i, 'startDate', new Date(e.target.value))
                }
                min={getMinMaxDates().min?.toISOString().split('T')[0]}
                max={graphDateRange[i].endDate.toISOString().split('T')[0]}
              />
            </div>
            <div className="select-period status-kpi-view__date flex flex-gap-16">
              <label>To:</label>
              <input
                type="date"
                value={graphDateRange[i].endDate.toISOString().split('T')[0]}
                onChange={(e) =>
                  handleGraphDateRangeChange(i, 'endDate', new Date(e.target.value))
                }
                min={graphDateRange[i].startDate.toISOString().split('T')[0]}
                max={getMinMaxDates().max?.toISOString().split('T')[0]}
              />
            </div>
          </div> */}
          <div className="select-period status-kpi-view__date flex flex-gap-16">
            <div className="select-period-button__prev" onClick={() => handlePrevMonth(letter)}>
              {'<'}
            </div>
            <span>{moment(selectedDates[letter]).format('MMMM')}</span>
            <div className="select-period-button__prev" onClick={() => handleNextMonth(letter)}>
              {'>'}
            </div>
          </div>
        </div>
        <div className="graph-kpi-view__item-content">
          <Line
            data={{
              labels: huddleBoard?.data[1]?.years?.[selectedYear]?.[selectedMonthName]
                ?.map((_, idx) => idx + 1)
                .filter((day) => {
                  const date = new Date(selectedYear, selectedDates[letter].getMonth(), day);
                  return date >= graphDateRange[i].startDate && date <= graphDateRange[i].endDate;
                }),

              datasets: [
                {
                  label: 'Planned',
                  data: huddleBoard?.data[1]?.years?.[selectedYear]?.[selectedMonthName]
                    ?.map((day, idx) => ({
                      day: idx + 1,
                      value: Number(day[i][0].Planned) || 0,
                    }))
                    .filter(({ day }) => {
                      const date = new Date(selectedYear, selectedDates[letter].getMonth(), day);
                      return (
                        date >= graphDateRange[i].startDate && date <= graphDateRange[i].endDate
                      );
                    })
                    .map(({ value }) => value),
                  borderColor: '#F85640',
                  borderDash: [7, 6],
                  tension: 0.2,
                  borderWidth: 2,
                  pointRadius: 0,
                },
                {
                  label: 'Actual ',
                  data: huddleBoard?.data[1]?.years?.[selectedYear]?.[selectedMonthName]?.map(
                    (day) => {
                      return Number(day[i][0].Actual) || 0;
                    },
                  ),
                  borderColor: '#F85640',
                  tension: 0.2,
                  borderWidth: 2,
                  pointRadius: 0,
                },
                {
                  label: 'Planned ',
                  data: huddleBoard?.data[1]?.years?.[selectedYear]?.[selectedMonthName]
                    ?.map((day, idx) => ({
                      day: idx + 1,
                      value: Number(day[i][1].Planned) || 0,
                    }))
                    .filter(({ day }) => {
                      const date = new Date(selectedYear, selectedDates[letter].getMonth(), day);
                      return (
                        date >= graphDateRange[i].startDate && date <= graphDateRange[i].endDate
                      );
                    })
                    .map(({ value }) => value),
                  borderColor: '#608dff',
                  borderDash: [7, 6],
                  tension: 0.2,
                  borderWidth: 2,
                  pointRadius: 0,
                },
                {
                  label: 'Actual',
                  data: huddleBoard?.data[1]?.years?.[selectedYear]?.[selectedMonthName]?.map(
                    (day) => {
                      return Number(day[i][1].Actual) || 0;
                    },
                  ),
                  borderColor: '#608dff',
                  tension: 0.2,
                  borderWidth: 2,
                  pointRadius: 0,
                },
              ],
            }}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    font: {
                      size: 20,
                      weight: 500,
                      family: 'Poppins',
                    },
                  },
                },
                tooltip: {
                  titleFont: {
                    size: 20,
                    weight: 500,
                    family: 'Poppins',
                  },
                  bodyFont: {
                    size: 20,
                    weight: 400,
                    family: 'Poppins',
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  ticks: {
                    font: {
                      size: 16,
                      weight: 500,
                      family: 'Poppins',
                    },
                  },
                  grid: {
                    display: false,
                  },
                },
                x: {
                  ticks: {
                    font: {
                      size: 16,
                      weight: 500,
                      family: 'Poppins',
                    },
                  },
                  grid: {
                    display: false,
                  },
                },
              },
            }}
            height={200}
          />
        </div>
      </div>
    );
  };

  const renderReasons = (letter) => {
    return (
      <div className="reasons-kpi-view__item flex flex-column flex-gap-16">
        <div className="item-kpi-view__title">
          <div className="Material-KitSB2_TS" style={{ color: 'var(--general-general-80)' }}>
            Reason Codes
          </div>
        </div>
        <div className="reasons-kpi-view__content flex flex-column flex-gap-16">
          {/* {reasonsFilteredSQCDP[letter].map(([reason, count], idx) => (
            <div key={idx} className="reasons-kpi-view__reason">
              {reason} ({count})
            </div>
          ))} */}
          <Bar
            data={{
              labels: reasonsFilteredSQCDP[letter].map((obj) => obj[0]),
              // labels: ['Reason Code Count'],
              datasets: reasonsFilteredSQCDP[letter].map((obj, index) => {
                return {
                  label: obj[0],
                  data: Array(reasonsFilteredSQCDP[letter].length)
                    .fill(0)
                    .map((_, idx) => {
                      if (idx === index) {
                        return Number(obj[1]);
                      }
                      return 0;
                    }),
                  // data: [Number(obj[1])],
                  backgroundColor: '#89ABFF',
                  borderColor: '#89ABFF',
                };
              }),
            }}
            options={{
              indexAxis: 'y',
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
                tooltip: {
                  titleFont: {
                    size: 20,
                    weight: 500,
                    family: 'Poppins',
                  },
                  bodyFont: {
                    size: 20,
                    weight: 400,
                    family: 'Poppins',
                  },
                },
              },
              scales: {
                x: {
                  stacked: true,
                  ticks: {
                    font: {
                      size: 20,
                      weight: 500,
                      family: 'Poppins',
                    },
                    beginAtZero: true,
                    stepSize: 1,
                  },
                },
                y: {
                  stacked: true,
                  ticks: {
                    font: {
                      size: 20,
                      weight: 500,
                      family: 'Poppins',
                    },
                    beginAtZero: true,
                    stepSize: 1,
                  },
                },
              },
            }}
          />
        </div>
      </div>
    );
  };

  const getTodaysPrimaryMetricData = (offset = 0) => {
    return huddleBoard?.data[1]?.years?.[
      new Date(moment().add(offset, 'days').toDate()).getFullYear().toString()
    ]?.[month[new Date(moment().add(offset, 'days').toDate()).getMonth()]]?.[
      new Date(moment().add(offset, 'days').toDate()).getDate() - 1
    ];
  };

  // Add loading indicators for individual actions
  const renderActionButton = (index, status, onClick, isOverallStatus = false) => (
    <button
      type="button"
      className={`status-kpi-view__option ${
        isOverallStatus ? 'status-kpi-view__option--overall' : ''
      }`}
      onClick={onClick}
      disabled={isActionUpdating[index] || isLoadingHuddleBoard || isStatusUpdating}
    >
      {isActionUpdating[index] || isStatusUpdating ? '...' : status === index ? '●' : ''}
    </button>
  );

  return (
    <div className="kpi-view-container adaptive-container">
      {/* <h2 className="kpi-view-breadcrumbs Material-KitH5">KPI View</h2> */}

      <div className="kpi-view__content kpi-view-content">
        <Swiper
          modules={[Pagination]}
          spaceBetween={10}
          slidesPerView={8}
          pagination={{
            clickable: true,
            el: '.kpi-view-pagination',
            bulletClass: 'kpi-view-pagination-bullet',
            bulletActiveClass: 'kpi-view-pagination-bullet-active',
          }}
          className="kpi-view__departments"
          style={{ display: 'none' }}
        >
          {/* <SwiperSlide className="kpi-view__department-slide">
            <div className="kpi-view__status status-kpi-view">
              <div className="status-kpi-view__heading flex align-center justify-space-between">
                <h3 className="status-kpi-view__title">Overall Status</h3>
                <div className="status-kpi-view__date">
                  {selectedDates.S.toLocaleString('en-US', { month: 'long' })}{' '}
                  {selectedDates.S.getDate()}
                </div>
              </div>
              <div
                className="status-kpi-view__status"
                style={{
                  backgroundColor: `${
                    huddleBoard?.data[2]?.overallStatus === 'Bad'
                      ? 'var(--system-red-1)'
                      : huddleBoard?.data[2]?.overallStatus === 'Good'
                      ? 'var(--system-green-1)'
                      : huddleBoard?.data[2]?.overallStatus === 'Average'
                      ? '#f8ab40'
                      : '#a7a7a7'
                  }`,
                }}
              ></div>
              <div className="status-kpi-view__options">
                <button
                  style={{ backgroundColor: 'var(--system-red-1)' }}
                  type="button"
                  className="status-kpi-view__option"
                  onClick={() => handleChangeStatus('Bad')}
                  disabled={isLoadingHuddleBoard}
                >
                  ▲
                </button>
                <button
                  type="button"
                  className="status-kpi-view__option"
                  onClick={() => handleChangeStatus('Average')}
                  disabled={isLoadingHuddleBoard}
                >
                  ▲
                </button>
                <button
                  style={{ backgroundColor: 'var(--system-green-1)' }}
                  type="button"
                  className="status-kpi-view__option"
                  onClick={() => handleChangeStatus('Good')}
                  disabled={isLoadingHuddleBoard}
                >
                  ▲
                </button>
              </div>
            </div>
          </SwiperSlide> */}
          {!isDepartmentView &&
            departments?.filter((department) => !department.viewOnly)?.map((dept, index) => (
              <SwiperSlide key={index} className="kpi-view__department-slide">
                <div
                  className="kpi-view__department department-kpi-view"
                  style={{ backgroundColor: dept.color }}
                >
                  <h3 className="department-kpi-view__title Material-KitSB1">{dept.name}</h3>
                  <form onSubmit={formik.handleSubmit} className="department-kpi-view__form">
                    <div className="department-kpi-view__row flex justify-space-between">
                      <label
                        htmlFor={`actions[${index}].status`}
                        className="department-kpi-view__label Material-KitB2"
                      >
                        Status:{' '}
                      </label>
                      {/* <input
                      type="text"
                      id={`actions[${index}].status`}
                      name={`actions[${index}].status`}
                      className="department-kpi-view__input Material-KitB2"
                      value={formik.values.actions[index]?.status || ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={activeRows[index]?.status}
                    /> */}

                      {/* {activeRows[index].status ? (
                      <button
                        type="button"
                        className="department-kpi-view__edit-button kpi-edit-button Material-KitButton"
                        onClick={() => {
                          handleInputToggle(index, 'status');
                        }}
                      >
                        <img src="/icons/edit.svg" alt="Edit" />
                      </button>
                    ) : (
                      <div className="department-kpi-view__buttons flex align-center">
                        <button
                          type="submit"
                          className="department-kpi-view__accept-button kpi-edit-button Material-KitButton"
                          onClick={() => {
                            handleInputToggle(index, 'status');
                          }}
                        >
                          <img src="/icons/check_small.svg" alt="Accept" />
                        </button>
                        <button
                          type="button"
                          className="department-kpi-view__cancel-button kpi-edit-button Material-KitButton"
                          onClick={() => {
                            handleInputToggle(index, 'status', 'cancel');
                          }}
                        >
                          <img src="/icons/close.svg" alt="Cancel" />
                        </button>
                      </div>
                    )} */}
                      <div className="department-kpi-view__status">
                        {renderActionButton(index, formik.values.actions[index]?.status, () =>
                          handleInputToggle(index, 'status', 'Good'),
                        )}

                        {renderActionButton(index, formik.values.actions[index]?.status, () =>
                          handleInputToggle(index, 'status', 'Bad'),
                        )}
                      </div>
                    </div>

                    <div className="department-kpi-view__row ">
                      <div className="department-kpi-view__labels flex justify-space-between">
                        <label
                          htmlFor={`actions[${index}].actionsGoal`}
                          className="department-kpi-view__label Material-KitB2"
                        >
                          Actions:{' '}
                        </label>

                        <button
                          type="button"
                          className="department-kpi-view__edit-button kpi-edit-button"
                          onClick={() => {
                            handleInputToggle(index, 'actionsGoal');
                          }}
                        >
                          <img src="/icons/edit.svg" alt="Edit" />
                        </button>
                      </div>
                      <div className="department-kpi-view__fields">
                        <div className="department-kpi-view__action action-kpi-view">
                          {formik.values.actions[index]?.actionsGoal?.map((action, i) => (
                            <div
                              key={i}
                              className="action-kpi-view__input-row flex justify-space-between align-center"
                            >
                              <div className="action-kpi-view__icon kpi-edit-button">
                                <img src="/icons/check_small.svg" alt="check" />
                              </div>
                              <input
                                type="text"
                                id={`actions[${index}].actionsGoal[${i}]`}
                                name={`actions[${index}].actionsGoal[${i}]`}
                                className="department-kpi-view__input Material-KitB2"
                                value={formik.values.actions[index]?.actionsGoal[i] || ['']}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={activeRows[index]?.actionsGoal}
                              />
                              {!activeRows[index]?.actionsGoal && (
                                <>
                                  <div className="department-kpi-view__buttons flex align-center">
                                    <button
                                      type="submit"
                                      className="department-kpi-view__accept-button kpi-edit-button Material-KitButton"
                                      onClick={() => {
                                        handleInputToggle(index, 'actionsGoal');
                                      }}
                                    >
                                      <img src="/icons/check_small.svg" alt="Accept" />
                                    </button>
                                    <button
                                      type="button"
                                      className="department-kpi-view__cancel-button kpi-edit-button Material-KitButton"
                                      onClick={() => {
                                        handleInputToggle(index, 'actionsGoal', '', 'cancel');
                                      }}
                                    >
                                      <img src="/icons/close.svg" alt="Cancel" />
                                    </button>
                                  </div>
                                  <div className="department-kpi-view__delete-wrapper">
                                    <button
                                      type="button"
                                      className="department-kpi-view__delete-button department-kpi-view__cancel-button kpi-edit-button Material-KitButton"
                                      onClick={(e) => {
                                        handleDeleteConfirmation(index, i, e);
                                      }}
                                    >
                                      <img src="/icons/delete.svg" alt="Delete" />
                                    </button>
                                    {isDeleteConfirmed.state &&
                                      isDeleteConfirmed.index === index &&
                                      isDeleteConfirmed.action === i && (
                                        <div className="department-kpi-view__delete-button-text">
                                          <span>Delete this action?</span>
                                          <div className="department-kpi-view__delete-button-confirmation flex justify-space-between">
                                            <button
                                              type="button"
                                              className="department-kpi-view__confirmation-proceed-button"
                                              onClick={() => handleDeleteAction(index, i)}
                                            >
                                              Delete
                                            </button>
                                            <button
                                              type="button"
                                              className="department-kpi-view__confirmation-cancel-button"
                                              onClick={() =>
                                                setIsDeleteConfirmed({
                                                  state: false,
                                                  index: null,
                                                  action: null,
                                                })
                                              }
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>

                        <button
                          type="button"
                          className="department-kpi-view__add-button 
                  kpi-edit-button"
                          onClick={() => handleActionAppend(index)}
                        >
                          <img src="/icons/add.svg" alt="Append action" />
                        </button>
                      </div>
                    </div>
                  </form>
                  <span className="department-kpi-view__updated-at Material-KitCaption">
                    Updated{' '}
                    {formik.values.actions[index]?.updatedDate
                      .split('T')[0]
                      .split('-')
                      .reverse()
                      .map((part, i) => (i === 1 ? month[parseInt(part) - 1] : part))
                      .join(' ')}
                  </span>
                </div>
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="kpi-view-pagination"></div>

        <div className={`${formik.values.actions?.length > 6 ? 'modifier' : ''} kpi-view__pacing`}>
          <div
            className={`${
              formik.values.actions?.length > 6 ? 'many-elements' : 'flex flex-column flex-gap-24'
            } `}
          >
            <div className="Material-KitH6">Overall Status</div>
            <div className="kpi-view__status status-kpi-view"
              style={{
                backgroundColor: `${
                  huddleBoard?.data[2]?.overallStatus === 0
                    ? '#ffddd8' //bad
                    : huddleBoard?.data[2]?.overallStatus === 1
                    ? '#fbdbae' //avarage
                    : 'var(--general-general-30)'
                }`,
              }}
            >
              <div
                className={`${
                  formik.values.actions?.length > 6
                    ? 'status-kpi-view__content'
                    : 'flex-center flex-row flex-gap-16 full-width flex-right'
                }`}
              >
                <div
                  className="status-kpi-view__status"
                  style={{
                    backgroundColor: `${
                      huddleBoard?.data[2]?.overallStatus === 0
                        ? 'var(--system-red-1)' //bad
                        : huddleBoard?.data[2]?.overallStatus === 1
                        ? '#f8ab40' //avarage
                        : huddleBoard?.data[2]?.overallStatus === 2
                        ? 'var(--system-green-1)' //good
                        : '#a7a7a7'
                    }`,
                  }}
                ></div>
                <div className="status-kpi-view__options">
                  <button
                    style={{ backgroundColor: 'var(--system-red-1)' }}
                    type="button"
                    className="status-kpi-view__option"
                    onClick={() => handleChangeStatus(0)}
                    disabled={isLoadingHuddleBoard || isStatusUpdating}
                  >
                    {isStatusUpdating
                      ? '...'
                      : huddleBoard?.data[2]?.overallStatus === 0
                      ? '●'
                      : ''}
                  </button>
                  <button
                    type="button"
                    className="status-kpi-view__option"
                    onClick={() => handleChangeStatus(1)}
                    disabled={isLoadingHuddleBoard || isStatusUpdating}
                  >
                    {isStatusUpdating
                      ? '...'
                      : huddleBoard?.data[2]?.overallStatus === 1
                      ? '●'
                      : ''}
                  </button>
                  <button
                    style={{ backgroundColor: 'var(--system-green-1)' }}
                    type="button"
                    className="status-kpi-view__option"
                    onClick={() => handleChangeStatus(2)}
                    disabled={isLoadingHuddleBoard || isStatusUpdating}
                  >
                    {isStatusUpdating
                      ? '...'
                      : huddleBoard?.data[2]?.overallStatus === 2
                      ? '●'
                      : ''}
                  </button>
                </div>
              </div>

              <div className="status-kpi-view__heading flex-right full-width align-self-start">
                {/* <h3 className="status-kpi-view__title">Overall Status</h3> */}
                <div className="status-kpi-view__date">
                  {huddleBoard?.data[2]?.updatedDate &&
                    moment(huddleBoard?.data[2]?.updatedDate).format('MMM DD, YY HH:mm')}
                </div>
              </div>
            </div>
          </div>

          {/* DEPARTMENTS */}
          <div className="flex flex-column flex-gap-24">
            { !isDepartmentView && <div className="Material-KitH6">Pacing</div>}
            <div className="kpi-view__departments flex flex-row flex-gap-24 departments-kpi-view">
              {!isDepartmentView &&
                departments?.filter((department) => !department.viewOnly)?.map((dept, index) => (
                  <div
                    className="kpi-view__department department-kpi-view"
                    style={{
                      // backgroundColor:
                      //   huddleBoard?.data[0]?.actions.find(
                      //     (action) => action.departmentID === dept.id,
                      //   )?.status === 'Bad'
                      //     ? '#FFDDD8'
                      //     : 'var(--general-general-30)',
                      backgroundColor: `${
                        huddleBoards.find(
                          (board) => board.title === 'KPI' && board.department === dept.id,
                        )
                          ? huddleBoards.find(
                              (board) =>
                                board.title === 'KPI' && board.department === dept.id,
                            ).data[2]?.overallStatus === 0
                            ? '#ffddd8'
                            : huddleBoards.find(
                                (board) =>
                                  board.title === 'KPI' && board.department === dept.id,
                              ).data[2]?.overallStatus === 2
                            ? 'var(--general-general-30)'
                            : huddleBoards.find(
                                (board) =>
                                  board.title === 'KPI' && board.department === dept.id,
                              ).data[2]?.overallStatus === 1
                            ? '#fbdbae'
                            : 'var(--general-general-30)'
                          : huddleBoard?.data[0]?.actions?.find(
                              (action) => action.departmentID === dept.id,
                            )?.status === 'Bad'
                          ? '#FFDDD8'
                          : 'var(--general-general-30)'
                      }`,
                    }}
                    key={dept.id}
                  >
                    <h3 className="department-kpi-view__title">{dept.name}</h3>
                    <div className="department-kpi-view__row flex justify-space-between flex-gap-24">
                      <div className="flex flex-column flex-gap-24 align-self-end">
                      { !huddleBoards.find(
                                (board) => board.title === 'KPI' && board.department === dept.id
                              ) && <>
                          <div className="flex flex-row flex-gap-16">
                            <div className="Material-KitLabel-1">Issue Status</div>
                            <label className="status-kpi-view__status-toggle">
                              <input
                                type="checkbox"
                                checked={
                                  huddleBoard?.data[0]?.actions?.find(
                                    (action) => action.departmentID === dept.id,
                                  )?.status === 'Bad'
                                }
                                onChange={() =>
                                  handleInputToggle(
                                    dept.id,
                                    'status',
                                    huddleBoard?.data[0]?.actions?.find(
                                      (action) => action.departmentID === dept.id,
                                    )?.status === 'Bad'
                                      ? 'Good'
                                      : 'Bad',
                                  )
                                }
                              />
                              <span className="status-toggle-slider"></span>
                            </label>
                          </div>
                        </>}
                        <div className="status-kpi-view__date">
                          {
                            huddleBoards.find(
                              (board) => board.title === 'KPI' && board.department === dept.id,
                            )
                              ? moment(huddleBoards.find(
                                  (board) =>
                                    board.title === 'KPI' && board.department === dept.id,
                                ).data[2]?.updatedDate).format('MMM DD, YY HH:mm')                                
                                : huddleBoard?.data[0]?.actions?.find(
                                  (action) => action.departmentID === dept.id,
                                )?.updatedDate ?
                                  moment(
                                    huddleBoard?.data[0]?.actions?.find(
                                      (action) => action.departmentID === dept.id,
                                    )?.updatedDate,
                                  ).format('MMM DD, YY HH:mm')
                                : ''
                          }
                          {/* {huddleBoard?.data[0]?.actions.find(
                            (action) => action.departmentID === dept.id,
                          )?.updatedDate &&
                            moment(
                              huddleBoard?.data[0]?.actions.find(
                                (action) => action.departmentID === dept.id,
                              )?.updatedDate,
                            ).format('MMM DD, YY HH:mm')} */}
                        </div>
                        
                      </div>
                      <div className="flex flex-row flex-gap-16">                        
                        <div
                          className="status-kpi-view__dept-status"
                          style={{
                            backgroundColor: `${
                              huddleBoards.find(
                                (board) => board.title === 'KPI' && board.department === dept.id,
                              )
                                ? huddleBoards.find(
                                    (board) =>
                                      board.title === 'KPI' && board.department === dept.id,
                                  ).data[2]?.overallStatus === 0
                                  ? 'var(--system-red-1)'
                                  : huddleBoards.find(
                                      (board) =>
                                        board.title === 'KPI' && board.department === dept.id,
                                    ).data[2]?.overallStatus === 2
                                  ? 'var(--general-general-30)'
                                  : huddleBoards.find(
                                      (board) =>
                                        board.title === 'KPI' && board.department === dept.id,
                                    ).data[2]?.overallStatus === 1
                                  ? '#f8ab40'
                                  : 'var(--general-general-30)'
                                : huddleBoard?.data[0]?.actions?.find(
                                    (action) => action.departmentID === dept.id,
                                  )?.status === 'Bad'
                                ? 'var(--system-red-1)'
                                : 'var(--general-general-30)'
                            }`,
                            // border: `${
                            //   huddleBoard?.data[0]?.actions.find(
                            //     (action) => action.departmentID === dept.id,
                            //   )?.status === 'Bad'
                            //     ? 'none'
                            //     : '1px solid var(--general-general-60)'
                            // }`,
                            border: `${
                              huddleBoards.find(
                                (board) => board.title === 'KPI' && board.department === dept.id,
                              )
                                ? huddleBoards.find(
                                    (board) =>
                                      board.title === 'KPI' && board.department === dept.id,
                                  ).data[2]?.overallStatus === 0
                                  ? 'none'
                                  : huddleBoards.find(
                                      (board) =>
                                        board.title === 'KPI' && board.department === dept.id,
                                    ).data[2]?.overallStatus === 2
                                  ? '1px solid var(--general-general-60)'
                                  : huddleBoards.find(
                                      (board) =>
                                        board.title === 'KPI' && board.department === dept.id,
                                    ).data[2]?.overallStatus === 1
                                  ? 'none'
                                  : '1px solid var(--general-general-60)'
                                : huddleBoard?.data[0]?.actions?.find(
                                    (action) => action.departmentID === dept.id,
                                  )?.status === 'Bad'
                                ? 'none'
                                : '1px solid var(--general-general-60)'
                            }`,
                          }}
                        ></div>
                      </div>
                    </div>

                    {/* <form onSubmit={formik.handleSubmit} className="department-kpi-view__form">
                    <div className="department-kpi-view__row flex justify-space-between">
                      <label
                        htmlFor={`actions[${index}].status`}
                        className="department-kpi-view__label Material-KitB2"
                      >
                        Status:{' '}
                      </label>
                      
                      <div className="department-kpi-view__status">
                        <button
                          type="submit"
                          className={`department-kpi-view__status-button ${
                            formik.values.actions[index]?.status === 'Good' ? 'clicked' : ''
                          }`}
                          onClick={() => {
                            handleInputToggle(index, 'status', 'Good');
                          }}
                        ></button>

                        <button
                          type="submit"
                          className={`department-kpi-view__status-button ${
                            formik.values.actions[index]?.status === 'Bad' ? 'clicked' : ''
                          }`}
                          onClick={() => {
                            handleInputToggle(index, 'status', 'Bad');
                          }}
                        ></button>
                      </div>
                    </div>

                    <div className="department-kpi-view__row ">
                      <div className="department-kpi-view__labels flex justify-space-between">
                        <label
                          htmlFor={`actions[${index}].actionsGoal`}
                          className="department-kpi-view__label Material-KitB2"
                        >
                          Actions:{' '}
                        </label>

                        <button
                          type="button"
                          className="department-kpi-view__edit-button kpi-edit-button"
                          onClick={() => {
                            handleInputToggle(index, 'actionsGoal');
                          }}
                        >
                          <img src="/icons/edit.svg" alt="Edit" />
                        </button>
                      </div>
                      <div className="department-kpi-view__fields">
                        <div className="department-kpi-view__action action-kpi-view">
                          {formik.values.actions[index]?.actionsGoal?.map((action, i) => (
                            <div
                              key={i}
                              className="action-kpi-view__input-row flex justify-space-between align-center"
                            >
                              <div className="action-kpi-view__icon kpi-edit-button">
                                <img src="/icons/check_small.svg" alt="check" />
                              </div>
                              <input
                                type="text"
                                id={`actions[${index}].actionsGoal[${i}]`}
                                name={`actions[${index}].actionsGoal[${i}]`}
                                className="department-kpi-view__input Material-KitB2"
                                value={formik.values.actions[index]?.actionsGoal[i] || ['']}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                disabled={activeRows[index]?.actionsGoal}
                              />
                              {!activeRows[index]?.actionsGoal && (
                                <>
                                  <div className="department-kpi-view__buttons flex align-center">
                                    <button
                                      type="submit"
                                      className="department-kpi-view__accept-button kpi-edit-button Material-KitButton"
                                      onClick={() => {
                                        handleInputToggle(index, 'actionsGoal');
                                      }}
                                    >
                                      <img src="/icons/check_small.svg" alt="Accept" />
                                    </button>
                                    <button
                                      type="button"
                                      className="department-kpi-view__cancel-button kpi-edit-button Material-KitButton"
                                      onClick={() => {
                                        handleInputToggle(index, 'actionsGoal', '', 'cancel');
                                      }}
                                    >
                                      <img src="/icons/close.svg" alt="Cancel" />
                                    </button>
                                  </div>
                                  <div className="department-kpi-view__delete-wrapper">
                                    <button
                                      type="button"
                                      className="department-kpi-view__delete-button department-kpi-view__cancel-button kpi-edit-button Material-KitButton"
                                      onClick={(e) => {
                                        handleDeleteConfirmation(index, i, e);
                                      }}
                                    >
                                      <img src="/icons/delete.svg" alt="Delete" />
                                    </button>
                                    {isDeleteConfirmed.state &&
                                      isDeleteConfirmed.index === index &&
                                      isDeleteConfirmed.action === i && (
                                        <div className="department-kpi-view__delete-button-text">
                                          <span>Delete this action?</span>
                                          <div className="department-kpi-view__delete-button-confirmation flex justify-space-between">
                                            <button
                                              type="button"
                                              className="department-kpi-view__confirmation-proceed-button"
                                              onClick={() => handleDeleteAction(index, i)}
                                            >
                                              Delete
                                            </button>
                                            <button
                                              type="button"
                                              className="department-kpi-view__confirmation-cancel-button"
                                              onClick={() =>
                                                setIsDeleteConfirmed({
                                                  state: false,
                                                  index: null,
                                                  action: null,
                                                })
                                              }
                                            >
                                              Cancel
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </>
                              )}
                            </div>
                          ))}
                        </div>

                        <button
                          type="button"
                          className="department-kpi-view__add-button kpi-edit-button"
                          onClick={() => handleActionAppend(index)}
                        >
                          <img src="/icons/add.svg" alt="Append action" />
                        </button>
                      </div>
                    </div>
                  </form> */}
                    {/* <div className="status-kpi-view__date">
                    {formik.values.actions[index]?.updatedDate && moment(formik.values.actions[index]?.updatedDate).format('MMMM D, YYYY h:mm A')}
                  </div> */}
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className="kpi-view__sqcpd sqcpd-kpi-view flex flex-column flex-gap-24">
          <div className="Material-KitH6">SQCDP Framework</div>
          <div className="sqcpd-kpi-view__letters letters-kpi-view">
            <div className="letters-kpi-view__letter">
              {renderLetter('S')}
              <div className="letters-kpi-view__spacer-divider-32"></div>
              {renderTable(tables?.[0], 0)}
              {renderGraph(0, 'S')}
              <div className="letters-kpi-view__spacer-divider-32"></div>
              {renderReasons('S')}
            </div>

            <div className="letters-kpi-view__letter">
              {renderLetter('Q')}
              <div className="letters-kpi-view__spacer-divider-32"></div>
              {renderTable(tables?.[1], 1)}
              {renderGraph(1, 'Q')}
              <div className="letters-kpi-view__spacer-divider-32"></div>
              {renderReasons('Q')}
            </div>

            <div className="letters-kpi-view__letter">
              {renderLetter('C')}
              <div className="letters-kpi-view__spacer-divider-32"></div>
              {renderTable(tables?.[2], 2)}
              {renderGraph(2, 'C')}
              <div className="letters-kpi-view__spacer-divider-32"></div>
              {renderReasons('C')}
            </div>

            <div className="letters-kpi-view__letter">
              {renderLetter('D')}
              <div className="letters-kpi-view__spacer-divider-32"></div>
              {renderTable(tables?.[3], 3)}
              {renderGraph(3, 'D')}
              <div className="letters-kpi-view__spacer-divider-32"></div>
              {renderReasons('D')}
            </div>

            <div className="letters-kpi-view__letter">
              {renderLetter('P')}
              <div className="letters-kpi-view__spacer-divider-32"></div>
              {renderTable(tables?.[4], 4)}
              {renderGraph(4, 'P')}
              <div className="letters-kpi-view__spacer-divider-32"></div>
              {renderReasons('P')}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} /*  onRequestClose={() => {}} */ className="reasons-modal">
        <div className="reasons-modal__content">
          <h2 className="Material-KitH6">Add Reason Code</h2>
          <div className="reasons-modal__form">
            <div className="reasons-modal__input">
              <label className="Material-KitSB2_TS">Select existing reason:</label>
              {/* <select onChange={(e) => setReason(e.target.value)}>
                <option value="">Select a reason...</option>
                {getReasonsByFrequency(reasonIndex).map(([reason], idx) => (
                  <option key={idx} value={reason}>
                    {reason}
                  </option>
                ))}
              </select> */}
              <div className="reasons-modal__input-select">
                {getReasonsByFrequency(reasonIndex).map(([rsn, count], idx) => (
                  <div key={idx} className={`reasons-modal__input-select-option ${reason === rsn ? 'selected' : ''}`} onClick={() => setReason(rsn)}>
                    {rsn} ({count})
                  </div>
                ))}
              </div>
            </div>
            <div className="reasons-modal__input">
              <label className="Material-KitSB2_TS">Or enter new reason:</label>
              <input
                type="text"
                placeholder="Type new reason code..."
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </div>
            <div className="reasons-modal__actions">
              <button
                className="task-form-buttons-update"
                onClick={handleAddReason}
                disabled={!reason.trim()}
              >
                SUBMIT
              </button>
              <button
                className="task-form-buttons-cancel"
                onClick={() => setIsModalOpen(false)}
              >
                CANCEL
              </button>
              
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  huddleBoard: state.huddleBoard,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, {
    fetchHuddleBoard,
    createHuddleBoard,
    fetchHuddleBoards,
    updateCustomHuddleBoard,
  }),
)(KPIView);
