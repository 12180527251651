import {
  GET_PROFILE_LOADING,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_ACCOUNT_USERS_LOADING,
  GET_ACCOUNT_USERS_SUCCESS,
  GET_ACCOUNT_USERS_FAIL,
  GET_PRIVATE_DEPARTMENT_USERS_LOADING,
  GET_PRIVATE_DEPARTMENT_USERS_SUCCESS,
  GET_PRIVATE_DEPARTMENT_USERS_FAIL,
  EDIT_USER_LOADING,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  DELETE_USER_LOADING,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CREATE_USER_LOADING,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
} from '../types';

const initialState = {
  profile: {},
  users: [],
  isLoading: false,
  error: null,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case GET_PROFILE_LOADING:
    case GET_ACCOUNT_USERS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_PRIVATE_DEPARTMENT_USERS_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case EDIT_USER_LOADING:
    case DELETE_USER_LOADING:
    case CREATE_USER_LOADING:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_PROFILE_SUCCESS:    
      return {
        ...state,
        isLoading: false,
        profile: payload.profile,
      };
    case GET_ACCOUNT_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: payload.users,
      };
    case GET_PRIVATE_DEPARTMENT_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: payload.users,
      };
    case CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profile: payload.user,
      };
    case EDIT_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profile: payload.user,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profile: {},
      };
    case GET_PROFILE_FAIL:
    case GET_ACCOUNT_USERS_FAIL:
    case GET_PRIVATE_DEPARTMENT_USERS_FAIL:
    case EDIT_USER_FAIL:
    case DELETE_USER_FAIL:
    case CREATE_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        profile: {},
        error: payload.error,
      };
    default:
      return state;
  }
}
