import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { formatedStatus } from '../../../../constants/functions';
import { useSelector } from 'react-redux';
import { faPen, faTrash, faCheck, faBoxArchive } from '@fortawesome/free-solid-svg-icons';
const Transmissions = ({
  rows,
  title,
  deleteRow,
  editRow,
  onMarkCompleteRow,
  onArchiveRow,
  departments,
  departmentView,
}) => {
  // const departments = useSelector((state) => state.project.project.departments);

  const tags = useSelector((state) => state.tag.tags);

  const projects = useSelector((state) => state.project.project);
  const [activeTagDropdown, setActiveTagDropdown] = useState(null);

  const [expandedTags, setExpandedTags] = useState({});
  const [activeDepartmentDropdown, setActiveDepartmentDropdown] = useState(null);
  const [expandedDepartments, setExpandedDepartments] = useState({});
  const [filter, setFilter] = useState('new');
  const isProjectWithTags = tags?.some((tag) => tag.project.id === projects.id);
  const [orderedActions, setOrderedActions] = useState(rows
    .map((row, originalIndex) => ({ ...row, originalIndex }))
    .slice()
    .sort((a, b) => {
      return moment(b.date).diff(moment(a.date));
    })
    .filter((row) => row.status?.includes(filter)));

  const handleMoreDepartmentsClick = (index, e) => {
    e.stopPropagation();
    setActiveDepartmentDropdown(activeDepartmentDropdown === index ? null : index);
  };

  useEffect(() => {
    setOrderedActions(rows
      .map((row, originalIndex) => ({ ...row, originalIndex }))
      .slice()
      .sort((a, b) => {
        return moment(b.date).diff(moment(a.date));
      })
      .filter((row) => row.status?.includes(filter)))
  }, [filter, rows]);

  const handleDepartmentClick = (idx, deptId) => {
    // if (departments.length <= 3) {
    setExpandedDepartments((prev) => ({
      ...prev,
      [idx]: {
        ...prev[idx],
        [deptId]: !prev[idx]?.[deptId],
      },
    }));
    // }
  }; 
  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = () => setActiveDepartmentDropdown(null);

    setActiveTagDropdown(null);
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  return (
    <div className="huddle-board-content__transmissions transmissions">
      <h2 className="huddle-board-content__title title text-center">Pass Up/Pass Down</h2>
      <div className="transmissions-filter">
        <div className={`transmissions-filter__item ${filter === 'new' ? 'active' : ''}`} onClick={() => setFilter('new')}>New ({rows.filter((row) => row.status?.includes('new')).length})</div>
        <div className={`transmissions-filter__item ${filter === 'read' ? 'active' : ''}`} onClick={() => setFilter('read')}>Read ({rows.filter((row) => row.status?.includes('read')).length})</div>
        <div className={`transmissions-filter__item ${filter === 'draft' ? 'active' : ''}`} onClick={() => setFilter('draft')}>Drafts ({rows.filter((row) => row.status?.includes('draft')).length})</div>
        <div className={`transmissions-filter__item ${filter === 'sent' ? 'active' : ''}`} onClick={() => setFilter('sent')}>Sent ({rows.filter((row) => row.status?.includes('sent')).length})</div>        
      </div>

      <div className="table-wrapper">
        <table className="form-transmissions__table table">
          <thead>
            <tr>
              <th className="trsnmsn_index">#</th>
              <th className="trsnmsn_text">Issue Description</th>
              <th className="trsnmsn_person">Person</th>
              <th className="trsnmsn_due">Due</th>              
              <th className="trsnmsn_department">{departmentView ? ((filter === 'new' || filter === 'read') ? 'From Project' : 'To Project') : ((filter === 'new' || filter === 'read') ? 'From Department' : 'To Department')}</th>              
              {/* <th className="trsnmsn_status">Status</th> */}
              <th className="trsnmsn_actions">Actions</th>
            </tr>
          </thead>
          <tbody className="expanded">
            {orderedActions.map((row, idx) => {
              // Get only the departments associated with this non-tip action
              let rowDepartments = [];
              if (filter === 'new' || filter === 'read') {
                rowDepartments = departments.filter((dept) =>
                  row.originDepartment?.includes(dept.id) || row.originProject?.includes(dept.id),
                );
              } else {
                rowDepartments = departments.filter((dept) =>
                  row.department?.includes(dept.id),
                );
              }

              // const visibleDepts = rowDepartments.slice(0, 3);
              // const remainingDepts = rowDepartments.slice(3);
              const visibleDepts = rowDepartments;
              const remainingDepts = [];

              const rowTags = tags.filter((tag) => row.tags?.includes(tag.id));

              // const visibleTags = rowTags.slice(0, 3);
              // const remainingTags = rowTags.slice(3);
              const visibleTags = rowTags;
              const remainingTags = [];

              return (
                <tr key={idx} className={isProjectWithTags ? 'tags-exist' : ''}>
                  <td className="trsnmsn_index">{idx + 1}</td>
                  <td className="trsnmsn_text">{row?.text}</td>
                  <td className="trsnmsn_person">{row?.person}</td>
                  <td className="trsnmsn_due">{row?.date}</td>
                  <td className="trsnmsn_department">
                    {/* <span className="label">
                      {departments.find((el) => el.id === row?.department)?.name}
                    </span> */}
                    {visibleDepts.map((el) => (
                      <span
                        className={`label Material-KitB2 ${
                          expandedDepartments[idx]?.[el.id] ? 'expanded' : ''
                        }`}
                        key={el.id}
                        style={{ backgroundColor: el.color || '#D9E4FF' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDepartmentClick(idx, el.id);
                        }}
                      >
                        {expandedDepartments[idx]?.[el.id]
                          ? departmentView
                            ? el.title
                            : el.name
                          : departmentView
                          ? el.title.charAt(0)
                          : el.name.charAt(0)}
                      </span>
                    ))}
                    {remainingDepts.length > 0 && (
                      <>
                        <span
                          className="more-departments"
                          onClick={(e) => handleMoreDepartmentsClick(idx, e)}
                        >
                          +{remainingDepts.length}
                        </span>
                        <div
                          className={`departments-dropdown ${
                            activeDepartmentDropdown === idx ? 'show' : ''
                          }`}
                        >
                          {remainingDepts.map((el) => (
                            <span
                              className="label Material-KitB2"
                              key={el.id}
                              style={{ backgroundColor: el.color || '#D9E4FF' }}
                            >
                              {departmentView ? el.title : el.name}
                            </span>
                          ))}
                        </div>
                      </>
                    )}
                  </td>
                  {/* <td className="trsnmsn_status">
                    {row.status && row.status?.length > 0 ? (
                      row.status?.map((el, index) => (
                        <span key={index} className={`status ${el}`}>
                          {String(formatedStatus(el))}
                        </span>
                      ))
                    ) : (
                      <span className="status no-status">No Status</span>
                    )}
                  </td> */}
                  <td className="trsnmsn_actions">
                    <span className="actions">
                      {row.status.includes('new') &&
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="check-btn"
                          onClick={() => onMarkCompleteRow(row.originalIndex)}
                        />
                      }
                      {row.status.includes('draft') && <FontAwesomeIcon
                        icon={faPen}
                        className="edit-btn"
                        onClick={() => editRow(row.originalIndex)}
                      />}
                      {(row.status.includes('read') || row.status.includes('sent')) && <FontAwesomeIcon
                        icon={faBoxArchive}
                        className="archive-btn"
                        onClick={() => onArchiveRow(row.originalIndex)}
                      />}
                      {row.status.includes('draft') &&
                        <FontAwesomeIcon
                          icon={faTrash}
                          className=" delete-btn"
                          onClick={() => {
                          deleteRow(row.originalIndex);
                        }}
                      />}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Transmissions;
