import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import Layout from '../../layout/Layout';
// import MessageList from '../../components/MessageList/MessageList';
import ActiveProjectList from '../../components/DashboardComponents/ActiveProjectList/ActiveProjectList';
import ProjectForm from '../../components/DashboardComponents/ProjectForm/ProjectForm';
import ProjectEditForm from '../../components/DashboardComponents/ProjectForm/ProjectFormEdit';
import DepartmentEdit from '../../components/DashboardComponents/DepartmentEdit/DepartmentEdit';
import ProjectDepartmentsEdit from '../../components/DashboardComponents/ProjectDepartmentsEdit/ProjectDepartmentsEdit';
import TagEdit from '../../components/DashboardComponents/TagEdit/TagEdit';
import ProjectTagsEdit from '../../components/DashboardComponents/ProjectTagsEdit/ProjectTagsEdit';
import ProjectPodMastersEdit from '../../components/DashboardComponents/ProjectPodMastersEdit/ProjectPodMastersEdit';
import ProjectDepartmentsUsersEdit from '../../components/DashboardComponents/ProjectDepartmentsUsersEdit/ProjectDepartmentsUsersEdit';
import requireAuth from '../../hoc/requireAuth';

import './styles.css';

const Dashboard = ({ 
  auth,
  match 
}) => {
  const [openHistory, setOpenHistory] = useState([]);
  const [currentOpenForm, setCurrentOpenForm] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [project, setProject] = useState(null);
  const [department, setDepartment] = useState(null);
  const [tag, setTag] = useState(null);
  const [podmaster, setPodmaster] = useState(null);
  const [member, setMember] = useState(null);
  let { projectId } = useParams();
  let { pm_projectId } = useParams();

  const accountId = (match && match.params.accountId) || auth.me?.account;
  // const [searchParams, setSearchParams] = useParams();
  useEffect(() => {

    setCurrentOpenForm(null);      
    if (projectId) {
      setProject(projectId);
      setCurrentOpenForm('edit');
    } 

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
      // setIsMobile(window.innerWidth <= 1920);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  const handleOpenForm = (type = null, project = null) => {
    if (currentOpenForm && !openHistory.includes(currentOpenForm)) {
      setOpenHistory((prev) => [...prev, currentOpenForm]);
    }
    setCurrentOpenForm(type);
    project && setProject(project);
  };

  const handleUnsetForm = (project = null) => {
    // Save current form to history before closing
    // if (currentOpenForm) {
    //   setOpenHistory((prev) => [...prev, currentOpenForm]);
    // } else {
    //   setOpenHistory([]);
    // }
    setOpenHistory([]);
    setProject(null);
    setCurrentOpenForm(null);
  };
  const handleReturnBack = () => {
    if (openHistory.length > 0) {
      const lastForm = openHistory[openHistory.length - 1];
      setOpenHistory((prev) => prev.slice(0, -1));
      setCurrentOpenForm(lastForm);
    } else {
      setProject(null);
      setCurrentOpenForm(null);
    }
  };
  const renderForm = () => {
    switch (currentOpenForm) {
      case 'edit':
        return (
          <ProjectEditForm
            projectID={project}
            onUnsetForm={handleUnsetForm}
            currentOpenForm={currentOpenForm}
            onOpenForm={handleOpenForm}
            onReturnBack={handleReturnBack}
            setDepartment={setDepartment}
            setTag={setTag}
            setPodmaster={setPodmaster}
          />
        );
      case 'add':
        return <ProjectForm 
          auth={auth}
          onUnsetForm={handleUnsetForm} 
          onReturnBack={handleReturnBack} 
          accountId={accountId}
        />;
      case 'department/new':
        return (
          <ProjectDepartmentsEdit
            projectID={project}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            setDepartment={setDepartment}
            onOpenForm={handleOpenForm}
          />
        );
      case 'department/edit':
        return (
          <DepartmentEdit
            projectID={project}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            departmentID={department}
            onOpenForm={handleOpenForm}
            currentOpenForm={currentOpenForm}
            setMember={setMember}
          />
        );
      case 'tag/edit':
        return (
          <TagEdit
            projectID={project}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            tagID={tag}
          />
        );
      case 'tag/new':
        return (
          <ProjectTagsEdit
            projectID={project}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            setTag={setTag}
            onOpenForm={handleOpenForm}
          />
        );
      case 'member/new':
        return (
          <ProjectDepartmentsUsersEdit
            departmentID={department}
            accountID={accountId}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            memberID={member}
          />
        );
      case 'podmaster/new':
        return (
          <ProjectPodMastersEdit
            projectID={project}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            setPodmaster={setPodmaster}
            onOpenForm={handleOpenForm}
          />
        );
      default:
        return !isMobile ? (
          <div className="project-list__placeholder placeholder-project-list">
            <h4 className="Material-KitH4">Choose an action to start</h4>
            {(auth.me?.role === 'SUPERADMIN' || auth.me?.role === 'PODMASTER') ?
             <h5 className="Material-KitH5">You can view or edit an existing pod, or create a new one</h5> :
             <h5 className="Material-KitH5">You can view or edit an existing pod</h5>
            }
            <div className="placeholder-project-list__image">
              <img src="/images/dashboard/Decoration.svg" alt="Press + to start" />
            </div>
          </div>
        ) : null;
    }
  };
  if (!auth.isAuthenticated) return null;

  return (
    <Layout>
      <div className="adaptive-container">
        <div className="dashboard-page">
          <div className={`project-row ${currentOpenForm ? 'opened' : ''}`}>
            {(!isMobile || !currentOpenForm) && (
              <ActiveProjectList
                onUnsetForm={handleUnsetForm}
                isMobile={isMobile}
                currentOpenForm={currentOpenForm}
                onOpenForm={handleOpenForm}
                selectedProject={project}
                accountId={accountId}
              />
            )}
            {/* {(auth.me?.role === 'SUPERADMIN' || auth.me?.role === 'PODMASTER') && renderForm()} */}
            {renderForm()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(requireAuth, connect(mapStateToProps))(Dashboard);
