import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { updateHuddleBoard } from '../../../../store/actions/huddleBoardAction';
import styles from './DragAndDrop.module.css';
import './PatientCentricity.css';

const PatientCentricity = ({ title, huddleBoard, updateHuddleBoard, onUpdate }) => {
  const [dragOver, setDragOver] = useState(false);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [loadingStates, setLoadingStates] = useState([]);
  const abortControllerRef = useRef(new AbortController());
  const [uploadedImage, setUploadedImage] = useState(huddleBoard?.data[0]?.image || '');

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    setDragOver(false);

    let files = [];
    if (e.currentTarget?.files) {
      files = Array.from(e.currentTarget.files);
    } else if (e.dataTransfer?.files) {
      files = Array.from(e.dataTransfer.files);
    }
    setDroppedFiles([]);

    if (files && files.length > 1) {
      alert('Upload only one image file.');
    } else if (files && files.length === 1) {
      setLoadingStates(new Array(files.length).fill(true));

      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();

      const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`;
      for (let file of files) {
        try {
          const formData = new FormData();
          const fields = {
            file,
            tags: ['huddle-board', `huddleid-${huddleBoard?.id}`],
            multiple: false,
            resource_type: 'image',
          };

          Object.entries(fields).forEach(([key, value]) => {
            formData.append(key, value);
          });
          formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET);

          const options = {
            method: 'POST',
            body: formData,
            signal: abortControllerRef.current.signal,
          };
          const response = await fetch(url, options);
          if (!response.ok) {
            throw new Error('Failed to execute file upload via the Fetch API');
          }
          const json = await response.json();
          const secureUrl = json.secure_url;
          const previewUrl = secureUrl.replace('/upload/', '/upload/c_fill,h_1200,w_2100/f_auto/');

          setDroppedFiles((prevFiles) => [...prevFiles, { file, previewUrl }]);
          formik.setFieldValue('currentAvatar', previewUrl);

          const updatedData = [...huddleBoard.data];
          updatedData[0] = { ...updatedData[0], image: previewUrl };
          updateHuddleBoard(huddleBoard.id, updatedData, huddleBoard, 'Custom');
          if (onUpdate) onUpdate();

          setLoadingStates((prevStates) =>
            prevStates.map((state, index) => (file === files[index] ? false : state)),
          );
        } catch (error) {
          if (error.name !== 'AbortError') {
            console.error(error);
          }
          setLoadingStates((prevStates) =>
            prevStates.map((state, index) => (file === files[index] ? false : state)),
          );
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      abortControllerRef.current.abort();
    };
  }, []);  

  const formik = useFormik({
    initialValues: {
      currentAvatar: uploadedImage,
    },
    validationSchema: Yup.object({
      currentAvatar: Yup.string().required('Image is required'),
    }),
    onSubmit: (values) => {
      // console.log(values);
    },
  });

  useEffect(() => {    
    setUploadedImage(huddleBoard?.data[0]?.image || '');
    formik.setFieldValue('currentAvatar', huddleBoard?.data[0]?.image || '');
  }, [huddleBoard]);

  const handleDelete = () => {
    formik.setFieldValue('currentAvatar', '');
    // Update huddle board data
    const updatedData = [...huddleBoard.data];
    updatedData[0] = { ...updatedData[0], image: '' };
    updateHuddleBoard(huddleBoard.id, updatedData, huddleBoard, 'Custom');
    if (onUpdate) onUpdate();
  };

  const handleEdit = () => {
    // Create a temporary input element
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/png,image/jpeg,image/jpg';
    input.onchange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        // If a new file is selected, handle the upload
        handleDrop(e);
      }
      // If no file is selected, do nothing (keep current image)
    };
    input.click();
  };

  return (
    <div className="huddle-board-content__patient-centricity patient-centricity">
      <h2 className="huddle-board-content__title title">{title}</h2>
      <div className="patient-centricity__content">
        {/* <div className="patient-centricity__heading">
          <h3 className="patient-centricity__heading-title Material-KitH4">Client</h3>
          <h3 className="patient-centricity__heading-title Material-KitH4">Project</h3>
          <h3 className="patient-centricity__heading-title Material-KitH4">Patient</h3>
        </div> */}
        <div className="patient-centricity__body">
          <form className="avatar-section" onSubmit={formik.handleSubmit}>
            {formik.values.currentAvatar ? (
              <div className="patient-centricity__attached-image-container">
                <div className="patient-centricity__attached-image">
                  <img
                    src={
                      formik.values.currentAvatar.startsWith('http')
                        ? formik.values.currentAvatar
                        : `${process.env.REACT_APP_SERVER_BASE_URL}${formik.values.currentAvatar}`
                    }
                    alt="Current avatar"
                    className="patient-centricity__image"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '16px',
                    }}
                  />
                </div>
                <div className="patient-centricity__controls">
                  <button
                    className="button-edit patient-centricity__button"
                    type="button"
                    onClick={handleEdit}
                  >
                    <img src="/icons/edit.svg" alt="Edit" />
                  </button>
                  <button
                    className="button-delete patient-centricity__button"
                    type="button"
                    onClick={handleDelete}
                  >
                    <img src="/icons/delete.svg" alt="Delete" />
                  </button>
                </div>
              </div>
            ) : (
              <>
                <input
                  type="file"
                  accept="image/png,image/jpeg,image/jpg"
                  id="avatar"
                  name="avatar"
                  className="invisible Material-KitSB2"
                  placeholder="Avatar"
                  onChange={(event) => {
                    // formik.setFieldValue('avatar', event.currentTarget.files[0]);
                    handleDrop(event);
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.avatar && formik.errors.avatar && (
                  <p className="error">{formik.errors.avatar}</p>
                )}

                <div
                  className={`${styles.dragArea} ${dragOver ? `${styles.dragOver}` : ''}`}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                >
                  {loadingStates.some((loading) => loading) ? (
                    // <span className="drag-and-drop-image loading loading-spinner text-primary"></span>
                    <img
                      src="/icons/asterisk.svg"
                      className="drag-and-drop-image loading loading-spinner text-primary"
                      alt="Uploading Image"
                    />
                  ) : (
                    <img
                      src="/icons/upgrade.svg"
                      className="drag-and-drop-image"
                      alt="Upload Image"
                    />
                  )}
                  <div className="drag-and-drop-text-container">
                    <div className="drag-and-drop-text">Drop an image here</div>
                    <div className="or-text">or</div>
                    <label htmlFor="avatar" className="select-file-link">
                      select file
                    </label>
                  </div>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { updateHuddleBoard })(PatientCentricity);
