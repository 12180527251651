import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_TASKS_LOADING,
  GET_TASKS_SUCCESS,
  GET_TASKS_FAIL,
  GET_TASK_LOADING,
  GET_TASK_SUCCESS,
  GET_TASK_FAIL,
  GET_PROJECT_TASKS_LOADING,
  GET_PROJECT_TASKS_SUCCESS,
  GET_PROJECT_TASKS_FAIL,
  GET_PROJECT_COMPLETED_TASKS_LOADING,
  GET_PROJECT_COMPLETED_TASKS_SUCCESS,
  GET_PROJECT_COMPLETED_TASKS_FAIL,
  GET_USER_TASKS_LOADING,
  GET_USER_TASKS_SUCCESS,
  GET_USER_TASKS_FAIL,
  GET_ASSIGNEDTO_TASKS_LOADING,
  GET_ASSIGNEDTO_TASKS_SUCCESS,
  GET_ASSIGNEDTO_TASKS_FAIL,
  GET_DEPARTMENT_TASKS_LOADING,
  GET_DEPARTMENT_TASKS_SUCCESS,
  GET_DEPARTMENT_TASKS_FAIL,
  ADD_TASK_LOADING,
  ADD_TASK_SUCCESS,
  ADD_TASK_FAIL,
  DELETE_TASK_LOADING,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAIL,
  EDIT_TASK_LOADING,
  EDIT_TASK_SUCCESS,
  EDIT_TASK_FAIL,
  CLEAR_TASK_ERROR,
} from '../types';

export const getTasks = () => async (dispatch, getState) => {
  dispatch({
    type: GET_TASKS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks`, options);

    dispatch({
      type: GET_TASKS_SUCCESS,
      payload: { tasks: response.data.tasks },
    });
  } catch (err) {
    dispatch({
      type: GET_TASKS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getProjectTasks = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECT_TASKS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks/project/${id}`, options);

    dispatch({
      type: GET_PROJECT_TASKS_SUCCESS,
      payload: { tasks: response.data.tasks },
    });
  } catch (err) {
    dispatch({
      type: GET_PROJECT_TASKS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getProjectCompletedTasks = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROJECT_COMPLETED_TASKS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks/project/${id}/completed`, options);

    dispatch({
      type: GET_PROJECT_COMPLETED_TASKS_SUCCESS,
      payload: { tasks: response.data.tasks },
    });
  } catch (err) {
    dispatch({
      type: GET_PROJECT_COMPLETED_TASKS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getDepartmentTasks = (id, complete) => async (dispatch, getState) => {
  dispatch({
    type: GET_DEPARTMENT_TASKS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks/department/${id}${complete ? '/completed' : ''}`, options);

    dispatch({
      type: GET_DEPARTMENT_TASKS_SUCCESS,
      payload: { tasks: response.data.tasks },
    });
  } catch (err) {
    dispatch({
      type: GET_DEPARTMENT_TASKS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getUserTasks = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_USER_TASKS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks/user/${id}`, options);

    dispatch({
      type: GET_USER_TASKS_SUCCESS,
      payload: { tasks: response.data.tasks },
    });
  } catch (err) {
    dispatch({
      type: GET_USER_TASKS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getAssignedToTasks = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_ASSIGNEDTO_TASKS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks/assignedto/${id}`, options);

    dispatch({
      type: GET_ASSIGNEDTO_TASKS_SUCCESS,
      payload: { tasks: response.data.tasks },
    });
  } catch (err) {
    dispatch({
      type: GET_ASSIGNEDTO_TASKS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getTask = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_TASK_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks/${id}`, options);

    dispatch({
      type: GET_TASK_SUCCESS,
      payload: { task: response.data.task },
    });
  } catch (err) {
    dispatch({
      type: GET_TASK_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const addTask = (formData) => async (dispatch, getState) => {
  dispatch({
    type: ADD_TASK_LOADING,
    payload: { me: { ...getState().auth.me } },
  });  
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks`, formData, options);
    // const responseTasks = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks/project/${response.data.task.project.id}`, options);

    dispatch({
      type: ADD_TASK_SUCCESS,
      // payload: { task: response.data.task, tasks: responseTasks.data.tasks },
      payload: { task: response.data.task },
    });
  } catch (err) {
    dispatch({
      type: ADD_TASK_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteTask = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_TASK_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks/${id}`, options);

    dispatch({
      type: DELETE_TASK_SUCCESS,
      payload: { task: response.data.task },
    });
  } catch (err) {
    dispatch({
      type: DELETE_TASK_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const editTask = (id, formData, completeTasksView) => async (dispatch, getState) => {  
  dispatch({
    type: EDIT_TASK_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const responseTask = await axios.put(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks/${id}`, formData, options);
    // const responseTasks = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/tasks/project/${responseTask.data.task.project.id}${completeTasksView ? '/completed' : ''}`, options);

    dispatch({
      type: EDIT_TASK_SUCCESS,
      // payload: { task: responseTask.data.task, tasks: responseTasks.data.tasks },      
      payload: { task: responseTask.data.task},  
    });
  } catch (err) {
    dispatch({
      type: EDIT_TASK_FAIL,
      payload: { error: err?.response?.data.message || err.message, id },
    });
  }
};

export const clearTaskError = (id) => ({
  type: CLEAR_TASK_ERROR,
  payload: { id },
});
