import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { formatDate } from '../../../constants/functions';
import { updateHuddleBoard } from '../../../store/actions/huddleBoardAction';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import moment from 'moment';
import * as Yup from 'yup';
import './Note.css';

const Note = ({ huddleBoard, currentDate, updateHuddleBoard }) => {
  const [isEdit, setIsEdit] = useState(false);

  const [formattedDate, setFormattedDate] = useState(formatDate(currentDate.toISOString()));
  const [currentWeekData, setCurrentWeekData] = useState(
    huddleBoard.data[0]?.weeks?.find((week) => week?.week === formattedDate) || { note: '' },
  );

  const formik = useFormik({
    initialValues: {
      note: currentWeekData?.note || '',
    },
    validationSchema: Yup.object({
      note: Yup.string().optional().max(300),
    }),
    onSubmit: (values, { resetForm }) => {
      let updatedData;

      if (!huddleBoard.data || huddleBoard.data.length === 0) {
        updatedData = [
          {
            weeks: [
              {
                week: formattedDate,
                questions: [],
                note: values.note,
              },
            ],
          },
          {
            master: {
              items: [],
              subItems: [],
            },
          },
        ];
      } else {
        const weekIndex = huddleBoard.data[0]?.weeks?.findIndex(
          (week) => week.week === formattedDate,
        );
        const currentWeeks = [...(huddleBoard.data[0]?.weeks || [])];

        if (weekIndex === -1) {
          // Add new week
          currentWeeks.push({
            week: formattedDate,
            questions: [],
            note: values.note,
          });
        } else {
          // Update existing week
          currentWeeks[weekIndex] = {
            ...currentWeeks[weekIndex],
            note: values.note,
          };
        }

        updatedData = [
          {
            weeks: currentWeeks,
          },
          {
            master: huddleBoard.data[1]?.master || { items: [], subItems: [] },
          },
        ];
      }

      try {
        updateHuddleBoard(huddleBoard.id, updatedData, huddleBoard, 'Custom', currentDate);

        // Update local state to reflect changes immediately
        const updatedWeekData = { ...currentWeekData, note: values.note };
        setCurrentWeekData(updatedWeekData);

        setIsEdit(false);
      } catch (error) {
        console.error('Error updating note:', error);
      }
    },
  });

  useEffect(() => {
    // Reset edit mode and form values when date changes
    setFormattedDate(formatDate(currentDate.toISOString()));
  }, [currentDate]);

  useEffect(() => {
    // Update currentWeekData when formattedDate or huddleBoard changes
    const weekData = huddleBoard.data[0]?.weeks?.find((week) => week?.week === formattedDate) || {
      note: '',
    };
    setCurrentWeekData(weekData);

    // Only update formik values if they're different to avoid unnecessary re-renders
    if (formik.values.note !== (weekData?.note || '')) {
      formik.setValues({ note: weekData?.note || '' });
    }
  }, [formattedDate, huddleBoard, huddleBoard.data]);

  return (
    <div className="note">
      <form onSubmit={formik.handleSubmit}>
        <b className="Material-KitSB2">Leave a note:</b>
        <div className="note__row">
          <textarea
            id="note"
            name="note"
            className="Material-KitOverline note__textarea"
            placeholder="Note..."
            onChange={formik.handleChange}
            value={formik.values.note}
            disabled={!isEdit}
          ></textarea>
          <div className="note__buttons">
            {isEdit ? (
              <>
                <button type="submit" className="note__button button-submit">
                  <img src="/icons/check_small.svg" alt="accept" />
                </button>
                <button
                  type="button"
                  className="note__button button-cancel"
                  onClick={() => {
                    formik.resetForm();
                    setIsEdit(false);
                  }}
                >
                  <img src="/icons/close.svg" alt="cancel" />
                </button>
              </>
            ) : (
              <button
                type="button"
                className="note__button button-edit"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEdit(true);
                }}
              >
                <img src="/icons/edit.svg" alt="edit" />
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default connect(null, { updateHuddleBoard })(Note);
