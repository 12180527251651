import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_ACCOUNTS_LOADING,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAIL,
  GET_ACCOUNT_LOADING,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAIL,
  ADD_ACCOUNT_LOADING,
  ADD_ACCOUNT_SUCCESS,
  ADD_ACCOUNT_FAIL,
  DELETE_ACCOUNT_LOADING,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAIL,
  // ARCHIVE_ACCOUNT_LOADING,
  ARCHIVE_ACCOUNT_SUCCESS,
  // ARCHIVE_ACCOUNT_FAIL,
  UNARCHIVE_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT_LOADING,
  EDIT_ACCOUNT_SUCCESS,
  EDIT_ACCOUNT_FAIL,
  CLEAR_ACCOUNT_ERROR,
  GET_ACCOUNT_ADMINS_LOADING,
  GET_ACCOUNT_ADMINS_SUCCESS,
  GET_ACCOUNT_ADMINS_FAIL,
} from '../types';

export const getAccounts = (archived) => async (dispatch, getState) => {
  dispatch({
    type: GET_ACCOUNTS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const currentUser = getState().auth.me;
    var response = {};
    if (currentUser.role === 'SUPERADMIN') {
      response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/accounts`, options);
    } else {
      response = await axios.get(
        `${process.env.REACT_APP_SERVER_BASE_URL}/api/accounts/user/${currentUser.id}`,
        options,
      );
    }
    if (!archived) {
      dispatch({
        type: GET_ACCOUNTS_SUCCESS,
        payload: { accounts: response.data.accounts, error: null },
      });
    } else {
      dispatch({
        type: GET_ACCOUNTS_SUCCESS,
        payload: { accounts: response.data.accounts, error: null },
      });
    }
  } catch (err) {
    dispatch({
      type: GET_ACCOUNTS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getAccount = (accountId, isEdit, history) => async (dispatch, getState) => {
  // TODO: access validation needs to be updated once account tiers are implemented
  dispatch({
    type: GET_ACCOUNT_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const currentUser = getState().auth.me;
    var response = {};

    response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/accounts/${accountId}`,
      options,
    );

    const userHasAccess = currentUser.account?.toString() === response.data.account.id.toString();

    if (
      currentUser.role === 'SUPERADMIN' ||
      currentUser.id === response.data.account.user.id ||
      userHasAccess
    ) {
      dispatch({
        type: GET_ACCOUNT_SUCCESS,
        payload: { account: response.data.account, error: null },
      });
    } else {
      // need to add logic to check whether the user has access to view the account
      dispatch({
        type: GET_ACCOUNT_FAIL,
        payload: { error: "You don't have access to this account." },
      });
    }
  } catch (err) {
    if (err?.response?.status === 404) {
      history.push('/notfound');
    }
    dispatch({
      type: GET_ACCOUNT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const addAccount = (formData) => async (dispatch, getState) => {
  dispatch({
    type: ADD_ACCOUNT_LOADING,
    payload: { me: { ...getState().auth.me } },
  });
  try {
    const options = attachTokenToHeaders(getState);
    // console.log("FormData:", formData)
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/accounts`,
      formData,
      options,
    );

    dispatch({
      type: ADD_ACCOUNT_SUCCESS,
      payload: { account: response.data.account, error: null },
    });
  } catch (err) {
    dispatch({
      type: ADD_ACCOUNT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const archiveAccount = (id) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_ACCOUNT_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/accounts/${id}`,
      options,
    );

    dispatch({
      type: DELETE_ACCOUNT_SUCCESS,
      payload: { account: response.data.account, error: null },
    });
  } catch (err) {
    dispatch({
      type: DELETE_ACCOUNT_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const editAccount = (id, formData) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_ACCOUNT_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    // console.log('FormData:', formData);
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/accounts/${id}`,
      formData,
      options,
    );

    if (formData.archived === true) {
      dispatch({
        type: ARCHIVE_ACCOUNT_SUCCESS,
        payload: { account: response.data.account, error: null },
      });
    } else if (formData.archived === false) {
      dispatch({
        type: UNARCHIVE_ACCOUNT_SUCCESS,
        payload: { account: response.data.account, error: null },
      });
    } else {
      dispatch({
        type: EDIT_ACCOUNT_SUCCESS,
        payload: { account: response.data.account, error: null },
      });
    }
  } catch (err) {
    dispatch({
      type: EDIT_ACCOUNT_FAIL,
      payload: { error: err?.response?.data.message || err.message, id },
    });
  }
};

export const addAdminForAccount = (accountId, adminId) => async (dispatch, getState) => {
  dispatch({
    type: GET_ACCOUNT_ADMINS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    // console.log("Searching email:", formData.email);
    const responseUser = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/users/${adminId}`,
      options,
    );
    const responseAccount = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/accounts/addadmin/${accountId}`,
      { id: responseUser.data.user.id },
      options,
    );
    // const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/api/departments/account/${responseProject.data.department.account.id}`, options);
    dispatch({
      type: GET_ACCOUNT_ADMINS_SUCCESS,
      // payload: { departmentProfile: response.data.user },
      payload: { account: responseAccount.data.account },
    });
    // dispatch({
    //   type: GET_DEPARTMENTS_SUCCESS,
    //   // payload: { departmentProfile: response.data.user },
    //   payload: { departments: response.data.departments },
    // });
  } catch (err) {
    dispatch({
      type: GET_ACCOUNT_ADMINS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteAdminForAccount = (accountId, adminId) => async (dispatch, getState) => {
  dispatch({
    type: GET_ACCOUNT_ADMINS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const responseAccount = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/accounts/removeadmin/${accountId}`,
      { id: adminId },
      options,
    );

    dispatch({
      type: GET_ACCOUNT_ADMINS_SUCCESS,
      payload: { account: responseAccount.data.account },
    });
  } catch (err) {
    dispatch({
      type: GET_ACCOUNT_ADMINS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const clearProjectError = (id) => ({
  type: CLEAR_ACCOUNT_ERROR,
  payload: { id },
});
