import React from 'react';
import { compose } from 'redux';
import { Link } from 'react-router-dom';

import requireAuth from '../../hoc/requireAuth';
import { connect } from 'react-redux';
import Button from '../../components/Buttons/Button';
import '../../components/User/User.css';
const Department = ({   
  department, 
  isFormVisible, 
  onOpenForm, 
  auth, 
  currentOpenForm, 
  onUnsetForm,
  selected,
  account
}) => {
    
  const checkPermissions = () => {
    if (
      auth.me.id === department.user?.id ||
      auth.me.role === 'SUPERADMIN' ||
      department.podMasters.some((podMaster) => podMaster.id === auth.me.id) ||
      account?.admins?.some(admin => admin.id === auth.me?.id)
    ) {
      return true;
    }
    return false;
  };

  const checkKPIPermissions = () => {
    if (
      department.account?.kpiFeature &&
      department.kpiView
    ) {
      return true;
    }
    return false;
  };

  return (
    < div className={`list-users__account account-users ${selected ? ' selected' : ''}`}>
      <div className="account-users__info">
        <div className="account-users__image">
          <div className="account-initial" style={{ backgroundColor: department?.color || '#000' }}>{department?.name.charAt(0)}</div>
        </div>        
        <div className="account-users__info-text">
          <h5 className="Material-KitH6">
            {department?.name || ''}            
          </h5>
          <span className="Material-KitCaption">{department?.account?.name || ''}</span>
        </div>
      </div>
      <div className="account-users__actions">        
        
        {checkKPIPermissions() && (
          <Link to={`/department/view/${department?.id}/true`}>
            <Button
              className={`button-view`}
              size="small"
            >
              <div className="btn__image">
                <img src="/icons/graph.svg" alt="Tier" />
              </div>
            </Button>
          </Link>
        )}
        <Link to={`/department/view/${department?.id}`}>
          
            <Button
              className={`button-view`}
              size="small"
            >
              <div className="button-view__image button__image">
                <img src="/icons/visibility.svg" alt="Pods" />
              </div>
            </Button>                           
        </Link>
        {checkPermissions() && (
          <>            
            <Button
              className="project-actions__button"
              size="small"
              onClick={() => {
                currentOpenForm === 'edit' ? onUnsetForm() : onOpenForm('edit', department.id);
              }}
            >
              <div className="btn__image">
                <img src="/icons/edit.svg" alt="Edit" />
              </div>
            </Button>            
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(requireAuth, connect(mapStateToProps))(Department);
