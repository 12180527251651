import React, { useMemo } from 'react';
import { normalizeWeeklyDataWithMaster, normalizeYearsData } from '../../../constants/functions';
import './Metrica.css';
import { colors } from '../../../constants/index';
import { month } from '../../../constants/index';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
const MetricData = ({ huddleBoard, title, labels, selectedDate }) => {
  const currentDate = useMemo(() => selectedDate || new Date(), [selectedDate]);
  const currentYear = useMemo(() => currentDate.getFullYear().toString(), [currentDate]);
  const currentMonth = useMemo(() => month[currentDate.getMonth()], [currentDate]);

  // const lastDayOfMonth = useMemo(
  //   () => new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0),
  //   [currentDate],
  // );
  // const daysInMonth = useMemo(() => lastDayOfMonth.getDate(), [lastDayOfMonth]);

  const metrics = useMemo(() => {
    // return huddleBoard?.data[0]?.years?.[currentYear]?.[currentMonth] || noDataArray;
    return normalizeYearsData(huddleBoard?.data, selectedDate)[0].years[currentYear][currentMonth];
  }, [huddleBoard, currentYear, currentMonth]);

  // const metrics = huddleBoard?.data?.[0]?.years?.['2024']?.['Nov'] || [];

  const getDynamicMetrics = () => {
    // console.log('Metrics:', metrics);

    if (title === 'Sponsor Report') {
      const values = huddleBoard?.data || [];
      const counts = {
        Green: values.filter((el) => el.statusValue === 'Green').length,
        Yellow: values.filter((el) => el.statusValue === 'Yellow').length,
        Red: values.filter((el) => el.statusValue === 'Red').length,
        noData: values.filter((el) => el.statusValue === 'no-data').length,
      }; /* 
      console.log(counts); */
      return [
        {
          color: colors.acceptColor,
          label: labels[0],
          value: `${counts.Green} (${((counts.Green / values.length) * 100).toFixed(2)}%)`,
        },
        {
          color: colors.yellowColor,
          label: labels[1],
          value: `${counts.Yellow} (${((counts.Yellow / values.length) * 100).toFixed(2)}%)`,
        },
        {
          color: colors.dangerColor,
          label: labels[2],
          value: `${counts.Red} (${((counts.Red / values.length) * 100).toFixed(2)}%)`,
        },
        {
          color: colors.grayColor,
          label: labels[3],
          value: `${counts.noData} (${((counts.noData / values.length) * 100).toFixed(2)}%)`,
        },
      ];
    }

    if (title === 'Team Pulse Check') {
      const valuesWhole = normalizeWeeklyDataWithMaster(huddleBoard?.data)[0].weeks.filter(
        (week) => week.week === moment(currentDate).format('YYYY-MM-DD'),
      );

      const values = valuesWhole.length > 0 ? valuesWhole[0]?.questions : [];

      const counts = {
        Five: values.filter((el) => el.rating === 5).length,
        Four: values.filter((el) => el.rating === 4).length,
        Three: values.filter((el) => el.rating === 3).length,
        Two: values.filter((el) => el.rating === 2).length,
        One: values.filter((el) => el.rating === 1).length,
        noData: values.filter((el) => !el.rating).length,
      };

      const sums = values.reduce((accumulator, val) => {
        return val.rating ? accumulator + val.rating : accumulator;
      }, 0);

      return [
        {
          color: '#D6E082',
          label: Array(labels[0])
            .fill(0)
            .map((el, idx) => {
              return <FontAwesomeIcon key={idx} icon={faStar} className={`rating-metric`} />;
            }),
          value: `${counts.Five} (${((counts.Five / values.length) * 100).toFixed(2)}%)`,
        },
        {
          color: '#E7E483',
          label: Array(labels[1])
            .fill(0)
            .map((el, idx) => {
              return <FontAwesomeIcon key={idx} icon={faStar} className={`rating-metric`} />;
            }),
          value: `${counts.Four} (${((counts.Four / values.length) * 100).toFixed(2)}%)`,
        },
        {
          color: '#F7E984',
          label: Array(labels[2])
            .fill(0)
            .map((el, idx) => {
              return <FontAwesomeIcon key={idx} icon={faStar} className={`rating-metric`} />;
            }),
          value: `${counts.Three} (${((counts.Three / values.length) * 100).toFixed(2)}%)`,
        },
        {
          color: '#FEE482',
          label: Array(labels[3])
            .fill(0)
            .map((el, idx) => {
              return <FontAwesomeIcon key={idx} icon={faStar} className={`rating-metric`} />;
            }),
          value: `${counts.Two} (${((counts.Two / values.length) * 100).toFixed(2)}%)`,
        },
        {
          color: '#FDD680',
          label: Array(labels[4])
            .fill(0)
            .map((el, idx) => {
              return <FontAwesomeIcon key={idx} icon={faStar} className={`rating-metric`} />;
            }),
          value: `${counts.One} (${((counts.One / values.length) * 100).toFixed(2)}%)`,
        },
        {
          color: colors.grayColor,
          label: labels[5],
          value: `${counts.noData} (${((counts.noData / values.length) * 100).toFixed(2)}%)`,
        },
        {
          color: colors.grayColor,
          label: labels[6],
          value: `${(
            sums /
            (counts.Five + counts.Four + counts.Three + counts.Two + counts.One)
          ).toFixed(2)}`,
          star: true,
        },
      ];
    }

    const counts = {
      // truthy: huddleBoard?.data.filter((day) => day === 'truthy').length,
      // falsy: huddleBoard?.data.filter((day) => day === 'falsy').length,
      // noData: huddleBoard?.data.filter((day) => day === 'no-data').length,
      truthy:
        metrics.filter((day) => (typeof day === 'object' ? day.status === 'true' : day === 'true'))
          .length ||
        huddleBoard?.data.filter((day) =>
          typeof day === 'object' ? day.status === 'truthy' : day === 'truthy',
        ).length,
      falsy:
        metrics.filter((day) =>
          typeof day === 'object' ? day.status === 'false' : day === 'false',
        ).length ||
        huddleBoard?.data.filter((day) =>
          typeof day === 'object' ? day.status === 'falsy' : day === 'falsy',
        ).length,
      noData: metrics.filter((day) =>
        typeof day === 'object' ? day.status === 'no-data' : day === 'no-data',
      ).length,
    };
    return [
      {
        color: '#4CAF50',
        label: labels[0],
        value: `${counts.truthy} (${((counts.truthy / metrics.length) * 100).toFixed(2)}%)`,
      },
      {
        color: '#FF7043',
        label: labels[1],
        value: `${counts.falsy} (${((counts.falsy / metrics.length) * 100).toFixed(2)}%)`,
      },
      {
        color: '#D9E4FF',
        label: labels[2],
        value: `${counts.noData} (${((counts.noData / metrics.length) * 100).toFixed(2)}%)`,
      },
    ];
  };

  return (
    <div className="control-metrics-data-row">
      {getDynamicMetrics().map((metric, idx) => (
        <div className="control-metrics-data-item" key={idx}>
          <div className="item-left-side">
            <div className="color-box" style={{ backgroundColor: metric.color }}></div>
            <span>{metric.label}</span>
          </div>
          <div className="item-right-side">
            <span>
              {metric.star ? (
                <>
                  {metric.value} <FontAwesomeIcon icon={faStar} className={`rating-metric`} />
                </>
              ) : (
                metric.value
              )}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};
export default MetricData;
