import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Button from '../../Buttons/Button';
import Loader from '../../Loader/Loader';
import ProjectForm from '../../DashboardComponents/ProjectForm/ProjectForm';
import requireAuth from '../../../hoc/requireAuth';
// import { getProject, deleteDepartmentForProject, addDepartmentForProject, moveDepartment } from '../../../store/actions/projectActions';
import { getDepartmentProjects, deleteProjectForDepartment, getProjectForDepartment, moveProject } from '../../../store/actions/departmentActions';

// import { getProjectDepartments } from '../../../store/actions/departmentActions';

// import './styles.css'; // all styles are in the ProjectDepartmentsEdit.js reference

const DepartmentPodProjectsEdit = ({
  getDepartmentProjects,
  deleteProjectForDepartment,
  getProjectForDepartment,
  thisDepartment: { department, relatedProjects, isLoading, error },
  // thisAccount: { account, isLoading: accountIsLoading, error: accountError },
  project: { projects, isLoading: projectsIsLoading, error: projectsError },
  history,
  match,
  departmentID,
  accountID,
  onUnsetForm,
  onReturnBack,
  onOpenForm,
  setProject,
  // getAccount,
  // getProjectDepartments,
  moveProject, 
}) => {
  const matchDepartmentId = departmentID || match.params.departmentId;
  const matchAccountId = accountID || match.params.accountId;
  const [ filteredProjects, setFilteredProjects ] = useState([]);

  
  
  useEffect(() => {
    setFilteredProjects(relatedProjects.filter(p => !department.projects.some(dp => dp.toString() === p.id.toString())));
  }, [relatedProjects, department.projects])

  useEffect(() => {
    getDepartmentProjects(matchDepartmentId, true, history);
    // getProjectDepartments(project.id, true, history);
    // if (matchAccountId) {
    //   getAccount(matchAccountId, true, history);
    //   getProjectDepartments(matchAccountId);
    // } else {
      
    // }
  }, [getDepartmentProjects, history, matchDepartmentId, matchAccountId]);

  
  return (
    <div className="dashboard-form project-list">
      <div className="project-form__heading flex align-center justify-space-between">
        <h2 className="Material-KitH4">Department Projects</h2>
        <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
          <div className="btn__image">
            <img src="/icons/arrow_back.svg" alt="previous" />
          </div>
        </Button>
      </div>

      {error && <div className="error-center Material-KitCaption">{error}</div>}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="project-departments-list-container list flex flex-column justify-space-between">
          <div className="flex flex-row">
            <div className="department-list">
              <h4 className="Material-KitH6">Remove</h4>
              { department.projects.length > 0 
                ?  department.projects.map((project, index) => {
                  return (
                    <div key={index}>
                    {relatedProjects.some(p => (p.id.toString() === project.toString())) &&                       
                      <div className="department">

                        <div className="department-list-item">
                          <div className="department-row">
                            <div className="department-row-left">
                              <div className="department-color-box" style={{ backgroundColor: 'var(--general-general-100)' }}></div>
                              {relatedProjects.some(p => (p.id.toString() === project.toString() && p.privateDepartment)) ? 
                                <div 
                                  className="department-name Material-KitCaption private-department-name"
                                  onClick={() => {
                                    onOpenForm('project/edit');                                      
                                    setProject(project);
                                  }}  
                                >{relatedProjects.find(p => p.id.toString() === project.toString())?.title}</div> : 
                                <div className="department-name Material-KitCaption">{relatedProjects.find(p => p.id.toString() === project.toString())?.title}</div>
                              }                             
                            </div>          
                            
                            <div className="department-tags">
                              <div className="user-count Material-KitSB2">
                                <span>{relatedProjects.find(p => p.id.toString() === project.toString())?.departments?.length}</span>
                                <div className="user-count__icon">
                                  <img src="/icons/groups_2.svg" alt="users" />
                                </div>
                                {/* {department.viewOnly && (
                                  <div className="user-count__icon">
                                    <img src="/icons/visibility.svg" alt="read only" />
                                  </div>
                                )}
                                {department.external && (
                                  <div className="user-count__icon">
                                    <img src="/icons/shield_person.svg" alt="external" />
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        
                        <div className={`department-actions ${index > 0 && index < department.projects.length - 1 ? 'flex-center' : index > 0 ? 'flex-left' : 'flex-right'}`} style={{ width: '110px', marginRight: '20px' }}> 
                          {index > 0 && <Button
                            size="x-mini"
                            className="primary-blue button-move-up"
                            onClick={() => {                        
                              moveProject(department.id, project, 'up');                        
                            }}                          
                          >
                            <div className="department-actions__image">
                              <img src="/icons/arrow_drop_up.svg" alt="Up" />
                            </div>
                          </Button>}
                          {index < department.projects.length - 1 && <Button
                            size="x-mini"
                            className="primary-blue button-move-down"
                            onClick={() => {                        
                              moveProject(department.id, project, 'down');                        
                            }}                          
                          >
                            <div className="department-actions__image">
                              <img src="/icons/arrow_drop_down.svg" alt="Down" />
                            </div>
                          </Button>}
                        </div>
                        <div className="department-actions flex align-center ">                        
                          <Button
                            size="x-mini"
                            className="primary-red"
                            onClick={() => {                        
                              deleteProjectForDepartment({departmentID: department.id, id: project});                        
                            }}
                          >
                            <div className="department-actions__image">
                              <img src="/icons/delete.svg" alt="delete" />
                            </div>
                          </Button>
                        </div>
                        
                      </div>
                    }
                    </div>
                    
                  )
                }) 
              : <p className="department-name Material-KitCaption">No projects or custom rows added to this department pod</p>}
            </div>
            <div className="department-list">
              <h4 className="Material-KitH6">Add</h4>
              { filteredProjects.length > 0 
                ?  filteredProjects.map((project, index) => {
                  return (
                    <div key={index}>
                      <div className="department">
                        
                        <div className="department-list-item">
                          <div className="department-row">
                            <div className="department-row-left">
                              <div className="department-color-box" style={{ backgroundColor: 'var(--general-general-100)' }}></div>
                              {project.privateDepartment ? 
                                <div 
                                  className="department-name Material-KitCaption private-department-name"
                                  onClick={() => {
                                    onOpenForm('project/edit');                                      
                                    setProject(project.id);
                                  }}  
                                >{project.title}</div> : 
                                <div className="department-name Material-KitCaption">{project.title}</div>
                              }
                            </div>          
                            
                            <div className="department-tags">
                              <div className="user-count Material-KitSB2">
                                <span>{project?.departments?.length}</span>
                                <div className="user-count__icon">
                                  <img src="/icons/groups_2.svg" alt="users" />
                                </div>
                                {/* {department.viewOnly && (
                                  <div className="user-count__icon">
                                    <img src="/icons/visibility.svg" alt="read only" />
                                  </div>
                                )}
                                {department.external && (
                                  <div className="user-count__icon">
                                    <img src="/icons/shield_person.svg" alt="external" />
                                  </div>
                                )} */}
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div className="department-actions flex align-center ">
                          <Button
                            size="x-mini"
                            className="primary-blue"
                            onClick={() => {                        
                              getProjectForDepartment({departmentID: department.id, id: project.id});                        
                            }}
                          >
                            <div className="department-actions__image">
                              <img src="/icons/add.svg" alt="Add" />
                            </div>
                          </Button>
                        </div>
                      </div>
                      
                    </div>
                  )
                }) 
              : <p className="department-name Material-KitCaption">No projects or custom rows remaining to add</p>}
            </div>
          </div>
          <ProjectForm 
            departmentId={matchDepartmentId} 
            accountId={matchAccountId}
            // getDepartmentProjects={getDepartmentProjects}
          />            
        </div>
      )}
      
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  thisDepartment: state.department,
  // thisAccount: state.account,
  project: state.project,
});

// export default compose(requireAuth, withRouter, connect(mapStateToProps))(ProjectDepartmentsEdit);
export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getDepartmentProjects, deleteProjectForDepartment, getProjectForDepartment, moveProject }),
)(DepartmentPodProjectsEdit);
