import * as Yup from 'yup';

export const projectFormSchema = Yup.object({
  title: Yup.string()
    .min(1, '"Title" must be 1 characters at minimum')
    .max(100, '"Title" must be 100 characters or less')
    .required('"Title" is required'),
  projectPlan: Yup.string(),
  risks: Yup.string(),
  opportunities: Yup.string(),
  resources: Yup.string(),
  account: Yup.string().nullable().when('privateDepartment', (privateDepartment, schema) => {
    if (!privateDepartment) 
      return schema.required('"Account" is required');    
    return schema;
  }),
});
