// export const FACEBOOK_AUTH_LINK = `${process.env.REACT_APP_BASE_URL}/auth/facebook`;
// export const GOOGLE_AUTH_LINK = `${process.env.REACT_APP_BASE_URL}/auth/google`;

export const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const letterOptions = ['People', 'Safety', 'Cost', 'Quality', 'Delivery'];

export const dailyOptions = ['Daily Agenda', 'Attendance'];

export const weeklyOptions = ['Sponsor Report', 'Weekly Priorities', 'Team Pulse Check'];

export const currentDate = new Date();

// currentDate.setDate(currentDate.getDate() - 1);

export const currentYear = currentDate.getFullYear().toString();

export const currentMonth = month[currentDate.getMonth()];

export const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

export const daysInMonth = lastDayOfMonth.getDate();

export const TEXTAREA_HEIGHT = 20;

// export const statuses = ['inProgress', 'completed', 'archived', 'late'];

export const statuses = ['inProgress', 'completed', 'archived'];

export const transmissionStatuses = ['new', 'read', 'draft', 'sent', 'archived'];

export const dailyAgendaStatuses = ['not completed', 'completed'];

export const attendanceStatuses = ['absent', 'present'];

export const teamPulseRatings = ['0', '1', '2', '3', '4', '5'];

export const colors = {
  brandColor: '#1e5eff',
  mainColor: '#336dff',
  grayColor: '#e6e9f4',
  yellowColor: '#ffdd00',
  blackColor: 'rgba(19, 21, 35, 1)',
  dangerColor: '#ff7043',
  acceptColor: 'rgba(91, 249, 138, 1)',
  darkAcceptColor: 'rgb(31, 206, 83)',
  highlightColor: '#729aff',
  lightColor: '#d9e4ff',
  huddleBoardBgColor: '#f5f6fa',
};

export const colorPickerColors = [
  '#B4A7D6',
  '#B6D7A8',
  '#FFE599',
  '#EA9999',
  '#97BBDF',
  '#DFA6DF',
  '#ACDFDF',
  '#FFE5D4',
  '#CCC2A9',
  '#D6D6D6',
  '#9FB6C1',
  '#E5CDD2',
];
