import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faUserGroup, faPowerOff, faUser, faHouse, faCircleQuestion, faBuilding, faBriefcase } from '@fortawesome/free-solid-svg-icons';

import { logOutUser } from '../../store/actions/authActions';
import './styles.css';

const Navbar = ({ 
  auth, 
  account: { account },
  logOutUser, 
  history 
}) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const onLogOut = (event) => {
    event.preventDefault();
    logOutUser(history);
  };
  
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      <div>
        {/* <h2 className="logo">Pods</h2>
        <h3 className="tagLine">Project Execution</h3> */}
        <Link to="/"><img className="logo" src={`${process.env.REACT_APP_BASE_URL}/Logo-left.png`} /></Link>
      </div>
      <div className="nav-links-mobile">
        <div className={'nav-links-mobile-container'}>
          <FontAwesomeIcon icon={faBars} className={`nav-links-mobile-icon ${isMobileMenuOpen ? 'rotate-icon' : ''}`} onClick={toggleMobileMenu}/>
        </div>
        {isMobileMenuOpen && (
          <div className="nav-links-mobile-menu">
            <ul>
              <li>
                <Link className="mobile-nav-item" to="/">Home</Link>
              </li>              
              <li>
                <Link className="mobile-nav-item" to="/departments">Department Pods</Link>
              </li>
              {auth.me?.role === 'SUPERADMIN' && (
                <>
                  {/* <li className="nav-item">
                    <Link to="/admin">Admin</Link>
                  </li> */}
                  <li>
                    <Link className="mobile-nav-item" to="/accounts">Accounts</Link>
                  </li>
                  <li>
                    <Link className="mobile-nav-item" to="/users">Users</Link>
                  </li>                  
                </>
              )}
              {account?.admins?.some(admin => admin.id === auth.me.id) && (
                <>
                  {/* <li className="nav-item">
                    <Link to="/admin">Admin</Link>
                  </li> */}
                  <li>
                    <Link className="mobile-nav-item" to="/account">Account</Link>
                  </li>                  
                  <li>
                    <Link className="mobile-nav-item" to={`/users/account/${account.id}`}>Users</Link>
                  </li>                  
                </>
              )}
              <li>
                <Link className="mobile-nav-item" to={`/${auth.me.username}`}>Profile</Link>
              </li>
              <li onClick={onLogOut}>
                <a className="mobile-nav-item" href="#">Log out</a>
              </li>
            </ul>
          </div>
        )}
      </div>
      <ul className="nav-links flex-1">
        {/* <li className="nav-item">
          <Link to="/">Home</Link>
        </li> */}
        {auth.isAuthenticated ? (
          <>
            {/* <li className="nav-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="nav-item">
              <Link to="/archive">Archive</Link>
            </li>             */}
            {/* <li className="nav-item">
              <Link to={`/${auth.me.username}`}>Profile</Link>
            </li> */}

            <li className="nav-button-icon">
              {/* <Link to="/users">Users</Link> */}
              <Link to="/">
                <div className={'project-nav-right-button'}>
                  <FontAwesomeIcon icon={faHouse} /> 
                  <span className="btn-project-nav-right-button-tooltip">Dashboard</span>
                </div>
              </Link>
            </li>            
            <li className="nav-button-icon">
              {/* <Link to="/departments">Department Pods</Link> */}
              <Link to="/departments">
                <div className={'project-nav-right-button'}>
                  <FontAwesomeIcon icon={faBriefcase} /> 
                  <span className="btn-project-nav-right-button-tooltip">Department Pods</span>
                </div>
              </Link>
            </li> 
            {auth.me?.role === 'SUPERADMIN' && (
              <>
                {/* <li className="nav-item">
                  <Link to="/admin">Admin</Link>
                </li> */}
                
                <li className="nav-button-icon">
                  {/* <Link to="/users">Users</Link> */}
                  <Link to="/accounts">
                    <div className={'project-nav-right-button'}>
                      <FontAwesomeIcon icon={faBuilding} />
                      <span className="btn-project-nav-right-button-tooltip">Accounts</span>                    
                    </div>
                  </Link>
                </li>
                
                <li className="nav-button-icon">
                  {/* <Link to="/users">Users</Link> */}
                  <Link to="/users">
                    <div className={'project-nav-right-button'}>
                      <FontAwesomeIcon icon={faUserGroup} />
                      <span className="btn-project-nav-right-button-tooltip">Users</span>                    
                    </div>
                  </Link>
                </li>
              </>
            )}

            {account?.admins?.some(admin => admin.id === auth.me.id) && (
              <>
                <li className="nav-button-icon">
                  <Link to={`/account`}>
                    <div className={'project-nav-right-button'}>
                      <FontAwesomeIcon icon={faBuilding} />
                      <span className="btn-project-nav-right-button-tooltip">Account</span>                    
                    </div>
                  </Link>
                </li>
                <li className="nav-button-icon">
                  <Link to={`/users/account/${account.id}`}>
                    <div className={'project-nav-right-button'}>
                      <FontAwesomeIcon icon={faUserGroup} />
                      <span className="btn-project-nav-right-button-tooltip">Users</span>                    
                    </div>
                  </Link>
                </li>
              </>
            )}

            <li className="nav-button-icon">
              {/* <Link to="/users">Users</Link> */}
              <a href="mailto:info@missionperform.com?subject=Pods%20Support%20Request">
                <div className={'project-nav-right-button'}>                
                  <FontAwesomeIcon icon={faCircleQuestion} />
                  <span className="btn-project-nav-right-button-tooltip">Help</span>
                </div>
              </a>
            </li>
            <li className="flex-1" />
            <li className="nav-button-icon">              
              <div className='nav-button-divider'></div>
            </li>
            { auth.me && (
              <li className="nav-button-icon">
                <Link to={`/${auth.me.username}`}>
                  <div className={'project-nav-right-button'}>
                    <FontAwesomeIcon icon={faUser} />
                    <span className="btn-project-nav-right-button-tooltip">Profile</span>
                  {/* <Link to={`/${auth.me.username}`} className="avatar">
                    <img className="avatar" src={`${process.env.REACT_APP_SERVER_BASE_URL}${auth.me.avatar}`} />
                  </Link> */}
                  </div>
                </Link>
              </li>
            )}
            <li className="nav-button-icon">
              {/* <a href="#">Log out</a> */}
              <div className={'project-nav-right-button'} onClick={onLogOut}>                
                  <FontAwesomeIcon icon={faPowerOff} />
                  <span className="btn-project-nav-right-button-tooltip">Log out</span>
              </div>
            </li>
          </>
        ) : (
          <>
            <li className="flex-1" />

            <li className="nav-item">
              <Link to="/login">Login</Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  account: state.account,
});

export default compose(withRouter, connect(mapStateToProps, { logOutUser }))(Navbar);
