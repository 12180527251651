import React, { useMemo, useState, useEffect } from 'react';
import LetterLayout from './LettersLayout/LetterLayout';
import CustomLayout from './CustomLayout/CustomLayout';
import DailyLayout from './DailyLayout/DailyLayout';
import WeeklyLayout from './WeeklyLayout/WeeklyLayout';
import HuddleBoardForm from './Form/HuddleBoardForm';
import { letterOptions, dailyOptions, weeklyOptions } from '../../constants/index';

const HuddleBoardContent = ({ title, huddleBoard, labels, tags, isHuddleBoardVisible, simple, projectId, departmentView }) => {
  
  const [selectedTag, setSelectedTag] = useState(title === 'Transmissions' ? null : tags?.[0]?.id);
  



  const [selectedHuddleBoard, setSelectedHuddleBoards] = useState(huddleBoard[0]);
  const [newTag, setNewTag] = useState(false);



  const isLetterComponent = useMemo(() => {
    return letterOptions.includes(title);
  }, [title]);

  const isDailyComponent = useMemo(() => {
    return dailyOptions.includes(title);
  }, [title]);

  const isWeeklyComponent = useMemo(() => {
    return weeklyOptions.includes(title);
  }, [title]);
 

  useEffect(() => {
    if (title === 'Transmissions') {    
      setSelectedHuddleBoards(huddleBoard[0]);
    } else {
      if (tags?.length > 0 && selectedTag) {
        if (huddleBoard.some((el) => (el.tag === selectedTag || !el.tag ))) {        
          setNewTag(false);
        } else {
          setNewTag(true);
        }
      }
      if (huddleBoard.length === 1) { 
        setSelectedHuddleBoards(huddleBoard[0]);
      } else {
        if (tags?.find((el) => el.id === selectedTag)) {
          setSelectedHuddleBoards(huddleBoard.find((el) => el.tag === selectedTag));
        } else if (tags?.length > 0) {
          setSelectedTag(tags[0]?.id);
          setSelectedHuddleBoards(huddleBoard.find((el) => el.tag === tags[0]?.id));
        } else {        
          setSelectedHuddleBoards(huddleBoard[0]);
        }
      }
    }
    
  }, [selectedTag, huddleBoard, tags]);



  return (
    <div className="huddle-board-content">
      
      {((huddleBoard.length > 1 || huddleBoard[0]?.tag) && title !== 'Transmissions') && <div className="huddle-board-tag-filter">
        {tags && tags.map((tag) => (
          <div 
            className={`huddle-board-tag-filter-item ${selectedTag === tag.id ? 'huddle-board-tag-filter-item-active' : ''}`} 
            key={tag.id} 
            onClick={() => setSelectedTag(tag.id)}
            style={{ backgroundColor: selectedTag === tag.id ? tag.color : 'var(--huddle-board-bg-color)' }}
          >
            {tag.name}
          </div>
        ))}

      </div>}

      {newTag ? (
        <HuddleBoardForm           
          projectId={projectId} 
          title={title} 
          simple={simple}
          tags={tags}
          newTag={newTag}
          selectedTag={selectedTag}
        />
      ) : isLetterComponent ? (
        <LetterLayout title={title} huddleBoard={selectedHuddleBoard} labels={labels} isHuddleBoardVisible={isHuddleBoardVisible}  departmentView={departmentView} />
      ) : isDailyComponent ? (
        <DailyLayout title={title} huddleBoard={selectedHuddleBoard} labels={labels} isHuddleBoardVisible={isHuddleBoardVisible} departmentView={departmentView} />
      ) : isWeeklyComponent ? (
        <WeeklyLayout title={title} huddleBoard={selectedHuddleBoard} labels={labels} isHuddleBoardVisible={isHuddleBoardVisible}  departmentView={departmentView} />
      ) : (
        <CustomLayout title={title} huddleBoard={selectedHuddleBoard} isHuddleBoardVisible={isHuddleBoardVisible} departmentView={departmentView} />
      )}


    </div>
  );
};

export default HuddleBoardContent;
