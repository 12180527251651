import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// import { useFormik } from 'formik';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faXmark } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../../components/Loader/Loader';
import requireAuth from '../../../hoc/requireAuth';
import {
  getProjectUsers,
  getPodmasterForProject,
  deletePodmasterForProject,
} from '../../../store/actions/projectActions';
import { getAccountUsers } from '../../../store/actions/userActions';
// import { podMastersFormSchema } from './validation';
import Button from '../../Buttons/Button';
/* import './styles.css'; */

const ProjectPodMastersEdit = ({
  getProjectUsers,
  getAccountUsers,
  project: { project, isLoading, error },
  projectID,
  user: { users, isLoading: usersIsLoading, error: usersError },
  getPodmasterForProject,
  deletePodmasterForProject,
  onReturnBack,
}) => {
  // const [addUserError, setAddUserError] = useState(null);
  // const [isSave, setIsSave] = useState(false);

  // const formik = useFormik({
  //   enableReinitialize: true,
  //   initialValues: {
  //     email: '',
  //   },
  //   validationSchema: podMastersFormSchema,
  //   onSubmit: (values, { resetForm }) => {
  //     const userExists = project.podMasters.some((user) => user.email === formik.values.email);
  //     if (userExists) {
  //       setAddUserError('This Pod Master is already in the project.');
  //       return;
  //     } else {
  //       getPodmasterForProject({ email: formik.values.email, projectID: project.id });
  //       setAddUserError(null);
  //       formik.setFieldTouched('email', false, false);
  //       formik.setFieldValue('email', '');
  //       setIsSave(true);
  //     }

  //     // resetForm();
  //   },
  // });

  

  useEffect(() => {
    getProjectUsers(projectID, true);
    getAccountUsers(project.account.id)

  }, [getProjectUsers, projectID]);

  // useEffect(() => {
  //   // getProject(matchProjectId, true, history);
  //   setIsSave(false);
  // }, [isSave]);
  const handleRemovePodMaster = (id) => {
    deletePodmasterForProject(project.id, id);
    // setIsSave(true);
  };
  const handleAddPodMaster = (id) => {
    getPodmasterForProject(project.id, id);
    // setIsSave(true);
  };
  return (
    // <div className="department-users-edit-page dashboard-form project-list project-pod-masters-edit">
    <div className="podmasters-edit-page dashboard-form project-list project-pod-masters-edit">
      {error && <div className="error-center">{error}</div>}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project-form__heading flex align-center justify-space-between margin-bottom-sm">
            <h2 className="Material-KitH4">
              Pod Masters
            </h2>
            <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
              <div className="btn__image">
                <img src="/icons/arrow_back.svg" alt="previous" />
              </div>
            </Button>
          </div>
          {/* <div className="department-users-list-container list flex flex-column"> */}
          <div className="podmasters-list-container list flex flex-column">
            {/* <div className="department-users-list podmasters-list"> */}
            <div className="podmaster-list">
              {project.podMasters.length > 0
                ? project.podMasters.map((user, index) => {
                    return (                      
                      <div key={index} className="podmaster">
                      {/* <div key={index} className="department-user podmaster"> */}
                        {/* <div className="department-users-list-item podmaster-list-item">*/}
                        <div className="podmaster-list-item">
                          <div className="user-title">
                            <div className="user-info__image">
                              <img src={user.avatar.startsWith('http') ? user.avatar : `${process.env.REACT_APP_SERVER_BASE_URL}${user.avatar}`} alt="user" />
                            </div>                                                          
                            <div className="user-name Material-KitCaption">{user.name}</div>
                          </div>
                          <div className="user-info-container">
                            {/* <div className="user-info user-username Material-KitOverline">
                              @{user.username}
                            </div> */}
                            <div className="user-info user-email Material-KitOverline">
                              {user.email}
                            </div>
                          </div>
                        </div>
                        {/* <div className="department-users-actions">*/}
                        <div className="podmaster-actions">
                          <Button
                            size="x-mini"
                            onClick={() => handleRemovePodMaster(user.id)}
                            type="button"
                            className="primary-red"
                          >
                            <div className="btn__image">
                              <img src="/icons/delete.svg" alt="delete" />
                            </div>
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : <p className="user-name Material-KitCaption">No Pod Masters selected</p>}
            </div>
            <div className="podmaster-list">
              <h2 className="Material-KitH4">Add from existing users</h2>
              {users.length > 0
                ? users.filter(user => !project.podMasters.some(podMaster => podMaster.id === user.id)).map((user, index) => {
                    return (                      
                      <div key={index} className="podmaster">                        
                        <div className="podmaster-list-item">
                          <div className="user-title">
                            <div className="user-info__image">
                              <img src={user.avatar.startsWith('http') ? user.avatar : `${process.env.REACT_APP_SERVER_BASE_URL}${user.avatar}`} alt="user" />
                            </div>                                                          
                            <div className="user-name Material-KitCaption">{user.name}</div>
                          </div>
                          <div className="user-info-container">
                            {/* <div className="user-info user-username Material-KitOverline">
                              @{user.username}
                            </div> */}
                            <div className="user-info user-email Material-KitOverline">
                              {user.email}
                            </div>
                          </div>
                        </div>
                        {/* <div className="department-users-actions">*/}
                        <div className="podmaster-actions">
                          <Button
                            size="x-mini"
                            onClick={() => handleAddPodMaster(user.id)}
                            type="button"
                            className="primary-blue"
                          >
                            <div className="btn__image">
                              <img src="/icons/add.svg" alt="Add" />
                            </div>
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : <p className="user-name Material-KitCaption">No Pod Masters selected</p>}
            </div>
            {/* <div className="department-users-form">
              <h2>Add a member</h2>
              <form onSubmit={formik.handleSubmit}>
                <>
                  <input
                    name="email"
                    placeholder="User email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    disabled={project.isLoading}
                    className="user-input Material-KitSB2 margin-bottom-sm"
                  />
                  {(formik.touched.email && formik.errors.email) || addUserError ? (
                    <p className="error Material-KitCaption">
                      {formik.errors.email || addUserError}
                    </p>
                  ) : null}
                  {project.error ? (
                    <p className="error Material-KitCaption">{project.error}</p>
                  ) : null}
                  <button
                    type="submit"
                    className="save-new-pod submit-btn unique-btn Material-KitButton bg"
                    disabled={project.isLoading}
                  >
                    Add Member
                  </button>
                </>
              </form>
            </div> */}
          </div>
          
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
  user: state.user,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getProjectUsers, getPodmasterForProject, deletePodmasterForProject, getAccountUsers }),
)(ProjectPodMastersEdit);
