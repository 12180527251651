import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faXmark } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../Loader/Loader';
import requireAuth from '../../../hoc/requireAuth';
import {
  getDepartment,
  getPodMasterForDepartment,
  deletePodMasterForDepartment,
} from '../../../store/actions/departmentActions';
import { getAccountUsers } from '../../../store/actions/userActions';

import Button from '../../Buttons/Button';


const DepartmentLeadsEdit = ({
  getDepartment,
  // getAccountUsers,
  department: { department, isLoading, error },
  departmentID,
  // user: { users, isLoading: usersIsLoading, error: usersError },
  getPodMasterForDepartment,
  deletePodMasterForDepartment,
  onReturnBack,
}) => {
  

  

  useEffect(() => {
    getDepartment(departmentID, true);
    // getAccountUsers(project.account.id)

  }, [getDepartment, departmentID]);

  const handleRemovePodMaster = (id, departmentID = department.id) => {
    deletePodMasterForDepartment({ id, departmentID });
  
  };
  const handleAddPodMaster = (id, departmentID = department.id) => {
    getPodMasterForDepartment({ id, departmentID });    
  };
  return (
    
    <div className="podmasters-edit-page dashboard-form project-list project-pod-masters-edit">
      {error && <div className="error-center">{error}</div>}
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className="project-form__heading flex align-center justify-space-between margin-bottom-sm">
            <h2 className="Material-KitH4">
              Department Leads
            </h2>
            <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
              <div className="btn__image">
                <img src="/icons/arrow_back.svg" alt="previous" />
              </div>
            </Button>
          </div>
          
          <div className="podmasters-list-container list flex flex-column">
          
            <div className="podmaster-list">
              {department.podMasters.length > 0
                ? department.podMasters.map((user, index) => {
                    return (                      
                      <div key={index} className="podmaster">
                      
                        <div className="podmaster-list-item">
                          <div className="user-title">
                            <div className="user-info__image">
                              <img src={user.avatar.startsWith('http') ? user.avatar : `${process.env.REACT_APP_SERVER_BASE_URL}${user.avatar}`} alt="user" />
                            </div>                                                          
                            <div className="user-name Material-KitCaption">{user.name}</div>
                          </div>
                          <div className="user-info-container">                            
                            <div className="user-info user-email Material-KitOverline">
                              {user.email}
                            </div>
                          </div>
                        </div>                        
                        <div className="podmaster-actions">
                          <Button
                            size="x-mini"
                            onClick={() => handleRemovePodMaster(user.id)}
                            type="button"
                            className="primary-red"
                          >
                            <div className="btn__image">
                              <img src="/icons/delete.svg" alt="delete" />
                            </div>
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : <p className="user-name Material-KitCaption">No Leads selected</p>}
            </div>
            <div className="podmaster-list">
              <h2 className="Material-KitH4">Add existing members</h2>
              {department.users?.filter(user => !department.podMasters.some(podMaster => podMaster.id === user.id)).length > 0
                ? department.users?.filter(user => !department.podMasters.some(podMaster => podMaster.id === user.id)).map((user, index) => {
                    return (                      
                      <div key={index} className="podmaster">                        
                        <div className="podmaster-list-item">
                          <div className="user-title">
                            <div className="user-info__image">
                              <img src={user.avatar.startsWith('http') ? user.avatar : `${process.env.REACT_APP_SERVER_BASE_URL}${user.avatar}`} alt="user" />
                            </div>                                                          
                            <div className="user-name Material-KitCaption">{user.name}</div>
                          </div>
                          <div className="user-info-container">                            
                            <div className="user-info user-email Material-KitOverline">
                              {user.email}
                            </div>
                          </div>
                        </div>                        
                        <div className="podmaster-actions">
                          <Button
                            size="x-mini"
                            onClick={() => handleAddPodMaster(user.id)}
                            type="button"
                            className="primary-blue"
                          >
                            <div className="btn__image">
                              <img src="/icons/add.svg" alt="Add" />
                            </div>
                          </Button>
                        </div>
                      </div>
                    );
                  })
                : <p className="user-name Material-KitCaption">No members left to add</p>}
            </div>
            
          </div>
          
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  department: state.department,
  // user: state.user,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getDepartment, getPodMasterForDepartment, deletePodMasterForDepartment }),
)(DepartmentLeadsEdit);
