import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import Button from '../../Buttons/Button';
import Loader from '../../Loader/Loader';
import requireAuth from '../../../hoc/requireAuth';

import '../../../components/DepartmentList/styles.css';

import {
  getProject,
  deleteProject,
  editProject,
  deletePodmasterForProject,
  clearProjectError,
} from '../../../store/actions/projectActions';
import { getDepartmentProjects } from '../../../store/actions/departmentActions';
import { getTags } from '../../../store/actions/tagActions';
import { projectFormSchema } from './validation';

import './styles.css';

const ProjectEditForm = ({
  getProject,
  getTags,
  auth,
  thisProject: { project, isLoading, error },
  tag: { tags, isLoading: tagsIsLoading, error: tagsError },
  editProject,
  clearProjectError,
  history,
  match,
  projectID,
  departmentID,
  onUnsetForm,
  currentOpenForm,
  onOpenForm,
  setDepartment,
  onReturnBack,
  setTag,
  deletePodmasterForProject,
  deleteProject,
  getDepartmentProjects,
}) => {
  const matchProjectId = projectID || match?.params?.projectId;
  const [isSave, setIsSave] = useState(false);
  const [toArchive, setToArchive] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: project.title || '',
      id: matchProjectId || '',
      projectPlan: project.projectPlan || '',
      risks: project.risks || '',
      opportunities: project.opportunities || '',
      resources: project.resources || '',
      fontStyle: project.fontStyle || 'Reenie-Beanie',
      fontWeight: project.fontWeight || 'bold',
      fontSizeLarge: project.fontSizeLarge || '37px',
      fontSizeSmall: project.fontSizeSmall || '25px',
      podMasters: project.podMasters || [],
      taskColoredBy: project.taskColoredBy || 0,
      account: project.account?.id || null,
      kpiView: project.kpiView || false,
      privateDepartment: project.privateDepartment || null,
    },
    validationSchema: projectFormSchema,
    onSubmit: (values) => {
      try {
        const podMasterIDs = values.podMasters.map((podMaster) => podMaster.id);
        let submitValues = {
          title: values.title,
          projectPlan: values.projectPlan,
          risks: values.risks,
          opportunities: values.opportunities,
          resources: values.resources,
          fontStyle: values.fontStyle,
          fontWeight: values.fontWeight,
          fontSizeLarge: values.fontSizeLarge,
          fontSizeSmall: values.fontSizeSmall,
          podMasters: podMasterIDs,
          taskColoredBy: values.taskColoredBy,
          account: values.account,
          kpiView: values.kpiView,
        };

        if (departmentID) {
          submitValues.privateDepartment = departmentID;
          delete submitValues.podMasters;
          delete submitValues.account;
        }

        editProject(values.id, submitValues);
        clearProjectError(project.id);
        setIsSave(true);
        if (departmentID) {
          getDepartmentProjects(departmentID, true, history);
          onReturnBack();
        }
      } catch (err) {
        console.error('Error updating project:', err);
      }
    },
  });
  useEffect(() => {
    getProject(matchProjectId, true, history);
    getTags(matchProjectId);
    setIsSave(false);
  }, [isSave, getProject, history, matchProjectId]);
  useEffect(() => {
    clearProjectError(matchProjectId);
  }, []);
  const handleRemovePodMaster = (podMasterId) => {
    deletePodmasterForProject(matchProjectId, podMasterId);
  };
  const handleArchive = (e, id, formData) => {
    e.preventDefault();
    editProject(id, formData);
  };
  return (
    <>
      {!departmentID ? (
        <div className="dashboard-form project-list">
          <div className="project-form__heading flex align-center justify-space-between">
            <h2 className="Material-KitH4">Edit Pod</h2>
            <div className="project-actions__buttons">
              {/* <Button
                className="project-actions__button btn-gray mr-20"
                onClick={(e) => {
                  if (window.confirm('Are you sure you want to archive this project?')) {
                    handleArchive(e, project.id, {
                      title: project.title,
                      archived: !project.archived,
                    });
                    onReturnBack();
                  }
                }}
                size="small"
                type="button"
              >
                <div className="btn__image">
                  <img src="/icons/archive.svg" alt="Archive" />
                </div>
              </Button> */}
              <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
                <div className="btn__image">
                  <img src="/icons/arrow_back.svg" alt="previous" />
                </div>
              </Button>
            </div>
          </div>
          {error && <div className="error-center Material-KitCaption">{error}</div>}
          {isLoading ? (
            <Loader />
          ) : (
            <div className="list">
              {/* 
              <h4 className="label Material-KitButton">{project.title}</h4> */}
              <span className="time text-light Material-KitCaption">{`Created ${moment(
                project.createdAt,
              ).fromNow()}`}</span>
              {project.user ? (
                <>
                  <span className="fullname text-light Material-KitCaption">{` by: ${project.user.name}`}</span>
                </>
              ) : null}
              <br />
              {!moment(project.createdAt).isSame(project.updatedAt, 'minute') && (
                <span className="time text-light Material-KitCaption">{`Edited: ${moment(
                  project.updatedAt,
                ).fromNow()}`}</span>
              )}

              <form onSubmit={formik.handleSubmit}>
                <div className="form-section">
                  <input type="hidden" name="id" />
                  <h4 className="label Material-KitButton">Pod Title</h4>
                  <input
                    name="title"
                    className="Material-KitSB2"
                    placeholder="Example Pod"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    disabled={project.isLoading}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <p className="error Material-KitCaption">{formik.errors.title}</p>
                  ) : null}
                </div>

                <div className="form-section form-section-gap">
                  {/* Pod Masters */}
                  <div className="department-users-list">
                    {/* <h3 className="label Material-KitButton">
                      Pod Masters (
                      <Link to={`/project/podmasters/edit/${project.id}`} className="header-link">
                        Edit
                      </Link>
                      )
                    </h3> */}
                    <div className="project-edit__row">
                      <h3 className="label Material-KitButton">Pod Masters</h3>
                      <Button
                        size="mini"
                        className="action-btn primary-blue-bg bg"
                        onClick={() => {
                          currentOpenForm ? onOpenForm('podmaster/new') : onUnsetForm();
                        }}
                      >
                        <div className="department-btn__image">
                          <img src="/icons/add.svg" alt="add" />
                        </div>
                      </Button>
                    </div>
                    <div className="user-list-container">
                      {formik.values.podMasters.length === 0 ? (
                        <p className="Material-KitCaption">No Pod Masters selected</p>
                      ) : (
                        <>
                          {formik.values.podMasters.map((podMaster, index) => {
                            return (
                              <div key={index} className="user-list-item">
                                <div className="user-info-container">
                                  <div className="user-title">
                                    <div className="user-info__image">
                                      <img
                                        src={
                                          podMaster.avatar.startsWith('http')
                                            ? podMaster.avatar
                                            : `${process.env.REACT_APP_SERVER_BASE_URL}${podMaster.avatar}`
                                        }
                                        alt="user"
                                      />
                                    </div>
                                    <div className="user-info user-username Material-KitCaption">
                                      {podMaster.name}
                                    </div>
                                  </div>
                                  <div>
                                    <div className="user-info user-email Material-KitCaption">
                                      {podMaster.email}
                                    </div>
                                  </div>

                                  <Button
                                    size="mini"
                                    className="action-btn"
                                    onClick={() => {
                                      handleRemovePodMaster(podMaster.id);
                                    }}
                                  >
                                    <div className="department-btn__image">
                                      <img src="/icons/delete.svg" alt="delete" />
                                    </div>
                                  </Button>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-section form-section-gap">
                  {/* Departments */}
                  <div className="department-list">
                    <div className="project-edit__row">
                      <h3 className="label Material-KitButton">Departments / Custom Rows</h3>
                      <Button
                        size="mini"
                        className="action-btn primary-blue-bg bg"
                        type="button"
                        onClick={() => {
                          currentOpenForm ? onOpenForm('department/new') : onUnsetForm();
                        }}
                      >
                        <div className="department-btn__image">
                          <img src="/icons/add.svg" alt="add" />
                        </div>
                      </Button>
                    </div>

                    {/* <Link
                      to={`/project/departments/edit/${project.id}`}
                      className="department-list-link"
                    > */}
                    <div className="department-list-link">
                      <div className="department-list-container">
                        {isLoading ? (
                          <Loader />
                        ) : (
                          <>
                            {project.departments?.length === 0 ? (
                              <p className="Material-KitCaption">No departments created</p>
                            ) : (
                              <>
                                {project.departments?.map((department, index) => {
                                  return (
                                    <div className="department-list-item" key={index}>
                                      <div className="department-row">
                                        <div className="department-row-left">
                                          <div
                                            className="department-color-box "
                                            style={{ backgroundColor: department.color }}
                                          ></div>
                                          <div className="department-name Material-KitButton">
                                            {department.name}
                                          </div>
                                        </div>
                                        <div className="department-tags">
                                          <div className="user-count Material-KitSB2">
                                            <span>{department.users.length}</span>
                                            <div className="user-count__icon">
                                              <img src="/icons/groups_2.svg" alt="users" />
                                            </div>
                                            {department.viewOnly ? (
                                              <div className="user-count__icon">
                                                <img src="/icons/visibility.svg" alt="read only" />
                                              </div>
                                            ) : null}
                                            {department.external ? (
                                              <div className="user-count__icon">
                                                <img
                                                  src="/icons/shield_person.svg"
                                                  alt="external"
                                                />
                                              </div>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>

                                      {/* {(auth.me?.role === 'SUPERADMIN' || project.account.admins.some(admin => admin.toString() === auth.me?.id.toString())) && ( */}
                                      {department.privateProject && (
                                        <Button
                                          size="mini"
                                          className="action-btn "
                                          onClick={() => {
                                            currentOpenForm
                                              ? onOpenForm('department/edit')
                                              : onUnsetForm();
                                            setDepartment(department.id);
                                          }}
                                        >
                                          <div className="department-btn__image">
                                            <img src="/icons/edit.svg" alt="edit" />
                                          </div>
                                        </Button>
                                      )}
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                </div>

                <div className="form-section ">
                  {/* Tags */}
                  <div className="department-users-list">
                    <div className="project-edit__row">
                      <h3 className="label Material-KitButton">
                        Tags
                        {/* <Link to={`/project/tags/edit/${project.id}`} className="header-link">
                        Edit
                      </Link> */}
                      </h3>
                      <Button
                        size="mini"
                        className="action-btn primary-blue-bg bg"
                        type="button"
                        onClick={() => {
                          currentOpenForm ? onOpenForm('tag/new') : onUnsetForm();
                        }}
                      >
                        <div className="department-btn__image">
                          <img src="/icons/add.svg" alt="add" />
                        </div>
                      </Button>
                    </div>
                    {tagsError && (
                      <div className="error-center Material-KitCaption">{tagsError}</div>
                    )}
                    <div className="department-list-container">
                      {tagsIsLoading ? (
                        <Loader />
                      ) : (
                        <>
                          {tags.length === 0 ? (
                            <p className="Material-KitCaption">No tags created</p>
                          ) : (
                            <>
                              {tags.map((tag, index) => {
                                return (
                                  <div className="department-list-item" key={index}>
                                    <div className="department-row">
                                      <div className="department-row-left">
                                        <div
                                          className="tag-color-box"
                                          style={{ backgroundColor: tag.color }}
                                        ></div>
                                        <div className="department-name Material-KitSB2">
                                          {tag.name}
                                        </div>
                                      </div>
                                    </div>
                                    <Button
                                      size="mini"
                                      className="action-btn "
                                      onClick={() => {
                                        currentOpenForm ? onOpenForm('tag/edit') : onUnsetForm();
                                        setTag(tag.id);
                                      }}
                                    >
                                      <div className="department-btn__image">
                                        <img src="/icons/edit.svg" alt="edit" />
                                      </div>
                                    </Button>
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-section">
                  <h5 className="label Material-KitButton">Colour Code Tasks By</h5>

                  <select
                    name="taskColoredBy"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.taskColoredBy}
                    disabled={project.isLoading}
                    className="form-select Material-KitSB2"
                  >
                    <option value={0}>Departments</option>
                    <option value={1}>Tags</option>
                  </select>
                  {auth.me?.role === 'SUPERADMIN' ? (
                    <>
                      <h5 className="label Material-KitButton">Pod Account</h5>
                      <input
                        name="account"
                        className="Material-KitSB2"
                        placeholder="Example Pod"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.account || ''}
                        disabled={project.isLoading}
                      />
                    </>
                  ) : null}
                </div>

                <div className="form-section">
                  <h4 className="label Material-KitButton">Huddle Board Settings</h4>

                  <h5 className="project-form-label Material-KitOverline">Project Plan Link</h5>
                  {/* Project Plan */}
                  <input
                    name="projectPlan"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.projectPlan}
                    disabled={project.isLoading}
                    className="Material-KitSB2"
                  />
                  {formik.touched.projectPlan && formik.errors.projectPlan ? (
                    <p className="error Material-KitCaption">{formik.errors.projectPlan}</p>
                  ) : null}

                  <h5 className="project-form-label Material-KitOverline">Risks Link</h5>
                  {/* Risks */}
                  <input
                    name="risks"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.risks}
                    disabled={project.isLoading}
                    className="Material-KitSB2"
                  />
                  {formik.touched.risks && formik.errors.risks ? (
                    <p className="error Material-KitCaption">{formik.errors.risks}</p>
                  ) : null}

                  <h5 className="project-form-label Material-KitOverline">Opportunities Link</h5>
                  {/* Opportunities */}
                  <input
                    name="opportunities"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.opportunities}
                    disabled={project.isLoading}
                    className="Material-KitSB2"
                  />
                  {formik.touched.opportunities && formik.errors.opportunities ? (
                    <p className="error Material-KitCaption">{formik.errors.opportunities}</p>
                  ) : null}

                  {project.account?.kpiFeature && <>
                    <h5 className="project-form-label Material-KitOverline">Tier View</h5>
                    <select
                      name="kpiView"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.kpiView}
                      disabled={project.isLoading}
                      className="form-select Material-KitSB2"
                    >
                      <option value={false}>No</option>
                      <option value={true}>Yes</option>
                    </select>
                    {formik.touched.kpiView && formik.errors.kpiView ? (
                      <p className="error Material-KitCaption">{formik.errors.kpiView}</p>
                    ) : null}
                  </>}
                  
                </div>

                <div className="form-section">
                  <h4 className="label Material-KitButton">Task Font Settings</h4>

                  <h5 className="project-form-label Material-KitOverline">Font Style</h5>

                  <select
                    name="fontStyle"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fontStyle}
                    style={{
                      fontFamily: formik.values.fontStyle,
                      fontWeight: formik.values.fontWeight,
                    }}
                    disabled={project.isLoading}
                    className="form-select Material-KitSB2"
                  >
                    <option
                      value="Brush Script MT"
                      style={{
                        fontFamily: 'Brush Script MT',
                        fontWeight: formik.values.fontWeight,
                      }}
                    >
                      Brush Script MT
                    </option>
                    <option
                      value="Courier New"
                      style={{ fontFamily: 'Courier New', fontWeight: formik.values.fontWeight }}
                    >
                      Courier New
                    </option>
                    <option
                      value="Garamond"
                      style={{ fontFamily: 'Garamond', fontWeight: formik.values.fontWeight }}
                    >
                      Garamond
                    </option>
                    <option
                      value="'Klyakson', sans-serif"
                      style={{
                        fontFamily: "'Klyakson', sans-serif",
                        fontWeight: formik.values.fontWeight,
                      }}
                    >
                      Klyakson
                    </option>
                    <option
                      value="Lato"
                      style={{ fontFamily: 'Lato', fontWeight: formik.values.fontWeight }}
                    >
                      Lato
                    </option>
                    <option
                      value="Reenie-Beanie"
                      style={{ fontFamily: 'Reenie-Beanie', fontWeight: formik.values.fontWeight }}
                    >
                      Reenie Beanie
                    </option>
                    <option
                      value="'Roboto', sans-serif"
                      style={{
                        fontFamily: "'Roboto', sans-serif",
                        fontWeight: formik.values.fontWeight,
                      }}
                    >
                      Roboto
                    </option>
                  </select>

                  <h5 className="project-form-label Material-KitOverline">Font Weight</h5>
                  <select
                    name="fontWeight"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fontWeight}
                    style={{
                      fontFamily: formik.values.fontStyle,
                      fontWeight: formik.values.fontWeight,
                    }}
                    disabled={project.isLoading}
                    className="form-select Material-KitSB2"
                  >
                    <option
                      value="bold"
                      style={{ fontFamily: formik.values.fontStyle, fontWeight: 'bold' }}
                    >
                      Bold
                    </option>
                    <option
                      value="normal"
                      style={{ fontFamily: formik.values.fontStyle, fontWeight: 'normal' }}
                    >
                      Regular
                    </option>
                  </select>

                  <h5 className="project-form-label Material-KitOverline">
                    Font Size - Large Screen
                  </h5>

                  <select
                    name="fontSizeLarge"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fontSizeLarge}
                    style={{
                      fontFamily: formik.values.fontStyle,
                      fontWeight: formik.values.fontWeight,
                      fontSize: formik.values.fontSizeLarge,
                    }}
                    disabled={project.isLoading}
                    className="form-select Material-KitSB2"
                  >
                    <option
                      value="25px"
                      style={{
                        fontFamily: formik.values.fontStyle,
                        fontWeight: formik.values.fontWeight,
                        fontSize: '25px',
                      }}
                    >
                      XSmall
                    </option>
                    <option
                      value="31px"
                      style={{
                        fontFamily: formik.values.fontStyle,
                        fontWeight: formik.values.fontWeight,
                        fontSize: '31px',
                      }}
                    >
                      Small
                    </option>
                    <option
                      value="37px"
                      style={{
                        fontFamily: formik.values.fontStyle,
                        fontWeight: formik.values.fontWeight,
                        fontSize: '37px',
                      }}
                    >
                      Medium
                    </option>
                    <option
                      value="43px"
                      style={{
                        fontFamily: formik.values.fontStyle,
                        fontWeight: formik.values.fontWeight,
                        fontSize: '43px',
                      }}
                    >
                      Large
                    </option>
                    <option
                      value="49px"
                      style={{
                        fontFamily: formik.values.fontStyle,
                        fontWeight: formik.values.fontWeight,
                        fontSize: '49px',
                      }}
                    >
                      XLarge
                    </option>
                  </select>

                  <h5 className="project-form-label Material-KitOverline">
                    Font Size - Mobile Screen
                  </h5>

                  <select
                    name="fontSizeSmall"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fontSizeSmall}
                    style={{
                      fontFamily: formik.values.fontStyle,
                      fontWeight: formik.values.fontWeight,
                      fontSize: formik.values.fontSizeSmall,
                    }}
                    disabled={project.isLoading}
                    className="form-select Material-KitSB2"
                  >
                    <option
                      value="17px"
                      style={{
                        fontFamily: formik.values.fontStyle,
                        fontWeight: formik.values.fontWeight,
                        fontSize: '17px',
                      }}
                    >
                      XSmall
                    </option>
                    <option
                      value="21px"
                      style={{
                        fontFamily: formik.values.fontStyle,
                        fontWeight: formik.values.fontWeight,
                        fontSize: '21px',
                      }}
                    >
                      Small
                    </option>
                    <option
                      value="25px"
                      style={{
                        fontFamily: formik.values.fontStyle,
                        fontWeight: formik.values.fontWeight,
                        fontSize: '25px',
                      }}
                    >
                      Medium
                    </option>
                    <option
                      value="29px"
                      style={{
                        fontFamily: formik.values.fontStyle,
                        fontWeight: formik.values.fontWeight,
                        fontSize: '29px',
                      }}
                    >
                      Large
                    </option>
                    <option
                      value="33px"
                      style={{
                        fontFamily: formik.values.fontStyle,
                        fontWeight: formik.values.fontWeight,
                        fontSize: '33px',
                      }}
                    >
                      XLarge
                    </option>
                  </select>
                </div>

                <div className="project__buttons">
                  <Button
                    type="submit"
                    className="save-new-pod submit-btn Material-KitButton bg"
                    disabled={project.isLoading}
                  >
                    Save
                  </Button>

                  <Button
                    type="button"
                    className="cancel-btn submit-btn Material-KitButton bg"
                    disabled={project.isLoading}
                    onClick={() => {
                      formik.resetForm();
                      onUnsetForm();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                
                <div className="archive__prompt Material-KitButton" style={{ color: !toArchive ? 'var(--primary-blue)' : 'var(--system-red-1)' }} onClick={() => setToArchive(!toArchive)}>{!toArchive ? 'Archive this project?' : 'Cancel archive'}</div>
                <div className="project__buttons">
                  
                  {toArchive && (
                    <Button
                      type="button"
                      className="cancel-btn submit-btn Material-KitButton bg"
                      onClick={(e) => {
                        if (window.confirm('Are you sure you want to archive this project?')) {
                          handleArchive(e, project.id, {
                            title: project.title,
                            archived: !project.archived,
                          });
                          onReturnBack();
                        }
                      }}                                            
                    >
                      <div className="btn__image">
                        <img src="/icons/archive.svg" alt="Archive" />
                      </div>
                      &nbsp;Archive Now
                    </Button>
                  )}
                </div>
              </form>
            </div>
          )}
        </div>
      ) : (
        <div className="dashboard-form project-list">
          <div className="project-form__heading flex align-center justify-space-between">
            <h2 className="Material-KitH4">Edit Custom Row</h2>

            <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
              <div className="btn__image">
                <img src="/icons/arrow_back.svg" alt="previous" />
              </div>
            </Button>
          </div>
          {error && <div className="error-center Material-KitCaption">{error}</div>}
          {isLoading ? (
            <Loader />
          ) : (
            <div className="list">
              {/* 
              <h4 className="label Material-KitButton">{project.title}</h4> */}
              <span className="time text-light Material-KitCaption">{`Created ${moment(
                project.createdAt,
              ).fromNow()}`}</span>
              {project.user ? (
                <>
                  <span className="fullname text-light Material-KitCaption">{` by: ${project.user.name}`}</span>
                </>
              ) : null}
              <br />
              {!moment(project.createdAt).isSame(project.updatedAt, 'minute') && (
                <span className="time text-light Material-KitCaption">{`Edited: ${moment(
                  project.updatedAt,
                ).fromNow()}`}</span>
              )}

              <form onSubmit={formik.handleSubmit}>
                <div className="form-section">
                  <input type="hidden" name="id" />
                  <h4 className="label Material-KitButton">Custom Row Title</h4>
                  <input
                    name="title"
                    className="Material-KitSB2"
                    placeholder="Example Pod"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    disabled={project.isLoading}
                  />
                  {formik.touched.title && formik.errors.title ? (
                    <p className="error Material-KitCaption">{formik.errors.title}</p>
                  ) : null}
                  {formik.touched.account && formik.errors.account ? (
                    <p className="error Material-KitCaption">{formik.errors.account}</p>
                  ) : null}
                </div>

                <div className="project__buttons">
                  <Button
                    type="button"
                    className="cancel-btn submit-btn Material-KitButton bg"
                    disabled={project.isLoading}
                    onClick={() => {
                      if (window.confirm('Are you sure you want to delete this custom row?')) {
                        deleteProject(project.id);
                        formik.resetForm();
                        clearProjectError(project.id);
                        setIsSave(true);
                        onReturnBack();
                      }
                    }}
                  >
                    Delete Custom Row
                  </Button>
                </div>

                <div className="project__buttons">
                  <Button
                    type="submit"
                    className="save-new-pod submit-btn Material-KitButton bg"
                    disabled={project.isLoading}
                  >
                    Save
                  </Button>

                  <Button
                    type="button"
                    className="cancel-btn submit-btn Material-KitButton bg"
                    disabled={project.isLoading}
                    onClick={() => {
                      formik.resetForm();
                      onReturnBack();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  thisProject: state.project,
  auth: state.auth,
  tag: state.tag,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, {
    getProject,
    deleteProject,
    editProject,
    clearProjectError,
    getTags,
    deletePodmasterForProject,
    getDepartmentProjects,
  }),
)(ProjectEditForm);
