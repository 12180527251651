import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Button from '../../Buttons/Button';
import Project from '../Project/Project';
import Loader from '../../Loader/Loader';

import { getProjects } from '../../../store/actions/projectActions';
import { getAccount } from '../../../store/actions/accountActions';

const ArchivedProjectList = ({ 
  getProjects,
  auth,
  getAccount,
  account: { account, isLoading: accountLoading },
  project: { projects, isLoading, error } ,
  accountId,
}) => {
  useEffect(() => {
    getProjects(accountId, true);
    getAccount(accountId || auth.me?.account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="project-list">
      <div className="project-list__title">
        <h2 className="flex flex-column">Archived Project Pods<span className="Material-KitOverline">{(auth.me?.role !== 'SUPERADMIN' || accountId) && account?.name }</span></h2>
        <div className="project-list__title-buttons">
          <Link className="header-link" to="/">
            <Button size="large" className="project-list__back primary-blue bg">
              <div className="project-list__image">
                <img src="/icons/arrow_back_ios_new.svg" alt="Back to Active" />
              </div>
              <span>Active Pods</span>
            </Button>
          </Link>
        </div>
      </div>
      {error && <div className="error-center">{error}</div>}
      <div className="list">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {projects.map((project, index) => {
              return <Project 
                key={index} 
                project={project} 
                archived={true} 
                account={account}
              />;
            })}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
  account: state.account,
});

export default connect(mapStateToProps, { getProjects, getAccount })(ArchivedProjectList);
