import React, { useState, useMemo, useEffect } from 'react';
import GoldenMoments from './GoldenMoments/GoldenMoments';
import { statuses, transmissionStatuses } from '../../../constants/index';
import * as Yup from 'yup';
import Modal from '../Modal/Modal';
import { updateHuddleBoard, sendHuddleBoardTransmission } from '../../../store/actions/huddleBoardAction';
import { connect, useSelector } from 'react-redux';
import PatientCentricity from './PatientCentricity/PatientCentricity';
import NonTipActions from './NonTipActions/NonTipActions';
import Transmissions from './Transmissions/Transmissions';
import moment from 'moment';
import { filterDepartmentsArray } from '../../../constants/functions';
const CustomLayout = ({
  title,
  updateHuddleBoard,
  huddleBoard,
  departmentView,
  isHuddleBoardVisible,
  sendHuddleBoardTransmission,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [rowToEdit, setRowToEdit] = useState(null);

  const authMeId = useSelector((state) => state.auth.me?.id);
  const huddleBoards = useSelector((state) => state.huddleBoard.huddleBoards);
  const stateRelatedProjects = useSelector((state) => state.department.relatedProjects);
  const stateDepartments = filterDepartmentsArray(
    useSelector((state) => state.project.project.departments),
    authMeId,
  );
  const tags = useSelector((state) => state.tag.tags);
  let departments = [];
  if (departmentView) {
    departments = stateRelatedProjects;
  } else {
    departments = stateDepartments;
  }
  
  const [rows, setRows] = useState(huddleBoard?.data?.length > 0 ? huddleBoard.data : []);
  const [showCelebration, setShowCelebration] = useState(false);

  useEffect(() => {
    setRows(huddleBoard?.data?.length > 0 ? huddleBoard.data : []);
  }, [huddleBoard]);

  useEffect(() => {
    if (isHuddleBoardVisible) {
      if (modalOpen) {
        document.documentElement.style.setProperty('overflow', 'hidden');
      } else {
        document.documentElement.style.removeProperty('overflow');
      }
    } else {
      setModalOpen(false);
    }
  }, [modalOpen, isHuddleBoardVisible]);

  const fields = useMemo(() => {
    switch (title) {
      case 'Golden Moments':
        return [
          {
            name: 'name',
            label: 'Name',
            type: 'text',
            defaultValue: rows[rowToEdit]?.name || '',
            validation: Yup.string().required('Name is required'),
          },

          {
            name: 'description',
            label: 'Description',
            type: 'textarea',
            defaultValue: rows[rowToEdit]?.description || '',
            validation: Yup.string().required('Description is required'),
          },
          tags.length > 0 && {
            name: 'tags',
            label: 'Tag',
            type: 'select',
            defaultValue: rows[rowToEdit]?.tags || (tags?.length ? tags[0].id : ''),
            validation: Yup.string().optional(),
          },
          {
            name: 'department',
            label: 'Department',
            type: 'select',
            defaultValue:
              rows[rowToEdit]?.department || (departments?.length ? departments[0].id : ''),
            validation: Yup.string().required('Department is required'),
          },
          {
            name: 'date',
            label: 'When',
            type: 'date',
            defaultValue: rows[rowToEdit]?.date || moment().format('YYYY-MM-DD'),
            validation: Yup.date().required('Date is required'),
          },
        ].filter(Boolean);
      case 'Actions':
        return [
          {
            name: 'actions',
            label: 'Actions',
            type: 'text',
            defaultValue: rows[rowToEdit]?.actions || '',
            validation: Yup.string().required('Actions are required'),
          },
          {
            name: 'date',
            label: 'Due',
            type: 'date',
            defaultValue: rows[rowToEdit]?.date || moment().format('YYYY-MM-DD'),
            validation: Yup.date().required('Date is required'),
          },
          tags.length > 0 && {
            name: 'tags',
            label: 'Tag',
            type: 'select',
            defaultValue: rows[rowToEdit]?.tags || (tags?.length ? tags[0].id : ''),
            validation: Yup.string().optional(),
          },
          {
            name: 'department',
            label: 'Department',
            type: 'select',
            defaultValue:
              rows[rowToEdit]?.department || (departments?.length ? departments[0].id : ''),
            validation: Yup.string().required('Department is required'),
          },

          {
            name: 'name',
            label: 'Name',
            type: 'text',
            defaultValue: rows[rowToEdit]?.name || '',
            validation: Yup.string().required('Name is required'),
          },

          {
            name: 'status',
            label: 'Status',
            type: 'button',
            isMulti: true,
            options: statuses,
            defaultValue: rows[rowToEdit]?.status || [],
            validation: Yup.array().min(1, 'Please select at least one status'),
          },
          /* {
            name: 'isComplete',
            label: 'Complete',
            type: 'select',
            defaultValue: rows[rowToEdit]?.isComplete || 'In progress',
            validation: Yup.string().required('Completion status is required'),
          }, */
        ].filter(Boolean);
      case 'Transmissions':
        return [
          {
            name: 'text',
            label: 'Issue Description',
            type: 'text',
            defaultValue: rows[rowToEdit]?.text || '',
            validation: Yup.string().required('Transmission text is required'),
          },
          {
            name: 'person',
            label: 'Person',
            type: 'text',
            defaultValue: rows[rowToEdit]?.person || '',
            validation: Yup.string().optional(),
          },
          {
            name: 'date',
            label: 'Due',
            type: 'date',
            defaultValue: rows[rowToEdit]?.date || moment().format('YYYY-MM-DD'),
            validation: Yup.date().required('Due date is required'),
          },            
          {
            name: 'department',
            label: 'Department',
            type: 'select',
            defaultValue:
              rows[rowToEdit]?.department || (departments?.length ? departments[0].id : ''),
            validation: Yup.string().required('Department is required'),
          },
            
          {
            name: 'status',
            label: 'Status',
            type: 'button',
            hidden: true,
            isMulti: true,
            options: transmissionStatuses,
            defaultValue: rows[rowToEdit]?.status || ['draft'],
            validation: Yup.array().min(1, 'Please select at least one status'),
          },
          {
            name: 'originDepartment',
            label: 'From Department',
            type: 'text',
            defaultValue: rows[rowToEdit]?.originDepartment || huddleBoard?.department,
            validation: Yup.string().optional(),
            hidden: true,
          },
          {
            name: 'originProject',
            label: 'From Project',
            type: 'text',
            defaultValue: rows[rowToEdit]?.originProject || huddleBoard?.project,
            validation: Yup.string().optional(),
            hidden: true,
          }           
        ].filter(Boolean);
      default:
        return [];
    }
  }, [title, departments, rowToEdit, rows, tags]);

  const handleUpdate = (updatedRows) => {
    updateHuddleBoard(huddleBoard?.id, updatedRows, huddleBoard, 'Custom', moment());
  };

  const sendTransmission = (newRow) => {
    // console.log('newRow', newRow);
    sendHuddleBoardTransmission(newRow);
    // updateHuddleBoard(huddleBoard?.id, updatedRows, huddleBoard, 'Custom', moment());
  };

  const handleDeleteRow = (targetIndex) => {
    const updatedRows = rows.filter((_, idx) => idx !== targetIndex);
    setRows(updatedRows);
    handleUpdate(updatedRows);
  };

  const handleEditRow = (idx) => {
    setRowToEdit(idx);
    setModalOpen(true);
  };
  
  const handleSubmit = (newRow) => {
    const updatedRows =
      rowToEdit === null
        ? [...rows, newRow]
        : rows.map((currRow, idx) => (idx === rowToEdit ? newRow : currRow));

    setRows(updatedRows);
    setRowToEdit(null);
    if (title === 'Transmissions' && newRow.status.includes('sent')) { 
      sendTransmission(newRow);
      handleUpdate(updatedRows);
    } else {
      handleUpdate(updatedRows);
    }

    

    if (rowToEdit === null && title === 'Golden Moments') {
      setTimeout(() => {
        setShowCelebration(true);
        setTimeout(() => setShowCelebration(false), 4000);
      }, 200);
    }

    setModalOpen(false);
  };

  const handleArchiveRow = (idx) => {
    let updatedRows = []
    if (title === 'Transmissions') {
      updatedRows = rows.map((row, index) => {
        if (index === idx) {
          return { ...row, status: ['archived'] };
        } else {
          return row;
        }
      });
    } else {
      updatedRows = rows.map((row, index) => {
        if (index === idx && !row.status.includes('archived')) {
          return { ...row, status: [...row?.status, 'archived'] };
        } else {
          return row;
        }
      });
    }
    
    setRows(updatedRows);
    handleUpdate(updatedRows);
  };

  const handleMarkCompleteRow = (idx) => {
    let updatedRows = []
    if (title === 'Transmissions') {
      updatedRows = rows.map((row, index) => {
        if (index === idx) {
          return { ...row, status: ['read'] };
        } else {
          return row;
        }
      });
    } else {
      updatedRows = rows.map((row, index) => {
        if (index === idx && !row.status.includes('completed')) {
          let cleanInProgress = row.status.filter((el) => el !== 'inProgress');
          return { ...row, status: [...cleanInProgress, 'completed'] };
        } else {
          return row;
        }
      });
    }
    setRows(updatedRows);
    handleUpdate(updatedRows);
  };

  const componentMapping = () => {
    switch (title) {
      case 'Golden Moments':
        return (
          <>
            <GoldenMoments
              rows={rows}
              deleteRow={handleDeleteRow}
              editRow={handleEditRow}
              title={title}
              showCelebration={showCelebration}
              departments={departments}
              departmentView={departmentView}
            />
            <div className="btn-container flex-center">
              <button className="btn-add" onClick={() => setModalOpen(true)}>
                + Add Golden Moment
              </button>
            </div>
            {modalOpen && (
              <Modal
                title="Add Golden Moment"
                fields={fields}
                closeModal={() => {
                  setModalOpen(false);
                  setRowToEdit(null);
                }}
                onSubmit={handleSubmit}
                defaultValue={rowToEdit !== null ? rows[rowToEdit] : {}}
                departments={departments}
                departmentView={departmentView}
              />
            )}
          </>
        );
      case 'Client & Patient Centiricity':
        return (
          <PatientCentricity title={title} huddleBoard={huddleBoard} onUpdate={handleUpdate} />
        ); /* 
      case 'NON TIP Actions':
        return <NonTipActions title={title} huddleBoard={huddleBoard} onUpdate={handleUpdate} />;
 */
      case 'Actions':
        return (
          <>
            <NonTipActions
              rows={rows}
              deleteRow={handleDeleteRow}
              editRow={handleEditRow}
              onMarkCompleteRow={handleMarkCompleteRow}
              onArchiveRow={handleArchiveRow}
              title={title}
              departments={departments}
              departmentView={departmentView}
            />
            <div className="btn-container flex-center">
              <button className="btn-add" onClick={() => setModalOpen(true)}>
                + Add Non-Tip Action
              </button>
            </div>
            {modalOpen && (
              <Modal
                title="Add Non-Tip Action"
                fields={fields}
                closeModal={() => {
                  setModalOpen(false);
                  setRowToEdit(null);
                }}
                onSubmit={handleSubmit}
                defaultValue={rowToEdit !== null ? rows[rowToEdit] : {}}
                departments={departments}
                departmentView={departmentView}
              />
            )}
          </>
        );
      case 'Transmissions':
        return (
          <>
            <Transmissions
              rows={rows}
              deleteRow={handleDeleteRow}
              editRow={handleEditRow}
              onMarkCompleteRow={handleMarkCompleteRow}
              onArchiveRow={handleArchiveRow}
              title={title}
              departments={departments}
              departmentView={departmentView}
            />
            <div className="btn-container flex-center">
              <button className="btn-add" onClick={() => setModalOpen(true)}>
                + New Transmission
              </button>
            </div>
            {modalOpen && (
              <Modal
                title="New Transmission"
                fields={fields}
                closeModal={() => {
                  setModalOpen(false);
                  setRowToEdit(null);
                }}
                onSubmit={handleSubmit}
                defaultValue={rowToEdit !== null ? rows[rowToEdit] : {}}
                departments={departments}
                departmentView={departmentView}
              />
            )}
          </>
        );
      default:
        return <p>No specified HuddleBoard found</p>;
    }
  };
  return <>{componentMapping()}</>;
};
export default connect(null, { updateHuddleBoard, sendHuddleBoardTransmission })(CustomLayout);
