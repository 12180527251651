import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { updateHuddleBoard } from '../../../../store/actions/huddleBoardAction';
import { currentYear } from '../../../../constants';
import { month } from '../../../../constants';
import './StatusCard.css';
import { connect } from 'react-redux';
import moment from 'moment';

const StatusCard = ({ title, huddleBoard, labels, selectedDate, updateHuddleBoard }) => {
  
  const formattedDate = useMemo(() => {    
    return moment(selectedDate).format('dddd, MMMM Do');
  }, [selectedDate]);
  
  const todayDay = useMemo(() => {
    return moment(selectedDate).format('DD');
  }, [selectedDate]);
  
  const [isEdit, setIsEdit] = useState(false);
  
  useEffect(() => {
    // this is really bad practice - we should not be updating the huddle board like this. This is to be fixed in the future.
    if (!huddleBoard?.data[0]?.years[currentYear]?.[month[selectedDate.getMonth()]] && huddleBoard) {
      updateHuddleBoard(huddleBoard.id, 'no-data', huddleBoard, 'SQCPD', selectedDate);
    }    
    setComment(
      typeof huddleBoard?.data[0]?.years[currentYear]?.[month[selectedDate.getMonth()]]?.[
        todayDay - 1
      ] !== 'object'
        ? ''
        : huddleBoard?.data[0]?.years[currentYear][month[selectedDate.getMonth()]][todayDay - 1]
            .comment,
    );
  }, [huddleBoard, selectedDate, todayDay]);

  const [comment, setComment] = useState(
    typeof huddleBoard?.data[0]?.years[currentYear]?.[month[selectedDate.getMonth()]]?.[
      todayDay - 1
    ] !== 'object'
      ? ''
      : huddleBoard?.data[0]?.years[currentYear][month[selectedDate.getMonth()]][todayDay - 1]
          .comment,
  );
  
  const handleStatusChange = useCallback(
    (status) => {
      // this is really bad practice - we should not be updating the huddle board like this. This is to be fixed in the future.
      if (huddleBoard?.data[0]?.years[currentYear]?.[month[selectedDate.getMonth()]] && huddleBoard) {
        updateHuddleBoard(huddleBoard.id, 'no-data', huddleBoard, 'SQCPD', selectedDate);
      }
      
      updateHuddleBoard(
        huddleBoard.id,
        comment
          ? {
              status: status,
              comment: comment,
            }
          : status,
        huddleBoard,
        'SQCPD',
        selectedDate,
      );

      setComment(
        huddleBoard?.data[0]?.years[currentYear]?.[month[selectedDate.getMonth()]]?.[todayDay - 1]
          ?.comment || '',
      );
    },
    [huddleBoard, comment, selectedDate, todayDay, setComment],
  );
  
  return (
    <div className="status-card">
      <div className="status-card-header">
        {/* <span className="status-card-header-left">🔲 Yesterday</span> */}
        <span className="status-card-header-left">🔲 Selected Date</span>
        <span className="status-card-header-right">{formattedDate}</span>
      </div>
      <div className="status-card-body">
        <p>Leave a comment:</p>
        <div className="status-card__row">
          <textarea
            className="status-card-textarea"
            placeholder="Enter your comment here..."
            value={comment}
            disabled={!isEdit}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="status-card__buttons">
            {isEdit ? (
              <>
                <button
                  type="button"
                  className="status-card__button button-submit"
                  onClick={() => {
                    handleStatusChange(huddleBoard?.data[0]?.years[currentYear]?.[month[selectedDate.getMonth()]]?.[todayDay - 1]?.status || huddleBoard?.data[0]?.years[currentYear]?.[month[selectedDate.getMonth()]]?.[todayDay - 1] || 'no-data');
                    setIsEdit(false);
                  }}
                >
                  <img src="/icons/check_small.svg" alt="accept" />
                </button>
                <button
                  type="button"
                  className="status-card__button button-cancel"
                  onClick={() => {
                    setComment(
                      typeof huddleBoard?.data[0]?.years[currentYear]?.[
                        month[selectedDate.getMonth()]
                      ]?.[todayDay - 1] !== 'object'
                        ? ''
                        : huddleBoard?.data[0]?.years[currentYear][month[selectedDate.getMonth()]][
                            todayDay - 1
                          ].comment,
                    );
                    setIsEdit(false);
                  }}
                >
                  <img src="/icons/close.svg" alt="cancel" />
                </button>
              </>
            ) : (
              <button
                type="button"
                className="note__button button-edit"
                onClick={(e) => {
                  e.preventDefault();
                  setIsEdit(true);
                }}
              >
                <img src="/icons/edit.svg" alt="edit" />
              </button>
            )}
          </div>
        </div>
        <p>Choose status:</p>
        <div className="status-card-buttons">
          <button className="status-button green-button" onClick={() => handleStatusChange('true')}>
            {labels[0]}
          </button>
          <button className="status-button red-button" onClick={() => handleStatusChange('false')}>
            {labels[1]}
          </button>
          <button
            className="status-button neutral-button"
            onClick={() => handleStatusChange('no-data')}
          >
            Unset
          </button>
        </div>
      </div>
    </div>
  );
};
export default connect(null, { updateHuddleBoard })(StatusCard);
