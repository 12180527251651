import {
  FETCH_HUDDLE_BOARDS,
  FETCH_HUDDLE_BOARD,
  CREATE_HUDDLE_BOARD,
  UPDATE_HUDDLE_BOARD,
  DELETE_HUDDLE_BOARD,
  HUDDLE_BOARD_ERROR,
  FETCH_HUDDLE_BOARD_LOADING,
  UPDATE_HUDDLE_BOARD_TRANSMISSIONS,
} from '../types';

const initialState = {
  huddleBoards: [],
  huddleBoard: null,
  error: null,
  isLoading: false,
};

const huddleBoardReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case FETCH_HUDDLE_BOARD_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_HUDDLE_BOARDS:
      return {
        ...state,
        huddleBoards: payload,
        error: null,
        isLoading: false,
      };
    case FETCH_HUDDLE_BOARD:
      return {
        ...state,
        huddleBoard: payload,
        error: null,
        isLoading: false,
      };
    case CREATE_HUDDLE_BOARD:
      return {
        ...state,
        huddleBoards: [...state.huddleBoards, payload],
        error: null,

        isLoading: false,
      };

    case UPDATE_HUDDLE_BOARD:
      return {
        ...state,
        huddleBoards: state.huddleBoards.map((board) => {
          if (board.id === payload.id) return { ...payload };
          return board;
        }),
        huddleBoard: state.huddleBoard?.title === payload?.title ? payload : state.huddleBoard,
        error: null,

        isLoading: false,
      };
    case UPDATE_HUDDLE_BOARD_TRANSMISSIONS:
      return {
        ...state,
        huddleBoards: state.huddleBoards.map((board) => {
          let curHB = payload.huddleBoards?.find((hb) => hb.id === board.id);
          if (board.id === curHB?.id) return { ...curHB };
          return board;
        }),
        // huddleBoard: state.huddleBoard?.id === payload?.huddleBoard?.id ? payload.huddleBoard : state.huddleBoard,
        error: null,

        isLoading: false,
      };
    case DELETE_HUDDLE_BOARD:
      return {
        ...state,
        huddleBoards: state.huddleBoards.filter((board) => board._id !== payload),
        error: null,

        isLoading: false,
      };

    case HUDDLE_BOARD_ERROR:
      return { ...state, error: payload, isLoading: false };

    default:
      return state;
  }
};

export default huddleBoardReducer;
