import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomButton from '../../Buttons/Button';
import {
  Calendar,
  CalendarCell,
  Button as AriaButton,
  Heading,
  CalendarGrid,
} from 'react-aria-components';
import { parseDate, today } from '@internationalized/date';
import moment from 'moment';
import {
  formatedStatus,
  filterDepartmentsArray,
  filterTagsArray,
} from '../../../constants/functions';
import './Modal.css';
import '../../commonStyles/Modal/style.css';

const Modal = ({ fields, closeModal, onSubmit, defaultValue = {}, title = '', departmentView = false, departments = [] }) => {
  const auth = useSelector((state) => state.auth);
  const project = useSelector((state) => state.project.project);

  const tags = useSelector((state) => state.tag.tags);
  const transmissionHuddleBoards = useSelector((state) => state.huddleBoard.huddleBoards.filter((huddleBoard) => huddleBoard.title === 'Transmissions'));
  const [isEditingDepartment, setIsEditingDepartment] = useState(false);
  const [isEditingDueDate, setIsEditingDueDate] = useState(false);

  const [isEditingTags, setIsEditingTags] = useState(false);
  const initialValues = fields.reduce((acc, field) => {
    if (field.type === 'select' && field.name === 'department') {
      acc[field.name] = defaultValue[field.name] || [];
    } else if (field.type === 'select' && field.name === 'tags') {
      acc[field.name] = defaultValue[field.name] || [];
    } else if (field.type === 'date') {
      acc[field.name] = defaultValue[field.name] || moment().format('YYYY-MM-DD');
    } else {
      acc[field.name] = defaultValue[field.name] || field.defaultValue || '';
    }
    return acc;
  }, {});
  const validationSchema = Yup.object(
    fields.reduce((acc, field) => {
      if (field.validation) {
        acc[field.name] = field.validation;
      }
      return acc;
    }, {}),
  );
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.length ? validationSchema : undefined,
    onSubmit: (values) => {
      const processedValues = {
        ...values,
        department: values.department ? [...values.department] : [],
        tags: values.tags ? [...values.tags] : [],
      };
      onSubmit(processedValues);

      // Add a small delay to ensure the modal closes before celebration starts
      setTimeout(() => {
        closeModal();
      }, 100);
    },
  });

  const setDepartment = (departmentId) => {
    const currentDepartments = formik.values.department || [];
    if (!currentDepartments.includes(departmentId)) {
      formik.setFieldValue('department', [...currentDepartments, departmentId]);
    }
    setIsEditingDepartment(false);
  };

  const handleRemoveDepartment = (departmentId, e) => {
    e.stopPropagation();
    const currentDepartments = formik.values.department;
    formik.setFieldValue(
      'department',
      currentDepartments.filter((id) => id !== departmentId),
    );
  };
  const handleSetTag = (tagId) => {
    const currentTags = formik.values.tags || [];
    if (!currentTags.includes(tagId)) {
      formik.setFieldValue('tags', [...currentTags, tagId]);
    }
    setIsEditingTags(false);
  };

  const handleRemoveTag = (tagId, e) => {
    e.stopPropagation();
    const currentTags = formik.values.tags;
    formik.setFieldValue(
      'tags',
      currentTags.filter((id) => id !== tagId),
    );
  };
  const handleDateSelect = (date) => {
    const formattedDate = moment(date.toString()).format('YYYY-MM-DD');
    formik.setFieldValue('date', formattedDate);
    setIsEditingDueDate(false);
  };

  const dateSelection = () => {
    if (formik.values.date) {
      try {
        return parseDate(formik.values.date);
      } catch (e) {
        return today();
      }
    }
    return today();
  };

  return (
    <div
      className="modal-container"
      onClick={(e) => {
        if (e.target.className === 'modal-container') closeModal();
      }}
    >
      <div className="modal">
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-title-row flex align-center justify-space-between">
            {title && <legend className="modal-title flex">{title}</legend>}
            <button
              type="button"
              className="form-actions-icon task-form-buttons-x-cancel"
              onClick={closeModal}
            >
              <img src="/icons/close.svg" alt="close" />
            </button>
          </div>
          {fields.map((field) => (
            <div className="form-group" key={field.name} style={{ display: field.hidden ? 'none' : 'block' }}>
              {(field.type === 'select') ? (
                
                ((departmentView && field.name !== 'tags') || (field.name === 'department') || (!departmentView && field.name === 'tags')) && <div className="task-form-department-container">
                  <div className="form-department-icon form-task-icon">
                    <img
                      src={`/icons/${
                        field.name === 'department' ? 'groups_2.svg' : 'Variant59.svg'
                      }`}
                      alt="people group"
                    />
                  </div>
                  <div className="task-form-department-container-inner form-department-container-inner">
                    {field.name === 'department' ? (
                      formik.values.department?.length > 0 ? (
                        formik.values.department.map((deptId) => {
                          const dept = departments?.find((d) => d.id === deptId);
                          return dept ? (
                            <div
                              key={deptId}
                              className="task-form-department task-form-department-selected"
                              style={{ backgroundColor: dept.color }}
                            >
                              {departmentView ? dept.title : dept.name}
                              <div
                                className="remove-department-icon"
                                onClick={(e) => handleRemoveDepartment(dept.id, e)}
                              >
                                <img src="/icons/close.svg" alt="remove" />
                              </div>
                            </div>
                          ) : null;
                        })
                      ) : (
                        <div className="task-form-department">NONE</div>
                      )
                    ) : formik.values.tags?.length > 0 ? (
                      formik.values.tags.map((tagId) => {
                        const tag = tags.find((tag) => tag.id === tagId);
                        return tag ? (
                          <div
                            key={tagId}
                            className="task-form-department task-form-department-selected"
                            style={{ backgroundColor: tag.color }}
                          >
                            {tag.name}
                            <div
                              className="remove-department-icon"
                              onClick={(e) => handleRemoveTag(tag.id, e)}
                            >
                              <img src="/icons/close.svg" alt="remove" />
                            </div>
                          </div>
                        ) : null;
                      })
                    ) : (
                      <div className="task-form-department">NONE</div>
                    )}

                    <div
                      className="task-form-department-list form-department-list"
                      style={{
                        visibility:
                          field.name === 'department'
                            ? isEditingDepartment
                              ? 'unset'
                              : 'hidden'
                            : isEditingTags
                            ? 'unset'
                            : 'hidden',
                      }}
                    >
                      {field.name === 'department'
                        ? (!departmentView ? 
                            filterDepartmentsArray(departments, auth.me?.id)?.filter((dept) => { 
                              if (title === 'New Transmission') {
                                return transmissionHuddleBoards.find((huddleBoard) => huddleBoard.department?.includes(dept.id))
                              } else {
                                return true;
                              }
                            })
                            .map((dept) => (
                            <div
                              key={dept.id}
                              className={`task-form-department ${
                                formik.values.department?.includes(dept.id)
                                  ? 'task-form-department-selected'
                                  : ''
                              }`}
                              style={{
                                backgroundColor: dept.color,
                                opacity: formik.values.department?.includes(dept.id) ? 0.7 : 1,
                              }}
                              onClick={() => setDepartment(dept.id)}
                            >
                              {dept.name}
                            </div>))
                          : departments?.filter((dept) => {                            
                            if (title === 'New Transmission') {
                              return transmissionHuddleBoards.find((huddleBoard) => huddleBoard.project?.includes(dept.id))
                            } else {
                              return true;
                            }
                          })
                          .map((dept) => (
                            <div
                              key={dept.id}
                              className={`task-form-department ${
                                formik.values.department?.includes(dept.id)
                                  ? 'task-form-department-selected'
                                  : ''
                              }`}
                              style={{
                                backgroundColor: dept.color,
                                opacity: formik.values.department?.includes(dept.id) ? 0.7 : 1,
                              }}
                              onClick={() => setDepartment(dept.id)}
                            >
                              {dept.title}
                            </div>
                          )))
                        : (!departmentView && tags.map((tag) => (
                            <div
                              key={tag.id}
                              className={`task-form-department ${
                                formik.values.tags?.includes(tag.id)
                                  ? 'task-form-department-selected'
                                  : ''
                              }`}
                              style={{
                                backgroundColor: tag.color,
                                opacity: formik.values.tags?.includes(tag.id) ? 0.7 : 1,
                              }}
                              onClick={() => handleSetTag(tag.id)}
                            >
                              {tag.name}
                            </div>
                          )))}
                    </div>
                  </div>
                  <CustomButton
                    type="button"
                    size="mini"
                    className={`form-actions-edit bg primary-blue-bg ${
                      field.name === 'department'
                        ? isEditingDepartment
                          ? 'editing'
                          : ''
                        : isEditingTags
                        ? 'editing'
                        : ''
                    }`}
                    onClick={
                      field.name === 'department'
                        ? () => {
                            setIsEditingDepartment(!isEditingDepartment);
                            setIsEditingDueDate(false);
                            setIsEditingTags(false);
                          }
                        : () => {
                            setIsEditingTags(!isEditingTags);
                            setIsEditingDueDate(false);
                            setIsEditingDepartment(false);
                          }
                    }
                  >
                    <div className="form-actions-edit__image">
                      <img src="/icons/edit.svg" alt="edit" />
                    </div>
                  </CustomButton>
                </div>
              ) : field.type === 'date' ? (
                /*  <div className="form-due-date-container">
                  <div className="form-due-date-icon">

                    <img src="/icons/calendar_month.svg" alt="calendar" />
                  </div>
                  <div className="form-due-date-container-inner">
                    <div className={`form-due-date-selected ${isEditingDueDate ? 'editing' : ''}`}>
                      {formik.values[field.name] ? (
                        <div className="form-due-date form-due-date-selected">
                          {moment(formik.values[field.name]).format('MMM DD, YYYY')}
                          <div
                            className="remove-due-date-icon"
                            onClick={() => formik.setFieldValue(field.name, '')}
                          >
                            <img src="/icons/close.svg" alt="close" />
                          </div>
                        </div>
                      ) : (
                        <div
                          className="form-due-date"
                          style={{ opacity: isEditingDueDate ? 0 : 1 }}
                        >
                          NONE
                        </div>
                      )}
                    </div>

                    <div
                      className="form-due-date-list"
                      style={{ display: isEditingDueDate ? 'block' : 'none' }}
                    >
                      <Calendar value={dateSelection()} onChange={handleDateSelect}>
                        <header>
                          <AriaButton slot="previous">◀</AriaButton>
                          <Heading />
                          <AriaButton slot="next">▶</AriaButton>
                        </header>
                        <CalendarGrid>{(date) => <CalendarCell date={date} />}</CalendarGrid>
                      </Calendar>
                    </div>
                  </div>
                  <CustomButton
                    type="button"
                    size="mini"
                    className={`form-actions-edit primary-blue-bg ${
                      isEditingDueDate ? 'editing' : ''
                    }`}
                    onClick={() => {
                      setIsEditingDueDate(!isEditingDueDate);
                      setIsEditingDepartment(false);
                    }}
                  >
                    <img src="/icons/edit.svg" alt="edit" />
                  </CustomButton>
                </div> */
                <div className="task-form-due-date-container modal-form-due-date-container">
                  <div className="form-task-icon form-due-date-icon">
                    <img src="/icons/calendar_month.svg" alt="calendar" />
                  </div>
                  <div className="task-form-due-date-container-inner">
                    {formik.values.date ? (
                      <div className="task-form-due-date task-form-due-date-selected form-due-date-selected">
                        {formik.values.date}{' '}
                        <div
                          className="remove-department-icon"
                          onClick={() => formik.setFieldValue('date', '')}
                        >
                          <img src="/icons/close.svg" alt="remove" />
                        </div>
                      </div>
                    ) : (
                      <div className="task-form-due-date">{'NONE'}</div>
                    )}
                    <div
                      className="task-form-due-date-list"
                      style={{ visibility: isEditingDueDate ? 'unset' : 'hidden' }}
                    >
                      <Calendar
                        value={dateSelection()}
                        onChange={handleDateSelect}
                        aria-label="Date Picker"
                      >
                        <header>
                          <AriaButton slot="previous">◀</AriaButton>
                          <Heading />
                          <AriaButton slot="next">▶</AriaButton>
                        </header>

                        <CalendarGrid>{(date) => <CalendarCell date={date} />}</CalendarGrid>
                      </Calendar>
                    </div>

                    <CustomButton
                      type="button"
                      size="mini"
                      className={`form-actions-edit bg primary-blue-bg ${
                        isEditingDueDate ? 'editing' : ''
                      }`}
                      onClick={() => {
                        setIsEditingDueDate(!isEditingDueDate);
                        setIsEditingDepartment(false);

                        setIsEditingTags(false);
                      }}
                    >
                      <div className="form-actions-edit__image">
                        <img src="/icons/edit.svg" alt="edit" />
                      </div>
                    </CustomButton>
                  </div>
                </div>
              ) : field.type === 'button' ? (
                <div className="status-options" style={{ display: field.hidden ? 'none' : 'flex' }}>
                  {field.options.map((option, idx) => (
                    <button
                      key={idx}
                      type="button"
                      className={`status-option ${
                        formik.values[field.name]?.includes(option) ? 'selected' : 'not-selected'
                      }`}
                      onClick={() => {
                        // Toggle selection
                        const currentValues = formik.values[field.name] || [];
                        const updatedValues = currentValues.includes(option)
                          ? currentValues.filter((value) => value !== option) // Remove if already selected
                          : // : [...currentValues, option]; // Add if not selected
                            [option]; // Replace if not selected

                        formik.setFieldValue(field.name, updatedValues);
                      }}
                    >
                      {formatedStatus(option)}
                    </button>
                  ))}
                </div>
              ) : (
                <>
                  {/* <label
                    htmlFor={field.name}
                    className={field.hidden ? 'field-label-hidden' : 'field-label'}
                  >
                    {field.label}
                  </label> */}
                  {field.type === 'textarea' ? (
                    <textarea
                      name={field.name}
                      placeholder={field.label}
                      rows="4"
                      className="Material-KitB2 modal-textarea"
                      onChange={formik.handleChange}
                      value={formik.values[field.name]}
                    />
                  ) : (
                    <input
                      type={field.type || 'text'}
                      name={field.name}
                      className="Material-KitB2 modal-input"
                      placeholder={field.label}
                      onChange={formik.handleChange}
                      value={formik.values[field.name]}                      
                    />
                  )}
                </>
              )}
              {formik.touched[field.name] && formik.errors[field.name] && (
                <div className="error">{formik.errors[field.name]}</div>
              )}
            </div>
          ))}
          <div className="task-form-buttons">
            { title !== 'New Transmission' ? 
              <button type="submit" className="task-form-buttons-create modal-btn">
                SUBMIT
              </button> : 
              <>
                <button className="task-form-buttons-create modal-btn" onClick={() => {
                  formik.setFieldValue('status', ['sent']);
                  formik.handleSubmit();
                }}>
                  SEND
                </button>
                <button type="submit" className="task-form-buttons-cancel modal-btn">
                  DRAFT
                </button>
              </>
            }
            <button className="task-form-buttons-cancel modal-btn" onClick={closeModal}>
              CANCEL
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default Modal;
