import React from 'react';
import './Table.css';
import { useFormik } from 'formik';
import { currentDate, daysInMonth, month } from '../../../constants';
import { normalizeKPIData } from '../../../constants/functions';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { updateCustomHuddleBoard } from '../../../store/actions/huddleBoardAction';
const Table = ({
  projectId,
  label,
  labelColor,
  huddleBoard,
  updateCustomHuddleBoard,
  arrayRelatedToTable,
  setIsModalOpen,
  onSetReason,
}) => {
  const [selectedDate, setSelectedDate] = useState(
    new Date(moment().startOf('isoWeek').toISOString()),
  );
  // const huddleBoard = useSelector((state) => state.huddleBoard.huddleBoard);
  const monthName = month[selectedDate.getMonth()];
  const daysInMonth = Number(
    new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0).getDate(),
  );
  const [startOfWeek, setStartOfWeek] = useState(moment().startOf('isoWeek'));
  const normalizedData = normalizeKPIData(
    normalizeKPIData(huddleBoard.data, selectedDate),
    new Date(moment().startOf('isoWeek').add(7, 'days').toISOString()),
  );

  const formik = useFormik({
    // initialValues: huddleBoard.data[1].years?.[year]?.[monthName],
    initialValues: normalizedData[1],
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!huddleBoard) return;

      const updatedValues = [huddleBoard.data[0], values, huddleBoard.data[2], huddleBoard.data[3]];
      /* console.log(updatedValues) */
      updateCustomHuddleBoard(huddleBoard.id, updatedValues, huddleBoard);
    },
  });

  const formatTableDataDates = () => {
    const nextWeek = moment(selectedDate).add(2, 'week');
    const currentMonth = moment(selectedDate).get('month');
    const currentYear = moment(selectedDate).get('year');
    const nextWeekMonth = nextWeek.get('month');
    const nextWeekYear = nextWeek.get('year');

    if (nextWeekMonth !== currentMonth || nextWeekYear !== currentYear) {
      const nextMonthName = month[nextWeekMonth];
      const nextYearStr = nextWeekYear.toString();
      const daysInNextMonth = new Date(nextWeekYear, nextWeekMonth + 1, 0).getDate();

      let updatedFormikValues = { ...formik.values };

      if (!updatedFormikValues.years[nextYearStr]) {
        updatedFormikValues.years[nextYearStr] = {};
      }

      if (!updatedFormikValues.years[nextYearStr][nextMonthName]) {
        updatedFormikValues.years[nextYearStr][nextMonthName] = Array(daysInNextMonth)
          .fill()
          .map(() => {
            return Array(5)
              .fill()
              .map((_, i) => [
                {
                  id:
                    formik.values.years[currentYear.toString()][month[currentMonth]][i]?.[0]?.id ||
                    `_${i}_0`,
                  Label:
                    formik.values.years[currentYear.toString()][month[currentMonth]][i]?.[0]
                      ?.Label || '',
                  Actual: '',
                  Planned: '',
                  Reason: '',
                },
                {
                  id:
                    formik.values.years[currentYear.toString()][month[currentMonth]][i]?.[1]?.id ||
                    `_${i}_1`,
                  Label:
                    formik.values.years[currentYear.toString()][month[currentMonth]][i]?.[1]
                      ?.Label || '',
                  Actual: '',
                  Planned: '',
                  Reason: '',
                },
              ]);
          });
      }

      formik.setValues(updatedFormikValues);
    }
  };
  const handleChange = (e, index, field) => {
    formatTableDataDates();
    const value = e.target.value;
    const targetDate = moment(selectedDate).add(index, 'days');
    const targetDayOfMonth = targetDate.date() - 1;
    const targetMonthName = month[new Date(targetDate.toISOString()).getMonth()];
    const targetYear = new Date(targetDate.toISOString()).getFullYear().toString();

    if (value !== '') {
      formik.setFieldValue(
        `years[${targetYear}][${targetMonthName}][${targetDayOfMonth}][${arrayRelatedToTable[0]}][${arrayRelatedToTable[1]}].${field}`,
        value,
      );
    }
    const actualValue =
      field === 'Actual'
        ? Number(value) || 0
        : Number(
            formik.values.years[targetYear][targetMonthName][targetDayOfMonth][
              arrayRelatedToTable[0]
            ][arrayRelatedToTable[1]].Actual,
          ) || 0;

    const plannedValue =
      field === 'Planned'
        ? Number(value) || 0
        : Number(
            formik.values.years[targetYear][targetMonthName][targetDayOfMonth][
              arrayRelatedToTable[0]
            ][arrayRelatedToTable[1]].Planned,
          ) || 0;

    if (actualValue > plannedValue && plannedValue !== 0) {
      onSetReason(
        arrayRelatedToTable[0],
        arrayRelatedToTable[1],
        targetYear,
        targetMonthName,
        targetDayOfMonth,
      );
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  const handleNextWeek = () => {
    /* const nextWeek = moment(selectedDate).add(2, 'week');
    const currentMonth = moment(selectedDate).get('month');
    const currentYear = moment(selectedDate).get('year');
    const nextWeekMonth = nextWeek.get('month');
    const nextWeekYear = nextWeek.get('year');
    console.log(nextWeekMonth, nextWeekYear);
    if (nextWeekMonth !== currentMonth || nextWeekYear !== currentYear) {
      const nextMonthName = month[nextWeekMonth];
      const nextYearStr = nextWeekYear.toString();
      const daysInNextMonth = new Date(nextWeekYear, nextWeekMonth + 1, 0).getDate();

      let updatedData = { ...huddleBoard.data[1] };

      if (!updatedData.years[nextYearStr]) {
        updatedData.years[nextYearStr] = {};
      }
      if (!updatedData.years[nextYearStr][nextMonthName]) {
        updatedData.years[nextYearStr][nextMonthName] = Array(daysInNextMonth)
          .fill()
          .map(() => {
            return Array(5)
              .fill()
              .map((_, i) => [
                {
                  id:
                    huddleBoard.data[1].years[currentYear][month[currentMonth]][i][0].id ||
                    `_${i}_0`,
                  Label:
                    huddleBoard.data[1].years[currentYear][month[currentMonth]][i][0].Label || '',
                  Actual: '',
                  Planned: '',
                  Reason: '',
                },
                {
                  id:
                    huddleBoard.data[1].years[currentYear][month[currentMonth]][i][1].id ||
                    `_${i}_1`,
                  Label:
                    huddleBoard.data[1].years[currentYear][month[currentMonth]][i][1].Label || '',
                  Actual: '',
                  Planned: '',
                  Reason: '',
                },
              ]);
          });
      }

      const update = [huddleBoard.data[0], updatedData, huddleBoard.data[2], huddleBoard.data[3]];

      updateCustomHuddleBoard(huddleBoard.id, update, huddleBoard);
    } */

    setSelectedDate(new Date(moment(selectedDate).add(1, 'week').toISOString()));
  };

  const handlePrevWeek = () => {
    setSelectedDate(new Date(moment(selectedDate).subtract(1, 'week').toISOString()));
  };

  return (
    <div className="item-kpi-view__table-col flex flex-column flex-gap-16">
      <div className="flex flex-row justify-space-between full-width align-center">
        <div className={`Material-KitSB2_TS`} style={{ color: 'var(--general-general-80)' }}>
          <span className={`${labelColor}-label`}>●</span>&nbsp;{label}
        </div>
        <div className="select-period status-kpi-view__date flex flex-gap-16">
          <div className="select-period-button__prev" onClick={() => handlePrevWeek()}>
            {'<'}
          </div>
          <span>Week of {moment(selectedDate).startOf('isoWeek').format('MMM D')}</span>
          <div className="select-period-button__prev" onClick={() => handleNextWeek()}>
            {'>'}
          </div>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <table className="item-kpi-view__table">
          <thead>
            <tr className="kpi-view__metric-table-header">
              {/* <th></th> */}
              <th
                className={
                  moment(selectedDate).add(0, 'days').format('YYYY-MM-DD') ===
                  moment().format('YYYY-MM-DD')
                    ? 'item-kpi-view__table-metric-today'
                    : ''
                }
              >
                M
              </th>
              <th
                className={
                  moment(selectedDate).add(1, 'days').format('YYYY-MM-DD') ===
                  moment().format('YYYY-MM-DD')
                    ? 'item-kpi-view__table-metric-today'
                    : ''
                }
              >
                T
              </th>
              <th
                className={
                  moment(selectedDate).add(2, 'days').format('YYYY-MM-DD') ===
                  moment().format('YYYY-MM-DD')
                    ? 'item-kpi-view__table-metric-today'
                    : ''
                }
              >
                W
              </th>
              <th
                className={
                  moment(selectedDate).add(3, 'days').format('YYYY-MM-DD') ===
                  moment().format('YYYY-MM-DD')
                    ? 'item-kpi-view__table-metric-today'
                    : ''
                }
              >
                T
              </th>
              <th
                className={
                  moment(selectedDate).add(4, 'days').format('YYYY-MM-DD') ===
                  moment().format('YYYY-MM-DD')
                    ? 'item-kpi-view__table-metric-today'
                    : ''
                }
              >
                F
              </th>
              <th
                className={
                  moment(selectedDate).add(5, 'days').format('YYYY-MM-DD') ===
                  moment().format('YYYY-MM-DD')
                    ? 'item-kpi-view__table-metric-today'
                    : ''
                }
              >
                S
              </th>
              <th
                className={
                  moment(selectedDate).add(6, 'days').format('YYYY-MM-DD') ===
                  moment().format('YYYY-MM-DD')
                    ? 'item-kpi-view__table-metric-today'
                    : ''
                }
              >
                S
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="kpi-view__metric-table-row kpi-view__metric-table-row-actual">
              {/* <td>
                <strong>A</strong>
              </td> */}
              {Array(7)
                .fill(null)
                .map((_, index) => {
                  const targetDate = moment(selectedDate).add(index, 'days');
                  const targetDayOfMonth = targetDate.date() - 1;
                  const targetMonthName = month[new Date(targetDate.toISOString()).getMonth()];
                  const targetYear = new Date(targetDate.toISOString()).getFullYear().toString();
                  return (
                    <td key={index}>
                      <input
                        type="number"
                        value={
                          formik.values.years[targetYear]?.[targetMonthName]?.[targetDayOfMonth]?.[
                            arrayRelatedToTable?.[0]
                          ]?.[arrayRelatedToTable?.[1]]?.Actual || ''
                        }
                        style={{
                          color:
                            Number(
                              formik.values.years[targetYear]?.[targetMonthName]?.[
                                targetDayOfMonth
                              ]?.[arrayRelatedToTable?.[0]]?.[arrayRelatedToTable?.[1]]?.Actual,
                            ) >
                            Number(
                              formik.values.years[targetYear]?.[targetMonthName]?.[
                                targetDayOfMonth
                              ]?.[arrayRelatedToTable?.[0]]?.[arrayRelatedToTable?.[1]]?.Planned,
                            )
                              ? 'var(--system-red-1)'
                              : 'var(--system-green-1)',
                        }}
                        onChange={(e) => handleChange(e, index, 'Actual')}
                        onBlur={formik.handleSubmit}
                        onKeyDown={handleKeyDown}
                        className="no-spinner kpi-view__metric-table-row-input Material-KitHB6_S"
                      />
                    </td>
                  );
                })}
            </tr>
            <tr className="kpi-view__metric-table-row kpi-view__metric-table-row-planned">
              {/* <td>
                <strong>P</strong>
              </td> */}
              {Array(7)
                .fill(null)
                .map((_, index) => {
                  const targetDate = moment(selectedDate).add(index, 'days');
                  const targetDayOfMonth = targetDate.date() - 1;
                  const targetMonthName = month[new Date(targetDate.toISOString()).getMonth()];
                  const targetYear = new Date(targetDate.toISOString()).getFullYear().toString();

                  return (
                    <td key={index}>
                      <input
                        type="number"
                        value={
                          formik.values.years[targetYear]?.[targetMonthName]?.[targetDayOfMonth]?.[
                            arrayRelatedToTable[0]
                          ]?.[arrayRelatedToTable[1]]?.Planned || ''
                        }
                        onChange={(e) => handleChange(e, index, 'Planned')}
                        onBlur={formik.handleSubmit}
                        onKeyDown={handleKeyDown}
                        className="no-spinner kpi-view__metric-table-row-input Material-KitHB6_TS"
                        style={{
                          color: 'var(--general-general-60)',
                        }}
                      />
                    </td>
                  );
                })}
            </tr>
          </tbody>
        </table>
      </form>
      <div className="letters-kpi-view__spacer-divider-24"></div>
    </div>
  );
};

export default connect(null, { updateCustomHuddleBoard })(Table);
