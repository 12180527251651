import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import Loader from '../../components/Loader/Loader';
import Layout from '../../layout/Layout';
import requireAuth from '../../hoc/requireAuth';
import TaskForm from '../../components/TaskForm/TaskForm';
import DailyTaskCell from '../../components/DailyTaskCell/DailyTaskCell';
import HuddleBoards from '../../components/HuddleBoards/HuddleBoards';
import '../../components/DepartmentList/styles.css';
import DepartmentFilter from './Filters/DepartmentFilter';
import TagFilter from './Filters/TagFilter';
import { getProject, clearProjectError } from '../../store/actions/projectActions';
import { getProjectTasks } from '../../store/actions/taskActions';
import { getTags } from '../../store/actions/tagActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import KPIView from '../../components/KPIView/KPIView';
import {
  faXmark,
  faGavel,
  faUpRightAndDownLeftFromCenter,
  faDownLeftAndUpRightToCenter,
  faGear,
  faCheckDouble,
  faTable,
} from '@fortawesome/free-solid-svg-icons';
import { fetchHuddleBoards, updateHuddleBoard } from '../../store/actions/huddleBoardAction';
import { 
  filterDepartmentsArray,
  deconstructTask,
  deconstructValues,
  desconstructAudit,
 } from '../../constants/functions';
import './styles.css';

const ProjectView = ({
  getProject,
  getProjectTasks,
  getTags,
  auth,
  fetchHuddleBoards,
  updateHuddleBoard,
  task: { tasks, isLoading: tasksIsLoading, error: tasksError },
  thisProject: { project, isLoading, error },
  // department: { departments, isLoading: departmentsIsLoading, error: departmentsError },
  tag: { tags, isLoading: tagsIsLoading, error: tagsError },
  huddleBoard: {
    huddleBoard,
    huddleBoards,
    error: huddleBoardError,
    isLoading: huddleboardIsLoading,
  },
  history,
  match,
}) => {
  
  const matchProjectId = match.params.projectId;     

  const [isTaskFormVisible, setIsTaskFormVisible] = useState({});

  const [isHuddleBoardVisible, setIsHuddleBoardVisible] = useState(false);

  const [departmentFilter, setDepartmentFilter] = useState({});

  const [tagFilter, setTagFilter] = useState({});

  const [secondsToRefresh, setSecondsToRefresh] = useState(10);

  const [hideApproveDecline, setHideApproveDecline] = useState(false);

  const [expandTasks, setExpandTasks] = useState({});

  const [isKPIVisible, setIsKPIVisible] = useState(false);  

  useEffect(() => {
    if (!isKPIVisible) {
      if (secondsToRefresh > -1) {
        const timerId = setInterval(() => {
          setSecondsToRefresh((prevSeconds) => prevSeconds - 1); // decrement seconds
        }, 1000);

        // Clean up the interval on component unmount or when seconds reach 0
        return () => clearInterval(timerId);
      } else {
        getProjectTasks(matchProjectId);
        setSecondsToRefresh(10);
      }
    }
  }, [secondsToRefresh, isKPIVisible]);

  const toggleHuddleBoardVisibility = () => {    
    setIsHuddleBoardVisible(!isHuddleBoardVisible);
  };

  const toggleTaskFormVisibility = (departmentId) => {
    // console.log('isTaskFormVisible', isTaskFormVisible);
    setIsTaskFormVisible({
      // ...isTaskFormVisible,
      [departmentId]: !isTaskFormVisible[departmentId],
    });
    if (isTaskFormVisible[departmentId]) {
      document.documentElement.style.removeProperty('overflow');
    } else {
      document.documentElement.style.setProperty('overflow', 'hidden');
    }
  };

  const toggleDepartmentFilter = (departmentId) => {
    setDepartmentFilter({
      ...departmentFilter,
      [departmentId]: !departmentFilter[departmentId],
    });
    Object.keys(expandTasks).some((key) => key.includes(departmentId)) && setExpandTasks({});
  };

  const toggleTagFilter = (tagId) => {
    setTagFilter({
      ...tagFilter,
      [tagId]: !tagFilter[tagId],
    });
  };

  const toggleHideApproveDecline = () => {
    setHideApproveDecline(!hideApproveDecline);
  };
  
  useEffect(() => {
    if (!isKPIVisible) {
      getProjectTasks(matchProjectId);
      getProject(matchProjectId, true, history);
      getTags(matchProjectId);
      fetchHuddleBoards(matchProjectId, false, true);
    }
    document.documentElement.style.removeProperty('overflow');   

    setSecondsToRefresh(10);
  }, [isKPIVisible]);

  useEffect(() => {
    setIsHuddleBoardVisible(match.params.isKPIView === 'true' && project.kpiView && project.account.kpiFeature);
    setIsKPIVisible(match.params.isKPIView === 'true' && project.kpiView && project.account.kpiFeature);
  }, [project]);

  // is current user SUPERADMIN, POD MASTER or PROJECT OWNER
  const isAdmin = () => {
    return (
      auth.me.role === 'SUPERADMIN' ||
      project.podMasters?.some((pm) => pm.id === auth.me.id) ||
      auth.me.id === project.user?.id
    );
  };

  const isDepUserOrPodMaster = (department) => {
    return (
      auth.me.role === 'SUPERADMIN' ||
      department.users.some((user) => user.id === auth.me.id) ||
      auth.me.id === project.user.id ||
      project.podMasters?.some((pm) => pm.id === auth.me.id)
    );
  };

  const isDepUserOrDepPodMaster = (department) => {
    return (
      auth.me.role === 'SUPERADMIN' ||
      department.users.some((user) => user.id === auth.me.id) ||
      auth.me.id === project.user.id ||
      department.podMasters?.some((pm) => pm.toString() === auth.me.id.toString())
    );
  };

  // is current user a member of "external" departments
  const isExternal = () => {
    return project.departments?.some(
      (department) =>
        department.external && department.users.some((user) => user.id === auth.me.id),
    );
  };

  if (auth.isAuthenticated) {
    return (
      <Layout>
        <div
          className={`project-view-page ${isHuddleBoardVisible ? 'huddle-boards-active' : ''} ${
            Object.keys(expandTasks).length > 0 ? 'expand-tasks-active' : ''
          }`}
          style={{ backgroundColor: isKPIVisible ? 'white' : 'var(--general-general-30)' }}
        >
          {error && <div className="error-center">{error}</div>}
          {(isLoading) ? (
            <Loader />
          ) : (
            <>
              <div className="project-title-bar">
                <div className="project-title-bar-left-container">
                  <div className="project-title-bar-left">
                    {/* <h1 className="project-view-page-title">{project.title}<span style={{ color: 'var(--general-general-60)' }}>{isKPIVisible ? ' | Tier Board' : ''}</span></h1> */}
                    <h1 className="Material-KitH5 project-view-page-title">
                      {project.title}
                      <span style={{ color: 'var(--general-general-60)' }}>
                        {isKPIVisible ? ' | Tier Board' : ''}
                      </span>
                    </h1>
                    {!isKPIVisible && (
                      <p className="project-view-page-refresh-in">
                        {secondsToRefresh > 0
                          ? `Next refresh in ${secondsToRefresh} seconds`
                          : 'Fetching tasks...'}
                      </p>
                    )}
                  </div>
                  {!isKPIVisible && (
                    <div className="project-title-bar-right-filters-container">
                      {isAdmin() && (
                        <div className="project-title-bar-right-filter-container decision-mode-filter-responsive">
                          <div
                            className={`project-title-bar-right-filter-button ${
                              hideApproveDecline ? 'filter-active' : ''
                            }`}
                            onClick={toggleHideApproveDecline}
                          >
                            <FontAwesomeIcon icon={faGavel} />
                          </div>
                        </div>
                      )}

                      {!isExternal() && (
                        <div className="project-title-bar-right-filter-container huddle-boards-filter-responsive">
                          <div
                            className={`project-title-bar-right-filter-button`}
                            onClick={toggleHuddleBoardVisibility}
                          >
                            {isHuddleBoardVisible ? (
                              <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                            ) : (
                              <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
                            )}
                          </div>
                        </div>
                      )}
                      <div className="project-title-bar-right-filter-container completed-tasks-filter-responsive">
                        <Link to={`/project/completed/${project.id}`}>
                          <div className={`project-title-bar-right-filter-button`}>
                            <FontAwesomeIcon icon={faCheckDouble} />
                          </div>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                {!isKPIVisible && (
                  <div className="project-title-bar-right">
                    {isAdmin() && (
                      <div className="project-title-bar-right-filter-container decision-mode-filter">
                        <div
                          className={`project-title-bar-right-filter-button ${
                            hideApproveDecline ? 'filter-active' : ''
                          }`}
                          onClick={toggleHideApproveDecline}
                        >
                          {hideApproveDecline ? (
                            'DECISION MODE'
                          ) : (
                            <>
                              DECISION MODE &nbsp; <FontAwesomeIcon icon={faXmark} />
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="scrollable-filters">
                      <DepartmentFilter
                        departmentFilter={departmentFilter}
                        toggleDepartmentFilter={toggleDepartmentFilter}
                        project={project}
                        filterDepartmentsArray={filterDepartmentsArray}
                        authMeId={auth.me?.id}
                      />
                      <TagFilter
                        tags={tags}
                        tagFilter={tagFilter}
                        toggleTagFilter={toggleTagFilter}
                        project={project}
                      />
                    </div>
                  </div>
                )}
                <div id="project-nav-right-container" className="project-nav-right-container">
                  {isAdmin() && (
                    <>
                      <Link to={`/project/edit/${project.id}`}>
                        <div className={'project-nav-right-button'}>
                          <FontAwesomeIcon icon={faGear} />
                          <span className="btn-project-nav-right-button-tooltip">
                            Edit Pod Settings
                          </span>
                        </div>
                      </Link>
                      {/* <Link to={`/project/podmasters/edit/${project.id}`}>
                        <div className={'project-nav-right-button'}>                          
                            <FontAwesomeIcon icon={faUserTie} />
                            <span className="btn-project-nav-right-button-tooltip">Pod Masters</span>
                        </div>
                      </Link>
                      <Link to={`/project/departments/edit/${project.id}`}>
                        <div className={'project-nav-right-button'}>                          
                            <FontAwesomeIcon icon={faPeopleGroup} />
                            <span className="btn-project-nav-right-button-tooltip">Departments</span>
                        </div>
                      </Link> */}
                    </>
                  )}
                  <div className="nav-button-icon">
                    <div className="nav-button-divider"></div>
                  </div>
                  {!isKPIVisible && !isExternal() && (
                    <div
                      onClick={toggleHuddleBoardVisibility}
                      className={'project-nav-right-button'}
                    >
                      {isHuddleBoardVisible ? (
                        <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                      ) : (
                        <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
                      )}
                      <span className="btn-project-nav-right-button-tooltip">Huddle Boards</span>
                    </div>
                  )}
                  {!isKPIVisible ? (
                    project.kpiView ? (
                      <button
                        type="button"
                        className="project-nav-right-button"
                        onClick={() => {
                          setIsKPIVisible(true);
                          setIsHuddleBoardVisible(true);
                          history.push(`/project/view/${matchProjectId}/true`);
                        }}
                        id="kpi-view-button"
                      >
                        <img src="/icons/graph.svg" alt="KPI View" />

                        <span className="btn-project-nav-right-button-tooltip">KPI View</span>
                      </button>
                    ) : null
                  ) : (
                    <button
                      type="button"
                      className="project-nav-right-button"
                      onClick={() => {
                        setIsKPIVisible(!isKPIVisible);
                        history.push(`/project/view/${matchProjectId}`);
                      }}
                      id="kpi-view-button"
                    >
                      <FontAwesomeIcon icon={faTable} />
                      <span className="btn-project-nav-right-button-tooltip">View Pod</span>                      
                    </button>
                  )}
                  {!isKPIVisible && (
                    <Link to={`/project/completed/${project.id}`}>
                      <div className={'project-nav-right-button'}>
                        <FontAwesomeIcon icon={faCheckDouble} />
                        <span className="btn-project-nav-right-button-tooltip">
                          Completed Tasks
                        </span>
                      </div>
                    </Link>
                  )}
                </div>
              </div>
              <div
                className={`huddle-boards-container ${
                  isHuddleBoardVisible ? 'huddle-boards-container-active' : ''
                }`}
              >
                <HuddleBoards
                  huddleBoards={huddleBoards}
                  toggleHuddleBoardVisibility={toggleHuddleBoardVisibility}
                  projectId={matchProjectId}
                  isLoading={huddleboardIsLoading}
                  error={huddleBoardError}
                  updateHuddleBoard={updateHuddleBoard}
                  project={project}
                  tags={tags}
                  filteredTags={tags.filter((tag) => !tagFilter[tag.id])}
                />
              </div>
              {isKPIVisible ? (
                <KPIView history={history} departments={project.departments} />
              ) : (
                <>
                  <div className="project-view-page-header">
                    {/* Departments Column Header */}
                    <div className="project-view-page-body-row-header-department-1">
                      <div>Departments</div>
                    </div>

                    {/* Pacing Column Header */}
                    <div className="project-view-page-body-row-header-1">
                      <div>Pacing</div>
                    </div>

                    {/* Escalate Column Header */}
                    <div className="project-view-page-body-row-header-1">
                      <div>Escalate</div>
                    </div>

                    {/* Due Column Header */}
                    <div className="project-view-page-body-row-header-1 due-column">
                      <div>Due</div>
                    </div>

                    {/* 14 Days Column Headers */}
                    {[...Array(14)].map((_, index) => (
                      <div key={index} className="project-view-page-body-row-header-2">
                        {moment().add(index, 'days').format('dddd')}
                        <span className="project-view-page-body-row-header-2-date">
                          {moment().add(index, 'days').format('MMM DD')}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className="project-view-page-body">
                    {filterDepartmentsArray(project.departments, auth.me?.id).map((department) => (
                      <div
                        key={department.id}
                        className={`project-view-page-body-row ${
                          departmentFilter[department.id] ? 'hide-department' : ''
                        }`}
                      >
                        {/* Department Row Header */}
                        <div className="project-view-page-body-row-header-department-1">                          
                          {(isDepUserOrDepPodMaster(department) && !department.privateProject) && (
                            <div 
                              className="project-view-page-body-row-header-department-1-view-icon"
                            >
                              <Link to={`/department/view/${department.id}`}>                              
                                <img src="/icons/link.svg" alt="View Department Pod" />                              
                              </Link>
                            </div>
                          )}
                          <div style={{ margin: '10px' }}>
                            {isTaskFormVisible[department.id] && (
                              <TaskForm
                                department={department}
                                projectID={project.id}
                                isTaskFormVisible={isTaskFormVisible}
                                toggleTaskFormVisibility={toggleTaskFormVisibility}
                                tags={tags}
                              />
                            )}
                          </div>
                          <div className="project-view-page-body-row-department-name">
                            <div>{department.name}</div>
                            {isDepUserOrPodMaster(department) && (
                              <div>
                                {isTaskFormVisible[department.id] ? null : (
                                  <>
                                    <button
                                      onClick={() => toggleTaskFormVisibility(department.id)}
                                      className="btn-add-task"
                                      style={{
                                        backgroundColor:
                                          project.taskColoredBy === 0
                                            ? department.color
                                            : 'var(--brand-color)',
                                        color: project.taskColoredBy === 0 ? '#131523' : 'white',
                                      }}
                                    >
                                      + <span className="btn-add-task-tooltip">New Task</span>
                                    </button>
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                          
                        </div>

                        {/* Pacing */}
                        <DailyTaskCell
                          dayNumber={0}
                          department={department}
                          tasks={tasks}
                          tags={tags}
                          project={project}
                          hideApproveDecline={hideApproveDecline}
                          auth={auth}
                          deconstructTask={deconstructTask}
                          deconstructValues={deconstructValues}
                          desconstructAudit={desconstructAudit}
                          cellType="pacing"
                          expandTasks={expandTasks}
                          setExpandTasks={setExpandTasks}
                          tagFilter={tagFilter}
                        />

                        {/* Escalate */}
                        <DailyTaskCell
                          dayNumber={0}
                          department={department}
                          tasks={tasks}
                          tags={tags}
                          project={project}
                          hideApproveDecline={hideApproveDecline}
                          auth={auth}
                          deconstructTask={deconstructTask}
                          deconstructValues={deconstructValues}
                          desconstructAudit={desconstructAudit}
                          cellType="escalate"
                          expandTasks={expandTasks}
                          setExpandTasks={setExpandTasks}
                          tagFilter={tagFilter}
                        />

                        {/* Due */}
                        <DailyTaskCell
                          dayNumber={0}
                          department={department}
                          tasks={tasks}
                          tags={tags}
                          project={project}
                          hideApproveDecline={hideApproveDecline}
                          auth={auth}
                          deconstructTask={deconstructTask}
                          deconstructValues={deconstructValues}
                          desconstructAudit={desconstructAudit}
                          cellType="due"
                          expandTasks={expandTasks}
                          setExpandTasks={setExpandTasks}
                          tagFilter={tagFilter}
                        />

                        {/* Daily */}
                        {[...Array(14)].map((_, index) => (
                          <DailyTaskCell
                            dayNumber={index}
                            department={department}
                            tasks={tasks}
                            tags={tags}
                            project={project}
                            hideApproveDecline={hideApproveDecline}
                            auth={auth}
                            deconstructTask={deconstructTask}
                            deconstructValues={deconstructValues}
                            desconstructAudit={desconstructAudit}
                            key={index}
                            cellType="daily"
                            expandTasks={expandTasks}
                            setExpandTasks={setExpandTasks}
                            tagFilter={tagFilter}
                          />
                        ))}
                        {/* Empty space for the column view styling */}
                        <div className="project-view-page-body-row-task-cell no-tasks empty-space-task-cell"></div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </Layout>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => ({
  thisProject: state.project,
  auth: state.auth,
  department: state.department,
  tag: state.tag,
  task: state.task,
  huddleBoard: state.huddleBoard,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, {
    getProject,
    getProjectTasks,
    clearProjectError,
    getTags,
    updateHuddleBoard,
    fetchHuddleBoards,
  }),
)(ProjectView);
