import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { useFormik } from 'formik';
import { CirclePicker } from 'react-color';

import Loader from '../../Loader/Loader';
import requireAuth from '../../../hoc/requireAuth';

// import '../../components/TagList/styles.css';
import Button from '../../Buttons/Button';
import { getProject } from '../../../store/actions/projectActions';
import { getTagUsers, deleteTag, editTag, clearTagError } from '../../../store/actions/tagActions';
// import { getTags } from '../../store/actions/tagActions';
import { tagFormSchema } from './validation';
import { colorPickerColors } from '../../../constants/index';
import './TagEdit.css';

const TagEdit = ({
  getTagUsers,
  getProject,
  thisProject: {
    project,
    // isLoading,
    error,
  },
  tag: { tag, isLoading: tagIsLoading, error: tagError },
  editTag,
  clearTagError,
  history,
  onReturnBack,
  tagID,
}) => {
  const [isSave, setIsSave] = useState(false);
  const [color, setColor] = useState(tag.color);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: tag.name || '',
      id: tagID || '',
      color: tag.color || '#000000',
      project: tag.project || '',
    },
    validationSchema: tagFormSchema,
    onSubmit: (values, { resetForm }) => {
      editTag(values.id, {
        name: values.name,
        color: values.color,
        project: values.project.id,
      });
      clearTagError(tag.id);
      onReturnBack();
      setIsSave(true);
    },
  });

  useEffect(() => {
    getTagUsers(tagID);
  }, [getTagUsers, tagID]);

  useEffect(() => {
    getTagUsers(tagID);
    setIsSave(false);
  }, [isSave, getTagUsers, tagID]);

  useEffect(() => {
    setColor(tag.color);
  }, [tag]);

  useEffect(() => {
    if (tag.project) {
      getProject(tag.project.id, true, history);
    }
  }, [tag.project]);

  return (
    <div className=" dashboard-form project-list tag-edit-page">
      {error && <div className="error-center">{error}</div>}
      {tagIsLoading ? (
        <Loader />
      ) : (
        <div className="form-container">
          <div className="project-form__heading flex align-center justify-space-between">
            <h2 className="Material-KitH4">Edit Tag</h2>
            <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
              <div className="btn__image">
                <img src="/icons/arrow_back.svg" alt="previous" />
              </div>
            </Button>
          </div>
          <div className="list">
            <span className="time text-light Material-KitCaption">{`Created: ${moment(
              tag.createdAt,
            ).fromNow()}`}</span>
            {tag.user ? (
              <>
                <span className="fullname text-light Material-KitCaption">{` by: ${tag.user.name}`}</span>
              </>
            ) : null}
            <br />
            {!moment(tag.createdAt).isSame(tag.updatedAt, 'minute') && (
              <span className="time text-light Material-KitCaption">{`Edited: ${moment(
                tag.updatedAt,
              ).fromNow()}`}</span>
            )}

            <form onSubmit={formik.handleSubmit}>
              <>
                <input type="hidden" name="id" />
                <h3 className="label Material-KitCaption">Tag Name</h3>
                {/* Tag Name */}
                <input
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className="Material-KitSB2"
                  disabled={tag.isLoading}
                />
                {formik.touched.name && formik.errors.name ? (
                  <p className="error Material-KitCaption">{formik.errors.name}</p>
                ) : null}

                <h3 className="label Material-KitCaption">Color</h3>
                {/* Tag Color */}
                <div className="color-picker margin-bottom-sm">
                  {colorPickerColors.map((i) => (
                    <button
                      type="button"
                      key={i}
                      className="color-button "
                      style={{
                        backgroundColor: i,
                        border: color === i ? '2px solid var(--primary-primary-80)' : 'none',
                      }}
                      onClick={() => {
                        setColor(i);
                        formik.setFieldValue('color', i);
                      }}
                    />
                  ))}
                </div>
                {formik.touched.color && formik.errors.color ? (
                  <p className="error">{formik.errors.color}</p>
                ) : null}
              </>

              <div className="form-actions project__buttons flex align-center justify-space-between">
                {tag.error ? <p className="error Material-KitCaption">{tag.error}</p> : null}
                <Button
                  type="submit"
                  className="submit-btn Material-KitButton save-btn bg"
                  disabled={tagIsLoading}
                >
                  Save
                </Button>
                <Button
                  type="button"
                  className="cancel-btn submit-btn Material-KitButton bg"
                  disabled={tagIsLoading}
                  onClick={() => {
                    formik.resetForm();
                    onReturnBack();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  thisProject: state.project,
  auth: state.auth,
  tag: state.tag,
});

export default compose(
  requireAuth,
  withRouter,
  connect(mapStateToProps, { getTagUsers, getProject, deleteTag, editTag, clearTagError }),
)(TagEdit);
