import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';

// import '@fortawesome/fontawesome-svg-core/styles.css'

import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
// import Home from './pages/Home/Home';
import Dashboard from './pages/Dashboard/Dashboard';
import Archive from './pages/Archive/Archive';
import Profile from './pages/Profile/Profile';
import ProjectView from './pages/ProjectView/ProjectView';
import DepartmentView from './pages/DepartmentView/DepartmentView';
import CompletedTasksView from './pages/ProjectView/CompletedTasksView/CompletedTasksView';
import DepartmentCompletedTasksView from './pages/DepartmentView/DepartmentCompletedTasksView/DepartmentCompletedTasksView';
import Users from './pages/Users/Users';
import Accounts from './pages/Accounts/Accounts';
import Departments from './pages/Departments/Departments';
import AccountPage from './pages/Account/Account';
import Admin from './pages/Admin/Admin';
import NotFound from './pages/NotFound/NotFound';
import Loader from './components/Loader/Loader';

import { logInUserWithOauth, loadMe } from './store/actions/authActions';

const App = ({ logInUserWithOauth, auth, loadMe }) => {
  useEffect(() => {
    loadMe();
  }, [loadMe]);

  //redosled hookova
  useEffect(() => {
    if (window.location.hash === '#_=_') window.location.hash = '';

    const cookieJwt = Cookies.get('x-auth-cookie');
    if (cookieJwt) {
      Cookies.remove('x-auth-cookie');
      logInUserWithOauth(cookieJwt);
    }
  }, [logInUserWithOauth]);

  useEffect(() => {
    if (!auth.appLoaded && !auth.isLoading && auth.token && !auth.isAuthenticated) {
      loadMe();
    }
  }, [auth.isAuthenticated, auth.token, loadMe, auth.isLoading, auth.appLoaded]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/sw.js')
        .then(() => console.log('Service Worker Registered'))
        .catch((err) => console.error('Service Worker Registration Failed:', err));
    }
  }, []);

  return (
    <>
      {auth.appLoaded ? (
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/users/account/:accountId" component={Users} />
          <Route path="/users" component={Users} />
          <Route path="/accounts" component={Accounts} />
          <Route path="/account/:accountId" component={Dashboard} />
          <Route path="/account" component={AccountPage} />
          <Route path="/departments/account/:accountId" component={Departments} />
          <Route path="/departments" component={Departments} />

          {/* <Route path="/dashboard" component={Dashboard} /> */}
          <Route path="/archive" component={Archive} />
          <Route path="/notfound" component={NotFound} />
          <Route path="/admin" component={Admin} />
          <Route exact path="/project/edit/:projectId" component={Dashboard} />
          <Route exact path="/:username" component={Profile} />
          <Route exact path="/project/view/:projectId/:isKPIView" component={ProjectView} />
          <Route exact path="/project/view/:projectId" component={ProjectView} />
          <Route exact path="/project/completed/:projectId" component={CompletedTasksView} />
          <Route exact path="/department/edit/:departmentId" component={Departments} />
          <Route exact path="/department/view/:departmentId/:isKPIView" component={DepartmentView} />
          <Route exact path="/department/view/:departmentId" component={DepartmentView} />
          <Route exact path="/department/completed/:departmentId" component={DepartmentCompletedTasksView} />
          {/* <Route exact path="/" component={Home} /> */}
          <Route exact path="/" component={Dashboard} />
          <Route component={NotFound} />
        </Switch>
      ) : (
        <Loader />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default compose(connect(mapStateToProps, { logInUserWithOauth, loadMe }))(App);
