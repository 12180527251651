import axios from 'axios';

import { attachTokenToHeaders } from './authActions';
import {
  GET_PROFILE_LOADING,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  GET_ACCOUNT_USERS_LOADING,
  GET_ACCOUNT_USERS_SUCCESS,
  GET_ACCOUNT_USERS_FAIL,
  GET_PRIVATE_DEPARTMENT_USERS_LOADING,
  GET_PRIVATE_DEPARTMENT_USERS_SUCCESS,
  GET_PRIVATE_DEPARTMENT_USERS_FAIL,
  EDIT_USER_LOADING,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  DELETE_USER_LOADING,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  CREATE_USER_LOADING,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAIL,
} from '../types';

import { logOutUser, loadMe } from './authActions';
export const createUser = (formData) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_USER_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);

    // Create a new FormData instance
    // const form = new FormData();

    // // Append all fields to FormData
    // Object.keys(formData).forEach((key) => {
    //   // Only append if value exists
    //   if (formData[key] !== null && formData[key] !== undefined) {
    //     form.append(key, formData[key]);
    //   }
    // });
    

    // Add additional headers for multipart/form-data
    // options.headers['Content-Type'] = 'multipart/form-data';

    

    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/users`,
      formData,
      options,
    );

    if (response.data && response.data.user) {
      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: { user: response.data.user },
      });

      if (getState().auth.me?.id === response.data.user.id) {
        dispatch(loadMe());
      }
      return true;
    } else {
      throw new Error('Invalid response format');
    }
  } catch (err) {
    dispatch({
      type: CREATE_USER_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
    return false;
  }
};
export const editUser = (id, formData /* , history */) => async (dispatch, getState) => {
  dispatch({
    type: EDIT_USER_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);

    // // Create a new FormData instance
    // const form = new FormData();

    // // Append all fields to FormData
    // Object.keys(formData).forEach((key) => {
    //   // Only append if value exists
    //   if (formData[key] !== null && formData[key] !== undefined) {
    //     form.append(key, formData[key]);
    //   }
    // });

    // Add additional headers for multipart/form-data
    // options.headers['Content-Type'] = 'multipart/form-data';

    const response = await axios.put(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/users/${id}`,
      // form,
      formData,
      options,
    );

    if (response.data && response.data.user) {
      dispatch({
        type: EDIT_USER_SUCCESS,
        payload: { user: response.data.user },
      });

      // edited him self, reload me
      if (getState().auth.me?.id === response.data.user.id) {
        dispatch(loadMe());
      }
      return true;
    } else {
      throw new Error('Invalid response format');
    }
  } catch (err) {
    dispatch({
      type: EDIT_USER_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
    return false;
  }
};
export const getProfile = (username, history) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROFILE_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/users/username/${username}`,
      options,
    );

    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: { profile: response.data.user },
    });
  } catch (err) {
    if (err?.response.status === 404) {
      history.push('/notfound');
    }
    dispatch({
      type: GET_PROFILE_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getUser = (userID) => async (dispatch, getState) => {
  dispatch({
    type: GET_PROFILE_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/users/${userID}`,
      options,
    );
    dispatch({
      type: GET_PROFILE_SUCCESS,
      payload: { profile: response.data.user },
    });
  } catch (err) {
    dispatch({
      type: GET_PROFILE_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getAccountUsers = (accountId) => async (dispatch, getState) => {
  dispatch({
    type: GET_ACCOUNT_USERS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/users/account/${accountId}`,
      options,
    );
    dispatch({
      type: GET_ACCOUNT_USERS_SUCCESS,
      payload: { users: response.data.users },
    });
  } catch (err) {
    dispatch({
      type: GET_ACCOUNT_USERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const getPrivateDepartmentUsers = (departmentId) => async (dispatch, getState) => {
  dispatch({
    type: GET_PRIVATE_DEPARTMENT_USERS_LOADING,
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/users/privateDepartment/${departmentId}`,
      options,
    );
    dispatch({
      type: GET_PRIVATE_DEPARTMENT_USERS_SUCCESS,
      payload: { users: response.data.users },
    });
  } catch (err) {
    dispatch({
      type: GET_PRIVATE_DEPARTMENT_USERS_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};

export const deleteUser = (id, history) => async (dispatch, getState) => {
  dispatch({
    type: DELETE_USER_LOADING,
    payload: { id },
  });
  try {
    const options = attachTokenToHeaders(getState);
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_BASE_URL}/api/users/${id}`,
      options,
    );

    //logout only if he deleted himself
    if (getState().auth.me.id === response.data.user.id) {
      dispatch(logOutUser(id, history));
    }
    history.push('/users');
    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: { message: response.data.user },
    });
  } catch (err) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: { error: err?.response?.data.message || err.message },
    });
  }
};
