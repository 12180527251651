import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import requireAuth from '../../../hoc/requireAuth';
import Project from '../Project/Project';
import Button from '../../Buttons/Button';

import { getProjects } from '../../../store/actions/projectActions';
import { getAccount } from '../../../store/actions/accountActions';
import './styles.css';

const ActiveProjectList = ({
  auth,
  getProjects,
  getAccount,
  project: { projects, isLoading, error },
  account: { account, isLoading: accountLoading },
  onUnsetForm,
  currentOpenForm,
  isMobile = false,
  onOpenForm,
  selectedProject,
  accountId,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    if (accountId) {
      getProjects(accountId, false);
      getAccount(accountId);
    } else {
      getProjects(null, false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId]);
  useEffect(() => {
    setSearchTerm('');
    setFilteredProjects(projects);
  }, [projects]);

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = projects.filter((project) => {
      // Check if user has access to this project
      const hasAccess =
        auth.me?.role === 'SUPERADMIN' ||
        project.account?.admins?.some((admin) => admin.toString() === auth.me?.id.toString()) ||
        project.podMasters?.some((pm) => pm.id === auth.me?.id) ||
        project.departments?.some((dept) =>
          dept.users?.some((user) => user.toString() === auth.me?.id.toString()),
        );

      return hasAccess && project.title?.toLowerCase().includes(term);
    });

    setFilteredProjects(filtered);
  };

  return (
    <div className={`project-list ${isMobile ? 'only-child' : ''}`}>
      {projects.length > 0 ? (
        <>
          <div className="project-list__title">
            <h2 className="flex flex-column project-list__title-h2">
              Project Pods
              <span className="Material-KitOverline">
                {(auth.me?.role !== 'SUPERADMIN' || accountId) && account?.name}
              </span>
            </h2>

            {auth.me?.role === 'SUPERADMIN' ||
            account?.admins?.some((admin) => admin.id === auth.me?.id) ||
            auth.me?.role === 'PODMASTER' ? (
              <div className="project-list__title-buttons">
                <div className="actions-users__search project__search">
                  <input
                    type="text"
                    className="actions-users__search-input Material-KitSB2"
                    placeholder="Search projects..."
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  <div className="actions-users__search-icon">
                    <img src="/icons/search.svg" alt="Search" />
                  </div>
                </div>
                <Button
                  onClick={() => {
                    currentOpenForm === 'add' ? onUnsetForm() : onOpenForm('add');
                  }}
                  className="project-list__append"
                  size="small bg"
                >
                  <div className="project-list__image">
                    <img src="/icons/add.svg" alt="Add Project" />
                  </div>
                </Button>
                {auth.me?.role === 'SUPERADMIN' && (
                  <Link className="header-link" to="/archive">
                    <Button size="large" className="project-list__archive">
                      <div className="project-list__image">
                        <img src="/icons/archive.svg" alt="Archive" />
                      </div>
                      <span>Archive</span>
                    </Button>
                  </Link>
                )}
              </div>
            ) : (
              <div className="project-list__title-buttons">
                <div className="actions-users__search project__search">
                  <input
                    type="text"
                    className="actions-users__search-input Material-KitSB2"
                    placeholder="Search projects..."
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  <div className="actions-users__search-icon">
                    <img src="/icons/search.svg" alt="Search" />
                  </div>
                </div>
              </div>
            )}
          </div>
          {error && <div className="error-center Material-KitCaption">{error}</div>}
          <div className="list">
            {/* {isLoading ? (
              <Loader />
            ) : ( */}
            <>
              {filteredProjects.map((project, index) => {
                return (
                  <Project
                    key={index}
                    project={project}
                    archived={false}
                    currentOpenForm={currentOpenForm}
                    onUnsetForm={onUnsetForm}
                    onOpenForm={onOpenForm}
                    selected={selectedProject === project.id}
                    account={account}
                  />
                );
              })}
            </>
            {/* )} */}
          </div>
        </>
      ) : (
        <>
          <div className="project-list__title">
            <h2 className="flex flex-column">
              Active Pods
              <span className="Material-KitOverline">
                {(auth.me?.role !== 'SUPERADMIN' || accountId) && account?.name}
              </span>
            </h2>

            {auth.me?.role === 'SUPERADMIN' ||
            account?.admins?.some((admin) => admin.id === auth.me?.id) ||
            auth.me?.role === 'PODMASTER' ? (
              <div className="project-list__title-buttons">
                <Button
                  onClick={() => {
                    // onOpenForm(null);
                    currentOpenForm === 'add' ? onUnsetForm() : onOpenForm('add');
                  }}
                  className="project-list__append"
                  size="small"
                >
                  <div className="project-list__image">
                    <img src="/icons/add.svg" alt="Add Project" />
                  </div>
                </Button>
                {auth.me?.role === 'SUPERADMIN' && (
                  <Link className="header-link" to="/archive">
                    <Button size="large" className="project-list__archive">
                      <div className="project-list__image">
                        <img src="/icons/archive.svg" alt="Archive" />
                      </div>
                      <span>Archive</span>
                    </Button>
                  </Link>
                )}
              </div>
            ) : null}
          </div>
          <div className="no-projects Material-KitH5">
            No active pods. Create a new project to get started or ask your Pod master to add you to
            a project.
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  project: state.project,
  auth: state.auth,
  account: state.account,
});

export default compose(
  requireAuth,
  connect(mapStateToProps, { getProjects, getAccount }),
)(ActiveProjectList);
