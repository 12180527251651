import React from 'react';
import { compose } from 'redux';

import requireAuth from '../../hoc/requireAuth';
import { connect } from 'react-redux';
import Button from '../Buttons/Button';
import { deleteUser } from '../../store/actions/userActions';
import './User.css';
const User = ({ user, isFormVisible, onOpenForm, auth, deleteUser, getUsers, selected, accountId }) => {
  const avatarUrl = user?.avatar?.startsWith('http')
    ? user.avatar
    : `${process.env.REACT_APP_SERVER_BASE_URL}${user?.avatar}`;

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      deleteUser(user.id);
      if (accountId) {
        // console.log('refreshing user list for account id', accountId);
        getUsers(accountId);
      } else {
        getUsers();
      }
    }
  };

  return (
    <div className={`list-users__account account-users ${selected ? ' selected' : ''}`}>
      <div className="account-users__info">
        <div className="account-users__image">
          <img src={avatarUrl || '/images/default-avatar.png'} alt="avatar" />
        </div>
        <div className="account-users__info-text flex flex-column">
          <h5 className="Material-KitH6">
            {user.name}
            <span className="user-role">
              {user.role === 'SUPERADMIN'
                ? ' (Super Admin)'
                : user.role === 'PODMASTER'
                ? ' (Pod Master)'
                : ''}
            </span>
          </h5>
          <span className="Material-KitCaption">{user.email}</span>
          <span className="Material-KitOverline">{user.account?.name}</span>
        </div>
      </div>
      <div className="account-users__actions">
        <Button
          className={`project-actions__button ${
            (auth.me?.role === 'SUPERADMIN' || auth.me?.role === 'PODMASTER') &&
            user.role !== 'SUPERADMIN'
              ? ''
              : 'alone'
          }`}
          onClick={() => onOpenForm(user.id)}
          size="small"
        >
          <div className="button-view__image button__image">
            <img src="/icons/edit.svg" alt="Edit" />
          </div>
        </Button>
        {auth.me?.role === 'SUPERADMIN' && user.role !== 'SUPERADMIN' && (
          <Button className="button-delete" size="small" onClick={handleDelete}>
            <div className="button-delete__image button__image">
              <img src="/icons/delete.svg" alt="Delete" />
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  users: state.users,
});

export default compose(requireAuth, connect(mapStateToProps, { deleteUser }))(User);
