import { month, currentYear, currentMonth, daysInMonth } from './index';
import moment from 'moment-timezone';

export const normalizeYearsData = (data, selectedDate) => {
  let computedDaysInMonth = daysInMonth;
  let computedYear = currentYear;
  let computedMonth = currentMonth;

  if (selectedDate) {
    computedDaysInMonth = selectDaysInMonth(selectedDate) || daysInMonth;
    computedYear = selectYear(selectedDate) || currentYear;
    computedMonth = selectMonth(selectedDate) || currentMonth;
  }

  const noDataMonth = {
    [computedMonth]: Array(computedDaysInMonth).fill('no-data'),
  };

  if (!data || !Array.isArray(data) || data.length === 0) {
    return [{ years: { [computedYear]: noDataMonth } }];
  }
  const newYearsData = data.map((item) => {
    if (typeof item === 'object' && item !== null) {
      const years = item.years || {};
      const yearData = years[computedYear] || {};
      const monthData = yearData[computedMonth] || Array(computedDaysInMonth).fill('no-data');

      return {
        ...item,
        years: {
          ...years,
          [computedYear]: {
            ...yearData,
            [computedMonth]: monthData,
          },
        },
      };
    }

    return {
      years: { [computedYear]: noDataMonth },
    };
  });

  return newYearsData.length > 0 ? newYearsData : [{ years: { [computedYear]: noDataMonth } }];
};

export const normalizeYearsDataWithMaster = (data, selectedDate) => {
  let computedDaysInMonth = daysInMonth;
  let computedYear = currentYear;
  let computedMonth = currentMonth;

  if (selectedDate) {
    computedDaysInMonth = selectDaysInMonth(selectedDate) || daysInMonth;
    computedYear = selectYear(selectedDate) || currentYear;
    computedMonth = selectMonth(selectedDate) || currentMonth;
  }

  const noDataMonth = {
    [computedMonth]: Array(computedDaysInMonth).fill([]),
  };

  if (!data || !Array.isArray(data) || data.length === 0) {
    return [{ years: { [computedYear]: noDataMonth } }, { master: { items: [], subItems: [] } }];
  }
  const newYearsData = data.map((item) => {
    if (typeof item === 'object' && item !== null && item.years) {
      const years = item.years || {};
      const yearData = years[computedYear] || {};
      const monthData = yearData[computedMonth] || Array(computedDaysInMonth).fill([]);

      return {
        ...item,
        years: {
          ...years,
          [computedYear]: {
            ...yearData,
            [computedMonth]: monthData,
          },
        },
      };
    } else {
      return item;
    }

    // return {
    //   years: { [computedYear]: noDataMonth },
    //   master: { items: [], subItems: [] }
    // };
  });

  return newYearsData.length > 0
    ? newYearsData
    : [{ years: { [computedYear]: noDataMonth } }, { master: { items: [], subItems: [] } }];
};

export const normalizeKPIData = (data, selectedDate) => {
  let computedDaysInMonth = daysInMonth;
  let computedYear = currentYear;
  let computedMonth = currentMonth;

  let updatedData = data;

  if (selectedDate) {
    computedDaysInMonth = selectDaysInMonth(selectedDate) || daysInMonth;
    computedYear = selectYear(selectedDate) || currentYear;
    computedMonth = selectMonth(selectedDate) || currentMonth;
  }

  const noDataMonthArray = Array(computedDaysInMonth)
    .fill()
    .map(() => {
      return Array(5)
        .fill()
        .map((_, i) => [
          {
            id: `_${i}`,
            Label: '',
            Actual: '',
            Planned: '',
            Reason: '',
          },
          {
            id: `_${i + 1}`,
            Label: '',
            Actual: '',
            Planned: '',
            Reason: '',
          },
        ]);
    });

  if (updatedData[1]) {
    if (!updatedData[1]?.years[computedYear]) {
      updatedData[1].years[computedYear] = [{ [computedMonth]: noDataMonthArray }];
    }
    if (!updatedData[1].years[computedYear][computedMonth]) {
      updatedData[1].years[computedYear][computedMonth] = noDataMonthArray;
    }
  }
  return updatedData;
};

export const normalizeWeeklyDataWithMaster = (data, selectedDate) => {
  // let computedDaysInMonth = daysInMonth;
  // let computedYear = currentYear;
  // let computedMonth = currentMonth;

  // if (selectedDate) {
  //   computedDaysInMonth = selectDaysInMonth(selectedDate) || daysInMonth;
  //   computedYear = selectYear(selectedDate) || currentYear;
  //   computedMonth = selectMonth(selectedDate) || currentMonth;
  // }

  // const noDataMonth = {
  //   [computedMonth]: Array(computedDaysInMonth).fill([]),
  // };

  // if (!data || !Array.isArray(data) || data.length === 0) {
  //   return [{ years: { [computedYear]: noDataMonth } }, { master: { items: [], subItems: [] } }];
  // }
  // const newYearsData = data.map((item) => {
  //   if (typeof item === 'object' && item !== null && item.years) {
  //     const years = item.years || {};
  //     const yearData = years[computedYear] || {};
  //     const monthData = yearData[computedMonth] || Array(computedDaysInMonth).fill([]);

  //     return {
  //       ...item,
  //       years: {
  //         ...years,
  //         [computedYear]: {
  //           ...yearData,
  //           [computedMonth]: monthData,
  //         },
  //       }
  //     };
  //   } else {
  //     return item;
  //   }

  // return {
  //   years: { [computedYear]: noDataMonth },
  //   master: { items: [], subItems: [] }
  // };
  // });

  // return newYearsData.length > 0 ? newYearsData : [{ years: { [computedYear]: noDataMonth } }, { master: { items: [], subItems: [] } }];
  if (data?.length > 0) {
    return data;
  } else {
    return [{ weeks: [] }, { master: { items: [], subItems: [] } }];
  }
};

// export const normalizeYearsData = (data, daysInMonthProp, currentYearProp, currentMonthProp) => {
//   const computedDaysInMonth = daysInMonthProp || daysInMonth;
//   const computedYear = currentYearProp || currentYear;
//   const computedMonth = currentMonthProp || currentMonth;
//   // console.log('Computed days in month:', computedDaysInMonth);
//   // console.log('Computed month:', computedMonth);
//   // console.log('Computed year:', computedYear);
//   // console.log('days in month:', daysInMonth);
//   // console.log('current month:', currentMonth);
//   // console.log('current year:', currentYear);

//   const noDataMonth = {
//     [computedMonth]: Array(computedDaysInMonth).fill('no-data')
//   };

//   if (data) {
//     // console.log('Normalizing data:', data);
//     const newYearsData = data.map((arrayItem) => {
//       if (typeof arrayItem === 'object') {
//         if (arrayItem.years) {
//           if (arrayItem.years[computedYear]) {
//             if (arrayItem.years[computedYear][computedMonth]) {
//               return arrayItem;
//             } else {
//               let historicMonths = arrayItem.years[computedYear];
//               return { ...arrayItem, years: { ...arrayItem.years, [computedYear]: { ...historicMonths, [computedMonth]: Array(daysInMonth).fill('no-data') } } }
//             }
//           } else {
//             let historicYears = arrayItem.years;
//             return { ...arrayItem, years: { ...historicYears, [computedYear]: noDataMonth } }
//           }
//         } else {
//           return { years: { [computedYear]: noDataMonth } }
//         }
//       } else {
//         return { years: { [computedYear]: noDataMonth } };
//       }
//     })
//     if (newYearsData.length > 0) {
//       // console.log('Normalized data 1:', newYearsData);
//       return newYearsData;
//     } else {
//       // console.log('Normalized data 2:', [{ years: { [computedYear]: noDataMonth } }]);
//       return [{ years: { [computedYear]: noDataMonth } }]
//     }
//   } else {
//     // console.log('Normalized data 3:', [{ years: { [computedYear]: noDataMonth } }]);
//     return [{ years: { [computedYear]: noDataMonth } }]
//   }

//   // return newYearsData.length > 0 ? newYearsData : [{ years: { [computedYear]: noDataMonth } }];
// }
export const formatDate = (date) => {
  // const formattedDate = date.split('T')[0];
  // return formattedDate;
  return moment(date).format('YYYY-MM-DD');
};

export const subtractDays = (date, days) => {
  return moment(date).subtract(days, 'days').format('YYYY-MM-DD');
};

export const selectDate = (selectedDate) => new Date(selectedDate);

export const selectYear = (selectedDate) => selectDate(selectedDate).getFullYear().toString();

export const selectMonth = (selectedDate) => month[selectDate(selectedDate).getMonth()];

export const selectLastDayOfMonth = (selectedDate) =>
  new Date(selectDate(selectedDate).getFullYear(), selectDate(selectedDate).getMonth() + 1, 0);

export const selectDaysInMonth = (selectedDate) => selectLastDayOfMonth(selectedDate).getDate();

export const formatedStatus = (input) => {
  if (!input) return '';

  const formatted = input
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  return formatted;
};

export const normalizeYearsDesc = (data, selectedDate) => {};

export const filterDepartmentsArray = (departments, authMeId) => {
  let externalDepartments =
    departments?.filter((pDept) => {
      if (pDept?.external) {
        return pDept;
      }
    }) || [];
  let filteredExternalDepartments =
    externalDepartments.filter((eDept) => {
      if (eDept?.users.some((user) => user.id === authMeId)) {
        return eDept;
      }
    }) || [];

  return (
    departments?.filter((department) => {
      if (department) {
        return (
          !department.viewOnly &&
          (filteredExternalDepartments.length > 0
            ? filteredExternalDepartments.some((dept) => dept.id === department.id)
            : true)
        );
      }
    }) || []
  );
};

export const deconstructTask = (task) => {
  return {
    title: task.title,
    description: task.description,
    complete: task.complete,
    assignedTo: task.assignedTo.hasOwnProperty('id') ? task.assignedTo.id : null,
    department: task.department.id,
    tag: task.tag.hasOwnProperty('id') ? task.tag.id : null,
    project: task.project.id,
    approved: task.approved,
    delay: task.delay,
    dueDate: task.dueDate,
  };
};

export const deconstructValues = (values) => {
  return {
    title: values.title,
    description: values.description,
    complete: values.complete,
    assignedTo: values.assignedTo === '' ? null : values.assignedTo,
    department: values.department,
    project: values.project,
    tag: values.tag === '' ? null : values.tag,
    approved: values.approved,
    delay: values.delay,
    dueDate: values.dueDate,
  };
};

export const desconstructAudit = (audit) => {
  let cleanAudit = [];
  audit.forEach((a) => {
    cleanAudit.push({
      user: a.user.id,
      action: a.action,
      date: a.date,
      old: a.old,
      new: a.new,
    });
  });
  return cleanAudit;
};

export const filterTagsArray = (tags, projectId) => {
  return tags.filter((tag) => tag.project === projectId);
};
