import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import { compose } from 'redux';
import requireAuth from '../../hoc/requireAuth';
import * as Yup from 'yup';
import Button from '../Buttons/Button';
import { getAccounts, editAccount, deleteAdminForAccount, getAccount } from '../../store/actions/accountActions';
import { getAccountDepartments } from '../../store/actions/departmentActions';
import '../UserForm/UserForm.css';
import styles from '../../pages/Profile/DragAndDrop.module.css';
import Loader from '../Loader/Loader';


const AccountFormEdit = ({ 
  accountID, 
  auth, 
  account: { accounts, account, error: accountsError, isLoading: accountsIsLoading },
  department: { departments, error: departmentsError, isLoading: departmentsIsLoading },
  editAccount, 
  onOpenForm, 
  getAccounts,
  getAccount,
  onUnsetForm, 
  onReturnBack,
  getAccountDepartments,
  setDepartment,
  currentOpenForm,
  deleteAdminForAccount,
  definedAccount
}) => {
  const [error, setError] = useState(null);
  // const accountSelected = accounts.find((account) => account.id === accountID);  
  const [accountSelected, setAccountSelected] = useState(definedAccount || accounts.find((account) => account.id === accountID));
  const formik = useFormik({
    initialValues: {
      name: accountSelected?.name || '',
      description: accountSelected?.description || '',
      phone: accountSelected?.phone || '',
      emailPrimary: accountSelected?.emailPrimary || '',
      emailSecondary: accountSelected?.emailSecondary || '',      
      avatar: null,
      currentAvatar: accountSelected?.avatar || null,
      kpiFeature: accountSelected?.kpiFeature || false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Must be 2 characters at minimum')
        .max(50, 'Must be 50 characters or less')
        .required('Name is required'),
      description: Yup.string()
        .min(2, 'Must be 2 characters at minimum')
        .max(1000, 'Must be 1000 characters or less'),
      phone: Yup.string(),
      emailPrimary: Yup.string().email('Invalid email address').required('Email is required'),
      emailSecondary: Yup.string().email('Invalid email address'),
      avatar: Yup.mixed().nullable(),
      kpiFeature: Yup.boolean(),
    }),
    onSubmit: (values) => {
      const submitValues = { ...values };

      if (!submitValues.avatar) {
        delete submitValues.avatar;
      }
      delete submitValues.currentAvatar;
      

      editAccount(accountID, submitValues);        
      
      formik.resetForm();
      setError(null);
      onOpenForm(accountID);
      getAccounts();        
      
    },
  });

  useEffect(() => {
    formik.resetForm();
    if (accountID) {
      getAccount(accountID, true);
      getAccountDepartments(accountID);        
    }
  }, [accountID]);

  useEffect(() => {
    setAccountSelected(account);    
    
    formik.setValues({
      name: account.name || '',
      description: account.description || '',
      phone: account.phone || '',
      emailPrimary: account.emailPrimary || '',
      emailSecondary: account.emailSecondary || '',        
      avatar: null,
      currentAvatar: account.avatar || null,
      kpiFeature: account.kpiFeature || false,
    });
    
  }, [account]);

  const handleCancel = () => {
    formik.resetForm();
    setError(null);
    onUnsetForm();
  };

  const handleRemoveAdmin = (adminId) => {
    deleteAdminForAccount(accountID, adminId);
  };

   // DRAG and DROP for CLOUDINARY
   const [dragOver, setDragOver] = useState(false);
   const [droppedFiles, setDroppedFiles] = useState([]);
   const [loadingStates, setLoadingStates] = useState([]);
   const abortControllerRef = useRef(new AbortController());
 
   const handleDragOver = (e) => {
     e.preventDefault();
     setDragOver(true);
   };
 
   const handleDragLeave = () => {
     setDragOver(false);
   };
 
   const handleDrop = async (e) => {
     e.preventDefault();
     setDragOver(false);
 
     let files = [];
     if (e.currentTarget?.files) {
       files = Array.from(e.currentTarget.files);
     } else if (e.dataTransfer?.files) {
       files = Array.from(e.dataTransfer.files);
     }
     // console.log("Current Target: ", e.currentTarget?.files);
     // console.log("Data Transfer: ", e.dataTransfer?.files);
     // const files = [];
     // const files = Array.from(e.dataTransfer?.files) || e.currentTarget.files;
     setDroppedFiles([]);
     
     if (files && files.length > 1) {
       alert('Upload only one image file.');
     } else if (files && files.length === 1) {
       setLoadingStates(new Array(files.length).fill(true));
       
       abortControllerRef.current.abort();
       abortControllerRef.current = new AbortController();
       
       
       const url = `https://api.cloudinary.com/v1_1/${
         process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
       }/upload`;
       for (let file of files) {
         try {
           const formData = new FormData();
           const fields = {
             file,
             upload_preset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
             tags: ['user-profile', `accountid-${accountID}`],
             multiple: false,
             resource_type: 'image',            
           };
 
           Object.entries(fields).forEach(([key, value]) => {
             formData.append(key, value);
           });
 
           const options = {
             method: 'POST',
             body: formData,
             signal: abortControllerRef.current.signal,
           };
           const response = await fetch(url, options);
           if (!response.ok) {
             throw new Error('Failed to execute file upload via the Fetch API');
           }
           const json = await response.json();
           const secureUrl = json.secure_url;
           // const previewUrl = secureUrl;
           const previewUrl = secureUrl.replace(
             '/upload/',
             '/upload/c_fill,h_200,w_200/f_auto/'
           );
 
           setDroppedFiles((prevFiles) => [...prevFiles, { file, previewUrl }]);
           formik.setFieldValue('avatar', previewUrl);
           formik.setFieldValue('currentAvatar', previewUrl);
           setLoadingStates((prevStates) =>
             prevStates.map((state, index) =>
               file === files[index] ? false : state
             )
           );
         } catch (error) {
           if (error.name !== 'AbortError') {
             console.error(error);
           }
           setLoadingStates((prevStates) =>
             prevStates.map((state, index) =>
               file === files[index] ? false : state
             )
           );
         }
       }
     }
   };
 
   useEffect(() => {
     return () => {
       abortControllerRef.current.abort();
     };
   }, []);
   // END DRAG and DROP for CLOUDINARY    
  
  

  return (
    <div className="user-form user__column profile-page">
      <div className="users__heading">
        <h2>Edit Account <span className="Material-KitOverline">ID: {accountID}</span></h2>
        {!definedAccount && <Button size="small" className="action-btn bg primary-blue-bg" onClick={onReturnBack}>
          <div className="btn__image">
            <img src="/icons/arrow_back.svg" alt="previous" />
          </div>
        </Button>}
        
      </div>
      {accountsIsLoading ? (
        <Loader />
      ) : (
        <div className="list">
          <form onSubmit={formik.handleSubmit}>
            <h4 className="label Material-KitButton">ACCOUNT DETAILS</h4>

            <div className="avatar-section">
              {formik.values.currentAvatar && (
                <img
                  src={
                    formik.values.currentAvatar.startsWith('http')
                      ? formik.values.currentAvatar
                      : `${process.env.REACT_APP_SERVER_BASE_URL}${formik.values.currentAvatar}`
                  }
                  alt="Current avatar"
                  className="current-avatar"
                  style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: '16px' }}
                />
              )}
              <input
                type="file"
                accept="image/png,image/jpeg,image/jpg"
                id="avatar"
                name="avatar"
                className=" invisible Material-KitSB2"
                placeholder="Avatar"
                onChange={(event) => {
                  // formik.setFieldValue('avatar', event.currentTarget.files[0]);
                  handleDrop(event);
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.avatar && formik.errors.avatar && (
                <p className="error">{formik.errors.avatar}</p>
              )}

              <div
                className={`${styles.dragArea} ${dragOver ? `${styles.dragOver}` : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                
                {loadingStates.some((loading) => loading) ? (
                  // <span className="drag-and-drop-image loading loading-spinner text-primary"></span>                      
                  <img src="/icons/asterisk.svg" className="drag-and-drop-image loading loading-spinner text-primary" alt="Uploading Image" />
                ) :
                  <img src="/icons/upgrade.svg" className="drag-and-drop-image" alt="Upload Image" />
                }
                <div className="drag-and-drop-text-container">
                  <div className="drag-and-drop-text">Drop an image here</div>
                  <div className="or-text">or</div>
                  <label htmlFor="avatar" className="select-file-link">select file</label>
                </div>
              </div>
            </div>

            <input
              name="name"
              className="Material-KitSB2"
              placeholder="Full Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            {formik.touched.name && formik.errors.name && <p className="error">{formik.errors.name}</p>}

            <textarea
              name="description"
              rows="4"
              className="Material-KitSB2"
              placeholder="Description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
            />
            {formik.touched.description && formik.errors.description && (
              <p className="error">{formik.errors.description}</p>
            )}

            <input
              name="phone"
              className="Material-KitSB2"
              placeholder="Phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
            />
            {formik.touched.phone && formik.errors.phone && (
              <p className="error">{formik.errors.phone}</p>
            )}

            <input
              name="emailPrimary"
              className="Material-KitSB2"
              placeholder="Email Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emailPrimary}
            />
            {formik.touched.emailPrimary && formik.errors.emailPrimary && (
              <p className="error">{formik.errors.emailPrimary}</p>
            )}

            <input
              name="emailSecondary"
              className="Material-KitSB2"
              placeholder="Secondary Email Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emailSecondary}
            />  
            {formik.touched.emailSecondary && formik.errors.emailSecondary && (
              <p className="error">{formik.errors.emailSecondary}</p>
            )}

            {auth.me?.role === 'SUPERADMIN' && <div className="full-width">
              <h3 className="label Material-KitCaption">Tier View</h3>
              <select
                name="kpiFeature"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.kpiFeature}          
                disabled={false}
                className="Material-KitSB2 form-select"
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </select>
              {formik.touched.kpiFeature && formik.errors.kpiFeature ? (
                <p className="error">{formik.errors.kpiFeature}</p>
              ) : null}
            </div>}

            <div className="form-section form-section-gap">
              {/* Admins */}
              <div className="department-users-list">
                {/* <h3 className="label Material-KitButton">
                  Pod Masters (
                  <Link to={`/project/podmasters/edit/${project.id}`} className="header-link">
                    Edit
                  </Link>
                  )
                </h3> */}
                <div className="project-edit__row">
                  <h3 className="label Material-KitButton">
                    ADMINS
                    {/* <Link to={`/project/departments/edit/${project.id}`} className="header-link">
                      Edit
                    </Link> */}
                  </h3>
                  <Button
                    size="mini"
                    className="action-btn primary-blue-bg bg"
                    onClick={() => {
                      currentOpenForm ? onOpenForm('admin/new') : onUnsetForm();
                    }}
                  >
                    <div className="department-btn__image">
                      <img src="/icons/add.svg" alt="add" />
                    </div>
                  </Button>
                </div>
                <div className="user-list-container">
                  {accountSelected.admins?.length === 0 ? (
                    <p className="Material-KitCaption">No admins selected</p>
                  ) : (
                    <>
                      {accountSelected.admins?.map((admin, index) => {
                        return (
                          <div key={index} className="user-list-item">
                            <div className="user-info-container">
                              <div className="user-title">
                                <div className="user-info__image">
                                  <img src={admin.avatar.startsWith('http') ? admin.avatar : `${process.env.REACT_APP_SERVER_BASE_URL}${admin.avatar}`} alt="user" />
                                </div>
                                <div className="user-info user-username Material-KitCaption">
                                  {admin.name}
                                </div>
                              </div>
                              <div>
                                <div className="user-info user-email Material-KitCaption">
                                  {admin.email}
                                </div>
                              </div>
                              {(auth.me?.role === 'SUPERADMIN') && (
                                <Button
                                  size="mini"
                                  className="action-btn"
                                  onClick={() => {
                                    handleRemoveAdmin(admin.id);
                                  }}
                                >
                                  <div className="department-btn__image">
                                    <img src="/icons/delete.svg" alt="delete" />
                                  </div>
                                </Button>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>


            
            <div className="form-section form-section-gap">
              {/* Departments */}
              <div className="department-list">
                <div className="project-edit__row">
                  <h3 className="label Material-KitButton">
                    DEPARTMENTS
                    {/* <Link to={`/project/departments/edit/${project.id}`} className="header-link">
                      Edit
                    </Link> */}
                  </h3>
                  <Button
                    size="mini"
                    className="action-btn primary-blue-bg bg"
                    type="button"
                    onClick={() => {
                      currentOpenForm ? onOpenForm('department/new') : onUnsetForm();
                    }}
                  >
                    <div className="department-btn__image">
                      <img src="/icons/add.svg" alt="add" />
                    </div>
                  </Button>
                </div>
                {departmentsError && (
                  <div className="error-center Material-KitCaption">{departmentsError}</div>
                )}
                {/* <Link
                  to={`/project/departments/edit/${project.id}`}
                  className="department-list-link"
                > */}
                <div className="department-list-link">
                  <div className="department-list-container">
                    {departmentsIsLoading ? (
                      <Loader />
                    ) : (
                      <>
                        {departments.length === 0 ? (
                          <p className="Material-KitCaption">No departments created</p>
                        ) : (
                          <>
                            {departments.map((department, index) => {
                              return (
                                <div className="department-list-item" key={index}>
                                  <div className="department-row">
                                    <div className="department-row-left">
                                      <div
                                        className="department-color-box "
                                        style={{ backgroundColor: department.color }}
                                      ></div>
                                      <div className="department-name Material-KitButton">
                                        {department.name}
                                      </div>
                                    </div>
                                    <div className="department-tags">
                                    <div className="user-count Material-KitSB2">
                                      <span>{department.users.length}</span>
                                      <div className="user-count__icon">
                                        <img src="/icons/groups_2.svg" alt="users" />
                                      </div>
                                      {department.viewOnly ? (
                                        <div className="user-count__icon">
                                          <img src="/icons/visibility.svg" alt="read only" />
                                        </div>
                                      ) : null}
                                      {department.external ? (
                                        <div className="user-count__icon">
                                          <img src="/icons/shield_person.svg" alt="external" />
                                        </div>
                                      ) : null}
                                    </div>
                                    
                                  </div>
                                  </div>
                                  

                                  <Button
                                    size="mini"
                                    className="action-btn "
                                    onClick={() => {
                                      currentOpenForm
                                        ? onOpenForm('department/edit')
                                        : onUnsetForm();
                                      setDepartment(department.id);
                                    }}
                                  >
                                    <div className="department-btn__image">
                                      <img src="/icons/edit.svg" alt="edit" />
                                    </div>
                                  </Button>
                                </div>
                              );
                            })}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* </Link> */}
              </div>
            </div>


            <div className="user__buttons">
              <Button
                type="submit"
                className="save-user submit-btn Material-KitButton bg"
                disabled={formik.isSubmitting}
              >
                Save
              </Button>
              {!definedAccount && <Button
                type="button"
                onClick={handleCancel}
                className="cancel-btn submit-btn Material-KitButton bg"
                disabled={formik.isSubmitting}
              >
                Cancel
              </Button>}
              
            </div>

            {formik.errors.submit && <p className="error" id="error-submit">{formik.errors.submit}</p>}
            {error && <p className="error" id="error-general">{error}</p>}
            {account.error && <p className="error" id="error-account">{account.error}</p>}
          </form>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  account: state.account,
  department: state.department,
});

export default compose(requireAuth, connect(mapStateToProps, { getAccounts, getAccount, editAccount, getAccountDepartments, deleteAdminForAccount }))(AccountFormEdit);
