import React, { useState, useEffect } from 'react';

import '../styles.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useSelector } from 'react-redux';

const GoldenMoments = ({
  rows,
  title,
  deleteRow,
  editRow,
  showCelebration,
  departments,
  departmentView,
}) => {
  const projects = useSelector((state) => state.project.project);
  const tags = useSelector((state) => state.tag.tags);
  const [activeDepartmentDropdown, setActiveDepartmentDropdown] = useState(null);
  const [activeTagDropdown, setActiveTagDropdown] = useState(null);
  const [expandedDepartments, setExpandedDepartments] = useState({});
  const [expandedTags, setExpandedTags] = useState({});
  const isProjectWithTags = tags?.some((tag) => tag.project.id === projects.id);

  const orderedMoments = rows
    .map((row, originalIndex) => ({ ...row, originalIndex }))
    .slice()
    .sort((a, b) => {
      return moment(b.date).diff(moment(a.date));
    });

  const handleMoreDepartmentsClick = (index, e) => {
    e.stopPropagation();
    setActiveDepartmentDropdown(activeDepartmentDropdown === index ? null : index);
  };

  const handleMoreTagsClick = (index, e) => {
    e.stopPropagation();
    setActiveTagDropdown(activeTagDropdown === index ? null : index);
  };

  const handleDepartmentClick = (idx, deptId) => {
    // Removed the departments.length check to allow expanding for any number of departments
    setExpandedDepartments((prev) => ({
      ...prev,
      [idx]: {
        ...prev[idx],
        [deptId]: !prev[idx]?.[deptId],
      },
    }));
  };

  const handleTagClick = (idx, tagId) => {
    setExpandedTags((prev) => ({
      ...prev,
      [idx]: {
        ...prev[idx],
        [tagId]: !prev[idx]?.[tagId],
      },
    }));
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      setActiveDepartmentDropdown(null);
      setActiveTagDropdown(null);
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const Celebration = () => {
    useEffect(() => {
      const celebrationSounds = [
        '/sounds/celebration.mp3',
        '/sounds/applause.mp3',
        '/sounds/cheer.mp3',
        '/sounds/applause2.mp3',
      ];
      const randomSound = celebrationSounds[Math.floor(Math.random() * celebrationSounds.length)];
      const audio = new Audio(randomSound);
      audio.volume = 0.5;
      audio.play().catch((e) => console.log('Audio playback failed:', e));

      return () => {
        audio.pause();
        audio.currentTime = 0;
      };
    }, []);

    const colors = [
      '#ffd700', // Gold
      '#ff0000', // Red
      '#00ff00', // Green
      '#0000ff', // Blue
      '#ff69b4', // Hot Pink
      '#ff8c00', // Dark Orange
      '#9932cc', // Dark Orchid
      '#00ffff', // Cyan
    ];

    const shapes = [
      'polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)', // Star
      'circle(50% at 50% 50%)', // Circle
      'polygon(50% 0%, 100% 100%, 0% 100%)', // Triangle
      'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)', // Diamond
    ];

    return (
      <div className="celebration-container">
        <div className="celebration-text">🍾 Congratulations! 🍾</div>
        {[...Array(150)].map((_, i) => {
          const isLeft = Math.random() > 0.5;
          return (
            <div
              key={i}
              className={`confetti ${isLeft ? 'confetti-left' : 'confetti-right'}`}
              style={{
                left: `${Math.random() * 100}%`,
                top: `-${Math.random() * 20}%`,
                backgroundColor: colors[Math.floor(Math.random() * colors.length)],
                clipPath: shapes[Math.floor(Math.random() * shapes.length)],
                animationDelay: `${Math.random() * 2}s`,
                transform: `rotate(${Math.random() * 360}deg)`,
                width: `${8 + Math.random() * 8}px`,
                height: `${8 + Math.random() * 8}px`,
                opacity: 0.7 + Math.random() * 0.3,
              }}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div className="huddle-board-content__golden-moments golden-moments">
      {showCelebration && <Celebration />}
      <h2 className="huddle-board-content__title title text-center">{title}</h2>

      <div className="table-wrapper">
        <table className="form-golden-moments__table table">
          <thead>
            <tr className={isProjectWithTags ? 'tags-exist' : ''}>
              <th className="gm_name">Name(s)</th>
              <th className="gm_description">Description</th>
              <th className="gm_department">{departmentView ? 'Project' : 'Department'}</th>
              {isProjectWithTags && <th className="gm_tags">Tags</th>}
              <th className="gm_when">When</th>
              <th className="gm_actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            {orderedMoments.map((row, idx) => {
              // Get only the departments associated with this golden moment
              const rowDepartments = departments.filter((dept) =>
                row.department?.includes(dept.id),
              );

              // const visibleDepts = rowDepartments.slice(0, 3);
              // const remainingDepts = rowDepartments.slice(3);

              const visibleDepts = rowDepartments;
              const remainingDepts = [];

              // Get tags associated with this golden moment
              const rowTags = tags.filter((tag) => row.tags?.includes(tag.id));

              // const visibleTags = rowTags.slice(0, 3);
              // const remainingTags = rowTags.slice(3);
              const visibleTags = rowTags;
              const remainingTags = [];

              return (
                <tr key={idx} className={isProjectWithTags ? 'tags-exist' : ''}>
                  <td className="gm_name">{row?.name}</td>
                  <td className="gm_description">{row?.description}</td>
                  <td className="gm_department">
                    {visibleDepts.map((el) => (
                      <span
                        className={`label Material-KitB2 ${
                          expandedDepartments[idx]?.[el.id] ? 'expanded' : ''
                        }`}
                        key={el.id}
                        style={{ backgroundColor: el.color || '#D9E4FF' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDepartmentClick(idx, el.id);
                        }}
                      >
                        {expandedDepartments[idx]?.[el.id]
                          ? departmentView
                            ? el.title
                            : el.name
                          : departmentView
                          ? el.title.charAt(0)
                          : el.name.charAt(0)}
                      </span>
                    ))}
                    {remainingDepts.length > 0 && (
                      <>
                        <span
                          className="more-departments"
                          onClick={(e) => handleMoreDepartmentsClick(idx, e)}
                        >
                          +{remainingDepts.length}
                        </span>
                        <div
                          className={`departments-dropdown ${
                            activeDepartmentDropdown === idx ? 'show' : ''
                          }`}
                        >
                          {remainingDepts.map((el) => (
                            <span
                              className="label Material-KitB2"
                              key={el.id}
                              style={{ backgroundColor: el.color || '#D9E4FF' }}
                            >
                              {departmentView ? el.title : el.name}
                            </span>
                          ))}
                        </div>
                      </>
                    )}
                  </td>
                  {isProjectWithTags && (
                    <td className="gm_tags">
                      {visibleTags.map((el) => (
                        <span
                          className={`label Material-KitB2 ${
                            expandedTags[idx]?.[el.id] ? 'expanded' : ''
                          }`}
                          key={el.id}
                          style={{ backgroundColor: el.color || '#D9E4FF' }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleTagClick(idx, el.id);
                          }}
                        >
                          {expandedTags[idx]?.[el.id]
                            ? departmentView
                              ? el.title
                              : el.name
                            : departmentView
                            ? el.title.charAt(0)
                            : el.name.charAt(0)}
                        </span>
                      ))}
                      {remainingTags.length > 0 && (
                        <>
                          <span
                            className="more-departments"
                            onClick={(e) => handleMoreTagsClick(idx, e)}
                          >
                            +{remainingTags.length}
                          </span>
                          <div
                            className={`departments-dropdown ${
                              activeTagDropdown === idx ? 'show' : ''
                            }`}
                          >
                            {remainingTags.map((el) => (
                              <span
                                className="label Material-KitB2"
                                key={el.id}
                                style={{ backgroundColor: el.color || '#D9E4FF' }}
                              >
                                {departmentView ? el.title : el.name}
                              </span>
                            ))}
                          </div>
                        </>
                      )}
                    </td>
                  )}
                  <td className="gm_when">{row?.date}</td>

                  <td className="gm_actions">
                    <span className="actions">
                      <FontAwesomeIcon
                        icon={faPen}
                        className="edit-btn"
                        onClick={() => editRow(row.originalIndex)}
                      />
                      <FontAwesomeIcon
                        icon={faTrash}
                        className=" delete-btn"
                        onClick={() => {
                          deleteRow(row.originalIndex);
                        }}
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GoldenMoments;
