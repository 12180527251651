import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Link, useParams } from 'react-router-dom';
import Button from '../../components/Buttons/Button';
// import { getUsers } from '../../store/actions/usersActions';
import { getAccounts, getAccount } from '../../store/actions/accountActions';
import Layout from '../../layout/Layout';
import Loader from '../../components/Loader/Loader';
import requireAuth from '../../hoc/requireAuth';
import { connect } from 'react-redux';
// import UserForm from '../../components/UserForm/UserForm';
// import User from '../../components/User/User';
// import UserFormEdit from '../../components/UserForm/UserFormEdit';
import AccountForm from '../../components/AccountComponents/AccountForm';
import Account from '../../components/AccountComponents/Account';
import AccountFormEdit from '../../components/AccountComponents/AccountFormEdit';
import DepartmentEdit from '../../components/DashboardComponents/DepartmentEdit/DepartmentEdit';
import AccountDepartmentsEdit from '../../components/AccountComponents/AccountDepartmentsEdit/AccountDepartmentsEdit';
import AccountAdminsEdit from '../../components/AccountComponents/AccountAdminsEdit/AccountAdminsEdit';
import ProjectDepartmentsUsersEdit from '../../components/DashboardComponents/ProjectDepartmentsUsersEdit/ProjectDepartmentsUsersEdit';
import { archiveAccount } from '../../store/actions/accountActions';
// Use '../Users/styles.css' for styling
// import './styles.css';

const AccountPage = ({
  getAccounts,
  getAccount,
  account: { accounts, account, isLoading, error },
  auth,
}) => {
  const [openHistory, setOpenHistory] = useState([]);
  const [currentOpenForm, setCurrentOpenForm] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isFormEditVisible, setIsFormEditVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  // const [searchTerm, setSearchTerm] = useState('');

  // const [account, setAccount] = useState(null);
  const [department, setDepartment] = useState(null);
  const [admin, setAdmin] = useState(null);
  // let { accountId } = useParams();

  useEffect(() => {
    getAccount(auth.me?.account);
    setCurrentOpenForm('edit');
  }, []);

  useEffect(() => {
    // setCurrentOpenForm(null);
    // if (account.id) {
    //   // setAccount(account.id);
    // setCurrentOpenForm('edit');
    // }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [account]);

  const isAdmin = account?.admins?.some((admin) => admin.id === auth.me?.id);

  // const handleOpenForm = (accountID) => {
  //   if (accountID) {
  //     // If clicking same user that's already open, close the form
  //     if (accountID === account) {
  //       setIsFormEditVisible(false);
  //       setIsFormVisible(false);
  //       setOpenedAccount(null);
  //     } else {
  //       // If clicking different user, show edit form for that user
  //       setIsFormEditVisible(true);
  //       setIsFormVisible(false);
  //       setOpenedAccount(accountID);
  //     }
  //   } else {
  //     // Handle create new user form toggle
  //     setIsFormEditVisible(false);
  //     setIsFormVisible(!isFormVisible);
  //     setOpenedAccount(null);
  //   }
  // };

  const handleOpenForm = (type = null, accountSet = null) => {
    if (currentOpenForm && !openHistory.includes(currentOpenForm)) {
      setOpenHistory((prev) => [...prev, currentOpenForm]);
    }
    setCurrentOpenForm(type);
    // account && setAccount(accountSet);
  };

  const handleUnsetForm = (accountSet = null) => {
    setOpenHistory([]);
    // setAccount(null);
    // setOpenedAccount(null);
    setCurrentOpenForm(null);
  };

  const handleReturnBack = () => {
    if (openHistory.length > 0) {
      const lastForm = openHistory[openHistory.length - 1];
      setOpenHistory((prev) => prev.slice(0, -1));
      setCurrentOpenForm(lastForm);
    } else {
      // setAccount(null);
      // setOpenedAccount(null);
      setCurrentOpenForm(null);
    }
  };

  const renderForm = () => {
    switch (currentOpenForm) {
      case 'department/new':
        return (
          <AccountDepartmentsEdit
            accountID={account.id}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            setDepartment={setDepartment}
            onOpenForm={handleOpenForm}
          />
        );
      case 'department/edit':
        return (
          <DepartmentEdit
            accountID={account.id}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            departmentID={department}
            onOpenForm={handleOpenForm}
            currentOpenForm={currentOpenForm}
          />
        );
      case 'member/new':
        return (
          <ProjectDepartmentsUsersEdit
            departmentID={department}
            accountID={account.id}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
          />
        );
      case 'admin/new':
        return (
          <AccountAdminsEdit
            accountID={account.id}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            onOpenForm={handleOpenForm}
          />
        );
      default:
        return (
          <AccountFormEdit
            accountID={account.id}
            onOpenForm={handleOpenForm}
            onUnsetForm={handleUnsetForm}
            currentOpenForm={currentOpenForm}
            onReturnBack={handleReturnBack}
            setDepartment={setDepartment}
            unresettable={true}
            definedAccount={account}
          />
        );
    }
  };
  if (!auth.isAuthenticated) return null;

  return (
    <Layout>
      <div className="users__container adaptive-container accounts-page">
        <div className="users">
          <div className={`users__row ${isFormVisible || isFormEditVisible ? 'opened' : ''}`}>
            {(!isMobile || !isFormVisible || !isFormEditVisible) && (
              <div className={`users__column users__menu ${isMobile ? 'only-child' : ''}`}>
                <div className="users__heading">
                  {/* <h4 className="users__title Material-KitH5">Users</h4> */}
                  <h2 className="users__title">{account.name} Account</h2>
                </div>

                <div className="list account-page">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="account-page__info">
                        <div className="account-page__image">
                          {account.avatar ? (
                            <img
                              src={
                                account?.avatar?.startsWith('http')
                                  ? account.avatar.replace('h_200,w_200', 'h_400,w_400')
                                  : `${process.env.REACT_APP_SERVER_BASE_URL}${account?.avatar}` ||
                                    '/images/default-avatar.png'
                              }
                              alt="avatar"
                            />
                          ) : (
                            <div className="account-initial">{account.name?.charAt(0)}</div>
                          )}
                        </div>
                        <div className="account-page__info-text">
                          <div className="Material-KitB2">{account.description}</div>
                          <div className="Material-KitB2">
                            <a href={`tel:${account.phone}`}>{account.phone}</a>
                          </div>
                          <div className="Material-KitB2">
                            <a href={`mailto:${account.emailPrimary}`}>{account.emailPrimary}</a>
                          </div>
                          <div className="Material-KitB2">
                            <a href={`mailto:${account.emailSecondary}`}>
                              {account.emailSecondary}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="account-page__actions">
                        <Link to={`/account/${account.id}`}>
                          <Button className={`button-view`} size="big">
                            <div className="btn-account-page-tooltip">Pods</div>
                            <div className="button-view__image button__image">
                              <img src="/icons/home.svg" alt="Pods" />
                            </div>
                          </Button>
                        </Link>
                        <Link to={`/departments/account/${account.id}`}>
                          <Button className={`button-view`} size="big">
                            <div className="btn-account-page-tooltip">Department Pods</div>
                            <div className="button-view__image button__image">
                              <img src="/icons/work.svg" alt="Pods" />
                            </div>
                          </Button>
                        </Link>
                        <Link to={`/users/account/${account.id}`}>
                          <Button className={`button-view`} size="big">
                            <div className="btn-account-page-tooltip">Users</div>
                            <div className="button-view__image button__image">
                              <img src="/icons/groups_2.svg" alt="Users" />
                            </div>
                          </Button>
                        </Link>
                        <Button
                          size="big"
                          className={`button-view`}
                          disabled={currentOpenForm === 'admin/new'}
                          onClick={() => {
                            currentOpenForm ? handleOpenForm('admin/new') : handleUnsetForm();
                          }}
                        >
                          <div className="btn-account-page-tooltip">Admins</div>
                          <div className="button-view__image button__image">
                            <img src="/icons/shield_person.svg" alt="Admins" />
                          </div>
                        </Button>
                        <Button
                          size="big"
                          className={`button-view`}
                          disabled={currentOpenForm === 'department/new'}
                          onClick={() => {
                            currentOpenForm ? handleOpenForm('department/new') : handleUnsetForm();
                          }}
                        >
                          <div className="btn-account-page-tooltip">Departments</div>
                          <div className="button-view__image button__image">
                            <img src="/icons/diversity_3.svg" alt="Departments" />
                          </div>
                        </Button>
                        {isAdmin && (
                          <Link to={`/archive`}>
                            <Button size="big" className={`button-view`}>
                              <div className="btn-account-page-tooltip">Archive</div>
                              <div className="button-view__image button__image">
                                <img src="/icons/archive.svg" alt="archive" />
                              </div>
                            </Button>
                          </Link>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {renderForm()}
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  account: state.account,
  auth: state.auth,
});

export default compose(
  requireAuth,
  connect(mapStateToProps, { getAccounts, getAccount }),
)(AccountPage);
