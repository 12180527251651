// import React, { useEffect, useState } from 'react';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import HuddleBoardContent from './HuddleBoardContent';
import './styles.css';
import HuddleBoardForm from './Form/HuddleBoardForm';
import Loader from '../Loader/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faChartGantt,
  faTriangleExclamation,
  faClover,
  faHeartPulse,
  faMagnifyingGlassDollar,
  faCalendarCheck,
  faCalendarWeek,
  faMedal,
  faTableList,
  faClipboardUser,
  faMessage,
} from '@fortawesome/free-solid-svg-icons';

const HuddleBoards = ({
  huddleBoards,
  auth,
  tags,
  toggleHuddleBoardVisibility,
  projectId,
  // updateHuddleBoard,
  error,
  isLoading,
  project,
  departmentView,
  filteredTags,
}) => {
  const [isHuddleBoardVisible, setIsHuddleBoardVisible] = useState({});

  const toggleHuddleBoardCenterVisibility = (index) => {
    document.documentElement.style.removeProperty('overflow');
    // -1 means close all huddle boards when overlay is clicked
    if (index === -1) {
      let trueIndex = -1;
      for (const key in isHuddleBoardVisible) {
        if (isHuddleBoardVisible.hasOwnProperty(key)) {
          trueIndex = key;
        }
      }
      setIsHuddleBoardVisible((prevState) => ({
        ...prevState,
        [trueIndex]: false,
      }));
    } else {
      setIsHuddleBoardVisible((prevState) => ({
        // ...prevState,
        [index]: !prevState[index],
      }));
    }
  };

  const isAnyHuddleBoardActive = () => {
    // console.log(isHuddleBoardVisible);
    for (const key in isHuddleBoardVisible) {
      if (isHuddleBoardVisible.hasOwnProperty(key)) {
        if (isHuddleBoardVisible[key] === true) return true;
      }
    }
    return false;
  };

  const getFilteredHuddleBoard = (title, tag) => {
    
    if (!tag) {
      // console.log(huddleBoards?.filter((el) => el.title === title));
      return huddleBoards?.filter((el) => title === 'Non-Tip Actions' ? ((el.title === title || el.title === 'Actions') && (el.project === projectId || el.department === projectId)) : (el.title === title && (el.project === projectId || el.department === projectId)));
    } else {
      return huddleBoards?.filter((el) => title === 'Non-Tip Actions' ? (el.title === title || el.title === 'Actions') && el.tag === tag.id && (el.project === projectId || el.department === projectId) : el.title === title && el.tag === tag.id && (el.project === projectId || el.department === projectId));
    }
  };

  // if (isLoading) {
  //   return <Loader />;
  // }

  if (error) {
    return <span>Error occured {error.message}</span>;
  }

  // Huddle Boards Mapping:
  // 0: I (Project Info) - Client & Patient Centricity
  // 1: PP (Project Plan) - Project Plan Key Milestones
  // 2: R (Risks)
  // 3: O (Opportunities)
  // 4: TP (Team Pulse Check)
  // 15: SR (Sponsor Review) - Sponsor Report Out Project Health
  // 5: WP (Weekly Priorities)
  // 6: GM (Recognition) - Golden Moments
  // 7: DA (Daily Agenda)
  // 8: NTA (Actions) - Non-TIP Actions
  // 9: A (Attendance)
  // 10: S (Safety)
  // 11: Q (Quality)
  // 12: C (Cost)
  // 13: P (People)
  // 14: D (Delivery)
  // 16: T (Transmissions)
  //

  return (
    <>
      {/* Background Overlay */}
      {isAnyHuddleBoardActive() && (
        <div
          className="huddle-boards-background-overlay"
          onClick={() => toggleHuddleBoardCenterVisibility(-1)}
        ></div>
      )}

      {/* Center Container */}
      <div
        className={`huddle-boards-center-container ${
          isAnyHuddleBoardActive() ? 'huddle-board-is-active' : ''
        }`}
      >
        {/* Client & Patient Centiricity */}
        <div
          className={`huddle-board-center-left ${
            isHuddleBoardVisible[0] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(0);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Client & Patient Centiricity').length > 0 ? (
            <HuddleBoardContent
              title="Client & Patient Centiricity"
              huddleBoard={getFilteredHuddleBoard('Client & Patient Centiricity')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[0]}
              simple={true}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Client & Patient Centiricity"
              simple={true}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Project Plan Key Milestones */}
        <div
          className={`huddle-board-center-left-links ${
            isHuddleBoardVisible[1] ? 'huddle-board-center-active' : ''
          }`}
          style={{ bottom: '264px', position: 'fixed' }}
        >
          {/* Project Plan Key Milestones */}
          {project.projectPlan?.split(',').map((link, index) => (
            <a href={link} target="_blank" rel="noreferrer" key={index}>
              <div
                // className={`huddle-board btn-huddle-board ${isHuddleBoardVisible[1] ? 'huddle-board-active' : ''}`}
                className={`huddle-board btn-huddle-board`}
              >
                <FontAwesomeIcon icon={faChartGantt} className="huddle-board-project-view-icon" />
                <span className="btn-huddle-board-tooltip-left">{tags?.[index]?.name}</span>
              </div>
              {project.projectPlan?.split(',').length > 1 && (
                <div
                  className="huddle-board-project-view-icon-tag"
                  style={{
                    backgroundColor: tags?.[index] ? tags?.[index]?.color : 'var(--brand-color)',
                  }}
                >
                  {tags?.[index] ? tags?.[index]?.name.split('')[0] : ''}
                </div>
              )}
            </a>
          ))}
        </div>

        {/* Risks */}
        <div
          className={`huddle-board-center-left-links ${
            isHuddleBoardVisible[2] ? 'huddle-board-center-active' : ''
          }`}
          style={{ bottom: '128px', position: 'fixed' }}
        >
          {/* Risks */}
          {project.risks?.split(',').map((link, index) => (
            <a href={link} target="_blank" rel="noreferrer" key={index}>
              <div
                // className={`huddle-board btn-huddle-board ${isHuddleBoardVisible[2] ? 'huddle-board-active' : ''}`}
                className={`huddle-board btn-huddle-board`}
              >
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="huddle-board-project-view-icon"
                />
                <span className="btn-huddle-board-tooltip-left">{tags?.[index]?.name}</span>
              </div>
              {project.risks?.split(',').length > 1 && (
                <div
                  className="huddle-board-project-view-icon-tag"
                  style={{
                    backgroundColor: tags?.[index] ? tags?.[index]?.color : 'var(--brand-color)',
                  }}
                >
                  {tags?.[index] ? tags?.[index]?.name.split('')[0] : ''}
                </div>
              )}
            </a>
          ))}
        </div>

        {/* Opportunities */}
        <div
          className={`huddle-board-center-left-links ${
            isHuddleBoardVisible[3] ? 'huddle-board-center-active' : ''
          }`}
          style={{ bottom: '108px', position: 'fixed' }}
        >
          {/* Opportunities */}
          {project.opportunities?.split(',').map((link, index) => (
            <a href={link} target="_blank" rel="noreferrer" key={index}>
              <div
                // className={`huddle-board btn-huddle-board ${isHuddleBoardVisible[3] ? 'huddle-board-active' : ''}`}
                className={`huddle-board btn-huddle-board`}
              >
                <FontAwesomeIcon icon={faClover} className="huddle-board-project-view-icon" />
                <span className="btn-huddle-board-tooltip-left">{tags?.[index]?.name}</span>
              </div>
              {project.opportunities?.split(',').length > 1 && (
                <div
                  className="huddle-board-project-view-icon-tag"
                  style={{
                    backgroundColor: tags?.[index] ? tags?.[index]?.color : 'var(--brand-color)',
                  }}
                >
                  {tags?.[index] ? tags?.[index]?.name.split('')[0] : ''}
                </div>
              )}
            </a>
          ))}
        </div>

        {/* Team Pulse Check */}
        <div
          className={`huddle-board-center-left ${
            isHuddleBoardVisible[4] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(4);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Team Pulse Check').length > 0 ? (
            <HuddleBoardContent
              labels={[5, 4, 3, 2, 1]}
              title="Team Pulse Check"
              huddleBoard={getFilteredHuddleBoard('Team Pulse Check')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[4]}
              simple={true}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Team Pulse Check"
              simple={true}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Sponsor Report Out Project Health */}
        <div
          className={`huddle-board-center-left ${
            isHuddleBoardVisible[15] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(15);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Sponsor Report').length > 0 ? (
            <HuddleBoardContent
              labels={['Green', 'Yellow', 'Red']}
              title="Sponsor Report"
              huddleBoard={getFilteredHuddleBoard('Sponsor Report')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[15]}
              simple={true}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Sponsor Report"
              simple={true}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Weekly Priorities */}
        <div
          className={`huddle-board-center-right ${
            isHuddleBoardVisible[5] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(5);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Weekly Priorities').length > 0 ? (
            <HuddleBoardContent
              labels={['Complete', 'Green', 'Yellow', 'Red']}
              title="Weekly Priorities"
              huddleBoard={getFilteredHuddleBoard('Weekly Priorities')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[5]}
              simple={true}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Weekly Priorities"
              simple={true}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Golden Moments */}
        <div
          className={`huddle-board-center-right ${
            isHuddleBoardVisible[6] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(6);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Golden Moments').length > 0 ? (
            <HuddleBoardContent
              title="Golden Moments"
              huddleBoard={getFilteredHuddleBoard('Golden Moments')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[6]}
              simple={true}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Golden Moments"
              simple={true}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Daily Agenda */}
        <div
          className={`huddle-board-center-right ${
            isHuddleBoardVisible[7] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(7);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Daily Agenda').length > 0 ? (
            <HuddleBoardContent
              labels={['Completed', 'Not Completed']}
              title="Daily Agenda"
              huddleBoard={getFilteredHuddleBoard('Daily Agenda')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[7]}
              simple={true}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Daily Agenda"
              simple={true}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Non-Tip Actions */}
        <div
          className={`huddle-board-center-right ${
            isHuddleBoardVisible[8] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(8);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {(getFilteredHuddleBoard('Non-Tip Actions').length > 0) ? (
            <HuddleBoardContent
              title="Actions"
              huddleBoard={getFilteredHuddleBoard('Non-Tip Actions')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[8]}
              simple={true}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Actions"
              simple={true}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Attendance */}
        <div
          className={`huddle-board-center-right ${
            isHuddleBoardVisible[9] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(9);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Attendance').length > 0 ? (
            <HuddleBoardContent
              labels={['Present', 'Absent']}
              title="Attendance"
              huddleBoard={getFilteredHuddleBoard('Attendance')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[9]}
              simple={true}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Attendance"
              simple={true}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Safety */}
        <div
          className={`huddle-board-center-right ${
            isHuddleBoardVisible[10] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(10);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Safety').length > 0 ? (
            <HuddleBoardContent
              labels={['Good', 'Bad']}
              title="Safety"
              huddleBoard={getFilteredHuddleBoard('Safety')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[10]}
              simple={false}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Safety"
              simple={false}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Quality */}
        <div
          className={`huddle-board-center-right ${
            isHuddleBoardVisible[11] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(11);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Quality').length > 0 ? (
            <HuddleBoardContent
              labels={['Good', 'Bad']}
              title="Quality"
              huddleBoard={getFilteredHuddleBoard('Quality')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[11]}
              simple={false}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Quality"
              simple={false}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Cost */}
        <div
          className={`huddle-board-center-right ${
            isHuddleBoardVisible[12] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(12);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Cost').length > 0 ? (
            <HuddleBoardContent
              labels={['Good', 'Bad']}
              title="Cost"
              huddleBoard={getFilteredHuddleBoard('Cost')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[12]}
              simple={false}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Cost"
              simple={false}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Delivery */}
        <div
          className={`huddle-board-center-right ${
            isHuddleBoardVisible[14] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(14);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('Delivery').length > 0 ? (
            <HuddleBoardContent
              labels={['Good', 'Bad']}
              title="Delivery"
              huddleBoard={getFilteredHuddleBoard('Delivery')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[14]}
              simple={false}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Delivery"
              simple={false}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* People */}
        <div
          className={`huddle-board-center-right ${
            isHuddleBoardVisible[13] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(13);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          {getFilteredHuddleBoard('People').length > 0 ? (
            <HuddleBoardContent
              labels={['Good', 'Bad']}
              title="People"
              // updateHuddleBoard={updateHuddleBoard}
              huddleBoard={getFilteredHuddleBoard('People')}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[13]}
              simple={false}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="People"
              simple={false}
              tags={tags}
              departmentView={departmentView}
            />
          )}
        </div>

        {/* Transmissions */}
        <div
          className={`huddle-board-center-left ${
            isHuddleBoardVisible[16] ? 'huddle-board-center-active' : ''
          }`}
        >
          <button
            onClick={() => {
              toggleHuddleBoardCenterVisibility(16);
            }}
            className="back-button"
          >
            &#8594;
          </button>
          { (!departmentView && getFilteredHuddleBoard('Transmissions').find((el) => el.project?.toString() === projectId)) ? (
            <HuddleBoardContent
              title="Transmissions"
              huddleBoard={getFilteredHuddleBoard('Transmissions').filter((el) => el.project?.toString() === projectId)}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[16]}
              simple={true}
              projectId={projectId}
            />
          ) : (departmentView && getFilteredHuddleBoard('Transmissions').find((el) => el.department?.toString() === projectId)) ? (
            <HuddleBoardContent
              title="Transmissions"
              huddleBoard={getFilteredHuddleBoard('Transmissions').filter((el) => el.department?.toString() === projectId)}
              tags={filteredTags}
              departmentView={departmentView}
              isHuddleBoardVisible={isHuddleBoardVisible[16]}
              simple={true}
              projectId={projectId}
            />
          ) : (
            <HuddleBoardForm
              projectId={projectId}
              title="Transmissions"
              simple={true}
              tags={[]} // this is on purpose so that transmission are never split by tag
              departmentView={departmentView}
            />
          )}
        </div>
      </div>

      {/* Left Container */}
      <div
        className={`huddle-boards-left-container ${
          isAnyHuddleBoardActive() ? 'huddle-board-is-active' : ''
        }`}
      >
        <div className="huddle-board-column">
          <div className="huddle-boards-group-title">Project Overview</div>
          <div
            className={`huddle-board btn-huddle-board ${
              isHuddleBoardVisible[0] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(0)}
          >
            <FontAwesomeIcon icon={faCircleInfo} className="huddle-board-project-view-icon" />
            <span className="btn-huddle-board-tooltip-left">Client & Patient Centricity</span>
          </div>
          
          

          <div
            className={`huddle-board btn-huddle-board ${
              isHuddleBoardVisible[4] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(4)}
          >
            <FontAwesomeIcon icon={faHeartPulse} className="huddle-board-project-view-icon" />
            <span className="btn-huddle-board-tooltip-left">Team Pulse Check</span>
          </div>
          
          <div
            className={`huddle-board btn-huddle-board ${
              isHuddleBoardVisible[15] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(15)}
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlassDollar}
              className="huddle-board-project-view-icon"
            />
            <span className="btn-huddle-board-tooltip-left">Sponsor Report</span>
          </div>

        </div>

        <div className="huddle-board-column">
          
          {project.kpiView &&<div
            className={`huddle-board btn-huddle-board ${
              isHuddleBoardVisible[16] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(16)}
          >
            <FontAwesomeIcon
              icon={faMessage}
              className="huddle-board-project-view-icon"
            />
            <span className="btn-huddle-board-tooltip-left">Transmissions</span>
            {getFilteredHuddleBoard('Transmissions').find((el) => !departmentView ? el.project?.toString() === projectId : el.department?.toString() === projectId)?.data?.filter((el) => el.status?.includes('new'))?.length > 0 &&
              <div className="huddle-board-notification-badge"></div>
            }
          </div>}

          {/* Project Plan, Risks, Opportunities */}
          {project.taskColoredBy === 0 ? (
            <>
              {project.projectPlan && (
                <a href={project.projectPlan} target="_blank" rel="noreferrer">
                  <div
                    className={`huddle-board btn-huddle-board ${
                      isHuddleBoardVisible[1] ? 'huddle-board-active' : ''
                    }`}
                  >
                    <FontAwesomeIcon icon={faChartGantt} className="huddle-board-project-view-icon" />
                    <span className="btn-huddle-board-tooltip-left">Project Plan Key Milestones</span>
                  </div>
                </a>
              )}
              {project.risks && (
                <a href={project.risks} target="_blank" rel="noreferrer">
                  <div
                    className={`huddle-board btn-huddle-board ${
                      isHuddleBoardVisible[2] ? 'huddle-board-active' : ''
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="huddle-board-project-view-icon"
                    />
                    <span className="btn-huddle-board-tooltip-left">Risks</span>
                  </div>
                </a>
              )}
              {project.opportunities && (
                <a href={project.opportunities} target="_blank" rel="noreferrer">
                  <div
                    className={`huddle-board btn-huddle-board ${
                      isHuddleBoardVisible[3] ? 'huddle-board-active' : ''
                    }`}
                  >
                    <FontAwesomeIcon icon={faClover} className="huddle-board-project-view-icon" />
                    <span className="btn-huddle-board-tooltip-left">Opportunities</span>
                  </div>
                </a>
              )}
            </>
          ) : (
            <>
              {project.projectPlan && (
                <>
                  {/* <a href={link} target="_blank" rel="noreferrer" key={index}>
                    <div
                      className={`huddle-board btn-huddle-board ${isHuddleBoardVisible[1] ? 'huddle-board-active' : ''}`}                                          
                    >
                      <FontAwesomeIcon icon={faChartGantt} className="huddle-board-project-view-icon" />
                      <span className="btn-huddle-board-tooltip-left">Project Plan Key Milestones</span>
                    </div>
                    {project.projectPlan?.split(',').length > 1 && <div 
                      className="huddle-board-project-view-icon-tag"
                      style={{backgroundColor: tags[index] ? tags[index].color : 'var(--brand-color)'}}
                    >
                      {tags[index] ? tags[index].name.split('')[0] : ''}
                    </div>}
                  </a> */}
                  <div
                    className={`huddle-board btn-huddle-board ${
                      isHuddleBoardVisible[1] ? 'huddle-board-active' : ''
                    }`}
                    onClick={() => toggleHuddleBoardCenterVisibility(1)}
                  >
                    <FontAwesomeIcon icon={faChartGantt} className="huddle-board-project-view-icon" />
                    <span className="btn-huddle-board-tooltip-left">Project Plan Key Milestones</span>
                  </div>
                </>
              )}

              {project.risks && (
                <>
                  {/* <a href={link} target="_blank" rel="noreferrer" key={index}>
                    <div
                      className={`huddle-board btn-huddle-board ${isHuddleBoardVisible[2] ? 'huddle-board-active' : ''}`}                            
                  >
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="huddle-board-project-view-icon"
                    />
                    <span className="btn-huddle-board-tooltip-left">Risks</span>
                  </div>
                  {project.risks?.split(',').length > 1 && <div 
                    className="huddle-board-project-view-icon-tag"
                    style={{backgroundColor: tags[index] ? tags[index].color : 'var(--brand-color)'}}
                  >
                    {tags[index] ? tags[index].name.split('')[0] : ''}
                    </div>}
                  </a> */}
                  <div
                    className={`huddle-board btn-huddle-board ${
                      isHuddleBoardVisible[2] ? 'huddle-board-active' : ''
                    }`}
                    onClick={() => toggleHuddleBoardCenterVisibility(2)}
                  >
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      className="huddle-board-project-view-icon"
                    />
                    <span className="btn-huddle-board-tooltip-left">Risks</span>
                  </div>
                </>
              )}

              {project.opportunities && (
                <>
                  {/* <a href={link} target="_blank" rel="noreferrer" key={index}>
                    <div
                      className={`huddle-board btn-huddle-board ${isHuddleBoardVisible[3] ? 'huddle-board-active' : ''}`}                         
                    >
                      <FontAwesomeIcon icon={faClover} className="huddle-board-project-view-icon" />
                      <span className="btn-huddle-board-tooltip-left">Opportunities</span>
                    </div>
                    {project.opportunities?.split(',').length > 1 && <div 
                      className="huddle-board-project-view-icon-tag"
                      style={{backgroundColor: tags[index] ? tags[index].color : 'var(--brand-color)'}}
                    >
                      {tags[index] ? tags[index].name.split('')[0] : ''}
                    </div>}
                  </a> */}
                  <div
                    className={`huddle-board btn-huddle-board ${
                      isHuddleBoardVisible[3] ? 'huddle-board-active' : ''
                    }`}
                    onClick={() => toggleHuddleBoardCenterVisibility(3)}
                  >
                    <FontAwesomeIcon icon={faClover} className="huddle-board-project-view-icon" />
                    <span className="btn-huddle-board-tooltip-left">Opportunities</span>
                  </div>
                </>
              )}
            </>
          )}

        </div>

      </div>

      {/* Right Container */}
      <div
        className={`huddle-boards-right-container ${
          isAnyHuddleBoardActive() ? 'huddle-board-is-active' : ''
        }`}
      >
        {/* Daily/Weekly */}
        <div className="huddle-board-column">
          <div className="huddle-boards-group-title">Daily/Weekly</div>

          {/* Weekly Priorities */}
          <div
            className={`huddle-board btn-huddle-board ${
              isHuddleBoardVisible[5] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(5)}
          >
            <FontAwesomeIcon icon={faCalendarWeek} className="huddle-board-project-view-icon" />
            <span className="btn-huddle-board-tooltip-right">Weekly Priorities</span>
          </div>

          {/* Daily Agenda */}
          <div
            className={`huddle-board btn-huddle-board ${
              isHuddleBoardVisible[7] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(7)}
          >
            <FontAwesomeIcon icon={faCalendarCheck} className="huddle-board-project-view-icon" />
            <span className="btn-huddle-board-tooltip-right">Daily Agenda</span>
          </div>

          {/* Non-Tip Actions */}
          <div
            className={`huddle-board btn-huddle-board ${
              isHuddleBoardVisible[8] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(8)}
          >
            <FontAwesomeIcon icon={faTableList} className="huddle-board-project-view-icon" />
            <span className="btn-huddle-board-tooltip-right">Actions</span>
          </div>

          {/* Golden Moments */}
          <div
            className={`huddle-board btn-huddle-board ${
              isHuddleBoardVisible[6] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(6)}
          >
            <FontAwesomeIcon icon={faMedal} className="huddle-board-project-view-icon" />
            <span className="btn-huddle-board-tooltip-right">Golden Moments</span>
          </div>

          {/* Attendance */}
          <div
            className={`huddle-board btn-huddle-board ${
              isHuddleBoardVisible[9] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(9)}
          >
            <FontAwesomeIcon icon={faClipboardUser} className="huddle-board-project-view-icon" />
            <span className="btn-huddle-board-tooltip-right">Attendance</span>
          </div>
        </div>

        {/* KPIs/SQCPD */}
        <div className="huddle-board-column">
          <div className="huddle-boards-group-title">KPIs</div>

          {/* Safety */}
          <div
            className={`huddle-board btn-huddle-board SQCPD-board ${
              isHuddleBoardVisible[10] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(10)}
          >
            <img src={`${process.env.REACT_APP_BASE_URL}/icons/s.svg`} alt="Safety" />
            <span className="btn-huddle-board-tooltip-right">Safety</span>
          </div>

          {/* Quality */}
          <div
            className={`huddle-board btn-huddle-board SQCPD-board ${
              isHuddleBoardVisible[11] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(11)}
          >
            <img src={`${process.env.REACT_APP_BASE_URL}/icons/q.svg`} alt="Quality" />
            <span className="btn-huddle-board-tooltip-right">Quality</span>
          </div>

          {/* Cost */}
          <div
            className={`huddle-board btn-huddle-board SQCPD-board ${
              isHuddleBoardVisible[12] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(12)}
          >
            <img src={`${process.env.REACT_APP_BASE_URL}/icons/c.svg`} alt="Cost" />
            <span className="btn-huddle-board-tooltip-right">Cost</span>
          </div>

          {/* Delivery */}
          <div
            className={`huddle-board btn-huddle-board SQCPD-board ${
              isHuddleBoardVisible[14] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(14)}
          >
            <img src={`${process.env.REACT_APP_BASE_URL}/icons/d.svg`} alt="Delivery" />
            <span className="btn-huddle-board-tooltip-right">Delivery</span>
          </div>

          {/* People */}
          <div
            className={`huddle-board btn-huddle-board SQCPD-board ${
              isHuddleBoardVisible[13] ? 'huddle-board-active' : ''
            }`}
            onClick={() => toggleHuddleBoardCenterVisibility(13)}
          >
            <img src={`${process.env.REACT_APP_BASE_URL}/icons/p.svg`} alt="People" />
            <span className="btn-huddle-board-tooltip-right">People</span>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(HuddleBoards);
