import React, { useCallback, useMemo, useSelector } from 'react';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash, faCheck } from '@fortawesome/free-solid-svg-icons';

// import moment from 'moment-timezone';

const Attendance = ({ rows, master, isGlobalEdit, editRow, markCompleteRow, departments, departmentView }) => {
    // console.log("departments", departments);
    // console.log("rows", rows);
    // console.log("master", master);
  
  const indexedDepartments = departments.map((department, index) => (
    { 
      departmentID: department.id, 
      departmentName: departmentView ? department.title : department.name, 
      names: rows.filter(d => d.departmentID === department.id).length > 0 ? 
        rows.filter(d => d.departmentID === department.id)[0].names : 
        master.items.filter(d => d.departmentID === department.id).length > 0 ? 
            master.items.filter(d => d.departmentID === department.id)[0].names : 
            '',
    //   originalIndex: index, 
    //   originalIndex: rows.some((row, i) => {
    //     if (row.departmentID === department.id) { return i; }
    //   }),
      originalIndex: rows.findIndex(row => row.departmentID === department.id) >= 0 ? rows.findIndex(row => row.departmentID === department.id) : 
      master.items.findIndex(row => row.departmentID === department.id) >= 0 ? master.items.findIndex(row => row.departmentID === department.id) : index,
      status: rows.filter(d => d.departmentID === department.id).length > 0 ? 
        rows.filter(d => d.departmentID === department.id)[0].status : 
        master.items.filter(d => d.departmentID === department.id).length > 0 ? 
            master.items.filter(d => d.departmentID === department.id)[0].status : 
            ''
    }
  ));
//   console.log("indexedDepartments", indexedDepartments);
  
//   let displayedRows = indexedDepartments;
  // if (rows.length > 0) {
  //   displayedRows = rows.map((row, originalIndex) => ({ ...row, originalIndex }));
  // } else if (master.items.length > 0) {
  //   displayedRows = master.items.map((row, originalIndex) => ({ ...row, originalIndex }));
  // } else {
  //   displayedRows = indexedDepartments;
  // }
//   console.log("data", data);
//   console.log("master", master);

  return (
    <>
        <div className="huddle-board-content__attendance attendance">
            {/* <h2 className="huddle-board-content__title title text-center">{title}</h2> */}

            <div className="table-wrapper">
                <table className="form-attendance__table table">
                    <thead>
                        <tr>
                            <th className="atnd_actions">&nbsp;</th>
                            <th className="atnd_names">Name(s)</th>
                            <th className="atnd_department">{departmentView ? 'Project' : 'Department'}</th>
                            <th className="atnd_status">Attnd.</th>
                        </tr>
                    </thead>
                    <tbody>
                        {indexedDepartments.map((row, idx) => {
                            return (
                                <tr key={idx} className={`${row?.status?.includes('present') ? 'present' : 'absent'}`}>
                                    <td className="atnd_actions">
                                        <span className="actions">
                                            <FontAwesomeIcon
                                                icon={faPen}
                                                className="edit-btn"
                                                onClick={() => editRow(row.originalIndex, row.departmentID, row.departmentName)}
                                            />                                            
                                        </span>
                                    </td>
                                    <td className="atnd_names">{row?.names}</td>
                                    <td className="atnd_department">{row?.departmentName}</td>
                                    <td className="atnd_status">
                                        <FontAwesomeIcon
                                            icon={faCheck}
                                            className={`complete-btn ${row?.status?.includes('present') ? 'present' : 'absent'}`}
                                            onClick={() => markCompleteRow(row.departmentID)}
                                        />
                                    </td>                        
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <div className="global-edit-indicator">
                {isGlobalEdit ? 
                    (                    
                        <div className="global-edit-indicator__true">* Editing <b>Global Template</b> of Attendance</div>                    
                    ) : (
                        <div className="global-edit-indicator__false">* Editing <b>Selected</b> Day's Attendance</div>
                    )
                }
            </div>
        </div>
    </>
  );

};

export default Attendance;
