import React, { useCallback, useMemo } from 'react';
import { normalizeYearsData } from '../../../constants/functions';
// import { currentDate, currentYear, currentMonth, daysInMonth } from '../../../constants/index';
import {
  selectDate,
  selectYear,
  selectMonth,
  selectDaysInMonth,
} from '../../../constants/functions';
const LetterS = ({
  huddleBoard,
  selectedDate,
  setSelectedDate,
  isLayout = true,
  outsideData = { data: [], startDate: null, endDate: null },
}) => {
  // const yesterdayIndex = useMemo(() => currentDate.getDate() - 1, []);
  // currentDate, currentYear, currentMonth, daysInMonth
  const data = useMemo(() => {
    return normalizeYearsData(huddleBoard?.data, selectedDate)[0].years[selectYear(selectedDate)][
      selectMonth(selectedDate)
    ];
  }, [huddleBoard, selectedDate]);
  const isDateInRange = useCallback(
    (dayIndex) => {
      if (!outsideData.startDate || !outsideData.endDate) return true;

      const currentDate = new Date(selectYear(selectedDate), selectedDate.getMonth(), dayIndex + 1);

      return currentDate >= outsideData.startDate && currentDate <= outsideData.endDate;
    },
    [outsideData.startDate, outsideData.endDate, selectedDate],
  );
  const renderRow = useCallback(
    (startIdx, endIdx, emptyCount = 0) => (
      <div className="row">
        {data.slice(startIdx, endIdx).map((el, idx) => {
          const globalIdx = startIdx + idx;

          return (
            <React.Fragment key={globalIdx}>
              {globalIdx === 7 && (
                <>
                  <div className="row-indicator empty"></div>
                </>
              )}
              {globalIdx === 25 && (
                <>
                  <div className="row-indicator empty"></div>
                </>
              )}
              {globalIdx === 21 && (
                <>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                  <div className="row-indicator empty"></div>
                </>
              )}
              {/* Regular div */}
              <div
                className={` indicator ${
                  isLayout
                    ? globalIdx === Number(selectDate(selectedDate).getDate()) - 1
                      ? 'today'
                      : ''
                    : 'row-indicator'
                }`}
                onClick={() =>
                  isLayout
                    ? setSelectedDate(new Date(selectDate(selectedDate).setDate(globalIdx + 1)))
                    : null
                }
                style={{
                  ...((el?.status === 'true' || el === 'true')
                    ? { backgroundColor: '#5BF98A' }
                    : (el?.status === 'false' || el === 'false')
                    ? { backgroundColor: '#FC9797' }
                    : {}),
                  ...(outsideData.data.length > 0 && isDateInRange(globalIdx)
                    ? outsideData.data[globalIdx] === true
                      ? { backgroundColor: '#5BF98A' }
                      : outsideData.data[globalIdx] === false
                      ? { backgroundColor: '#FC9797' }
                      : {}
                    : {}),
                }}
              >
                {globalIdx + 1}
              </div>
            </React.Fragment>
          );
        })}
        {Array.from({ length: emptyCount }).map((_, idx) => (
          <div
            key={`empty-${idx}`}
            className="row-indicator indicator"
            
          />
        ))}
      </div>
    ),
    [data, selectedDate, outsideData],
  );
  return (
    <>
      {renderRow(0, 5)}
      {renderRow(5, 9)}
      {renderRow(9, 11)}
      {renderRow(11, 16)}
      {renderRow(16, 21)}
      {renderRow(21, 23)}
      {renderRow(23, 27)}
      {selectDaysInMonth(selectedDate) === 28 && renderRow(27, 28, 4)}
      {selectDaysInMonth(selectedDate) === 29 && renderRow(27, 29, 3)}
      {selectDaysInMonth(selectedDate) === 30 && renderRow(27, 30, 2)}
      {selectDaysInMonth(selectedDate) === 31 && renderRow(27, 31, 1)}
    </>
  );
};
export default LetterS;
