import React, { useEffect, useState } from 'react';
import { compose } from 'redux';
import { Link, useParams } from 'react-router-dom';
import Button from '../../components/Buttons/Button';
// import { getUsers } from '../../store/actions/usersActions';
import { getCleanDepartments, getCleanAccountDepartments } from '../../store/actions/departmentActions';
import { getAccount } from '../../store/actions/accountActions';
import Layout from '../../layout/Layout';
import Loader from '../../components/Loader/Loader';
import requireAuth from '../../hoc/requireAuth';
import { connect } from 'react-redux';
// import UserForm from '../../components/UserForm/UserForm';
// import User from '../../components/User/User';
// import UserFormEdit from '../../components/UserForm/UserFormEdit';
// import AccountForm from '../../components/AccountComponents/AccountForm';
// import Account from '../../components/AccountComponents/Account';
import Department from './Department';
import DepartmentPodEdit from '../../components/DepartmentsPageComponenents/DepartmentPodEdit/DepartmentPodEdit';
import DepartmentLeadsEdit from '../../components/DepartmentsPageComponenents/DepartmentLeadsEdit/DepartmentLeadsEdit';
import DepartmentPodProjectsEdit from '../../components/DepartmentsPageComponenents/DepartmentPodProjectsEdit/DepartmentPodProjectsEdit';
import ProjectEditForm from '../../components/DashboardComponents/ProjectForm/ProjectFormEdit';
// import AccountFormEdit from '../../components/AccountComponents/AccountFormEdit';
// import DepartmentEdit from '../../components/DashboardComponents/DepartmentEdit/DepartmentEdit';
// import AccountDepartmentsEdit from '../../components/AccountComponents/AccountDepartmentsEdit/AccountDepartmentsEdit';
// import AccountAdminsEdit from '../../components/AccountComponents/AccountAdminsEdit/AccountAdminsEdit';
// import ProjectDepartmentsUsersEdit from '../../components/DashboardComponents/ProjectDepartmentsUsersEdit/ProjectDepartmentsUsersEdit';

// Use '../Users/styles.css' for styling
// import './styles.css';

const Departments = ({ 
  getCleanDepartments,  
  getCleanAccountDepartments,
  getAccount,
  department: { departments, isLoading, error }, 
  account: { account, isLoading: accountLoading },
  auth,
  match 
}) => {
  const [openHistory, setOpenHistory] = useState([]);
  const [currentOpenForm, setCurrentOpenForm] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isFormEditVisible, setIsFormEditVisible] = useState(false);  
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDepartments, setFilteredDepartments] = useState([]);
  // const [account, setAccount] = useState(null);
  const [project, setProject] = useState(null);
  const [department, setDepartment] = useState(null);  
  const [tag, setTag] = useState(null);
  const [podmaster, setPodmaster] = useState(null);
  // const [admin, setAdmin] = useState(null);  
  // let { accountId } = useParams(); 
  let { projectId } = useParams();
  let { departmentId } = useParams();
  const accountId = (match && match.params.accountId) || auth.me?.account;

  useEffect(() => {
    if (accountId) {
      getCleanAccountDepartments(accountId);
      getAccount(accountId);
    } else {
      getCleanDepartments();
    }
  }, [getCleanDepartments, getCleanAccountDepartments, accountId]);

  useEffect(() => {

    setCurrentOpenForm(null);      
    if (departmentId) {
      setDepartment(departmentId);      
      setCurrentOpenForm('edit');
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setFilteredDepartments(departments);
  }, [departments]);

  // const handleOpenForm = (accountID) => {
  //   if (accountID) {
  //     // If clicking same user that's already open, close the form
  //     if (accountID === account) {
  //       setIsFormEditVisible(false);
  //       setIsFormVisible(false);
  //       setOpenedAccount(null);        
  //     } else {
  //       // If clicking different user, show edit form for that user
  //       setIsFormEditVisible(true);
  //       setIsFormVisible(false);
  //       setOpenedAccount(accountID);        
  //     }
  //   } else {
  //     // Handle create new user form toggle
  //     setIsFormEditVisible(false);
  //     setIsFormVisible(!isFormVisible);
  //     setOpenedAccount(null);      
  //   }
  // };

  const handleOpenForm = (type = null, department = null) => {
    if (currentOpenForm && !openHistory.includes(currentOpenForm)) {
      setOpenHistory((prev) => [...prev, currentOpenForm]);
    }
    setCurrentOpenForm(type);
    
    department && setDepartment(department);    
  };

  const handleUnsetForm = (account = null) => {
    setOpenHistory([]);
    setDepartment(null);    
    setCurrentOpenForm(null);
  };

  const handleReturnBack = () => {
    if (openHistory.length > 0) {
      const lastForm = openHistory[openHistory.length - 1];
      setOpenHistory((prev) => prev.slice(0, -1));
      setCurrentOpenForm(lastForm);
    } else {
      setDepartment(null);      
      setCurrentOpenForm(null);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = departments.filter(
      (department) =>
        department.name?.toLowerCase().includes(term) ||
        department.account?.name?.toLowerCase().includes(term)
        
    );

    setFilteredDepartments(filtered);
  };

  const renderForm = () => {
    switch (currentOpenForm) {
      case 'edit':
        return (
          <DepartmentPodEdit
            departmentID={department}
            onUnsetForm={handleUnsetForm}
            currentOpenForm={currentOpenForm}
            onOpenForm={handleOpenForm}
            onReturnBack={handleReturnBack}
            setProject={setProject}
            // setTag={setTag}
            setPodmaster={setPodmaster}
            account={account}
          />
        );
      // case 'add':
      //   return (
      //     <AccountForm 
      //       onUnsetForm={handleUnsetForm} 
      //       onReturnBack={handleReturnBack}
      //       accountID={account} 
      //       onOpenForm={handleOpenForm}
      //       getCleanDepartments={getCleanDepartments} 
      //     />
      //   );
      case 'project/new':
        return (
          <DepartmentPodProjectsEdit
            departmentID={department}
            onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}
            setDepartment={setDepartment}
            setProject={setProject}
            onOpenForm={handleOpenForm}
          />
        );
      case 'project/edit':
        return (
          <ProjectEditForm
            projectID={project}
            departmentID={department}
            onUnsetForm={handleUnsetForm}
            currentOpenForm={currentOpenForm}
            onOpenForm={handleOpenForm}
            onReturnBack={handleReturnBack}            
            // setDepartment={setDepartment}
            // setTag={setTag}
            // setPodmaster={setPodmaster}
          />
        );      
      // case 'member/new':
      //   return (
      //     <ProjectDepartmentsUsersEdit
      //       departmentID={department}
      //       accountID={account}
      //       onUnsetForm={handleUnsetForm}
      //       onReturnBack={handleReturnBack}            
      //     />
      //   );
      case 'podmaster/new':
        return (
          <DepartmentLeadsEdit
            departmentID={department}
            // onUnsetForm={handleUnsetForm}
            onReturnBack={handleReturnBack}            
            // onOpenForm={handleOpenForm}
          />
        );
      default:
        return !isMobile ? (
          <div className="project-list__placeholder placeholder-project-list">
            <h4 className="Material-KitH4">Choose an action to start</h4>
            
             <h5 className="Material-KitH5">You can view your departments' pods in one place</h5> :
            
            <div className="placeholder-project-list__image">
              <img src="/images/dashboard/Decoration.svg" alt="Press + to start" />
            </div>
          </div>
        ) : null;
    }
  };
  if (!auth.isAuthenticated) return null;

  return (
    <Layout>
      <div className="users__container adaptive-container accounts-page">
        <div className="users">
          <div className={`users__row ${currentOpenForm !== null ? 'opened' : ''}`}>
            
              <div className={`users__column users__menu ${isMobile ? 'only-child' : ''}`}>
                <div className="users__heading">
                  {/* <h4 className="users__title Material-KitH5">Users</h4> */}
                  <h2 className="users__title">Department Pods<span className="Material-KitOverline">{(auth.me?.role !== 'SUPERADMIN' || accountId) && account?.name }</span></h2>
                  <div className="users__actions actions-users">
                    <div className="actions-users__search actions__search">
                      <input
                        type="text"
                        className="actions-users__search-input Material-KitSB2"
                        placeholder="Search departments..."
                        value={searchTerm}
                        onChange={handleSearch}
                      />
                      <div className="actions-users__search-icon">
                        <img src="/icons/search.svg" alt="Search" />
                      </div>
                    </div>
                    {/* <Button
                      className="button-append"
                      onClick={() => {
                        // handleOpenForm(null);
                        currentOpenForm === 'add' ? handleUnsetForm() : handleOpenForm('add');
                      }}
                      size="small"
                    >
                      <div className="button-append__image">
                        <img src="/icons/add.svg" alt="Press + to start" />
                      </div>
                    </Button> */}
                  </div>
                </div>

                <div className="list list-users">
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {filteredDepartments.map((dept) => (
                        <Department
                          key={dept.id}
                          department={dept}
                          isFormVisible={isFormVisible}
                          currentOpenForm={currentOpenForm}
                          onUnsetForm={handleUnsetForm}
                          onOpenForm={handleOpenForm}
                          getCleanDepartments={getCleanDepartments}
                          selected={department === dept.id}
                          account={account}
                        />                        
                      ))}
                    </>
                  )}
                </div>
              </div>
            
            {renderForm()}
            
          </div>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  department: state.department,
  auth: state.auth,
  account: state.account,
});

export default compose(requireAuth, connect(mapStateToProps, { getCleanDepartments, getCleanAccountDepartments, getAccount }))(Departments);
